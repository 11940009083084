import React, { useState, useEffect } from "react";
import {
  Container,
  Tooltip,
  Row,
  Col,
  OverlayTrigger,
  Button,
  Modal,
} from "react-bootstrap";
import Fade from "react-reveal";
import { ReactComponent as Info } from "../../../../Assets/SVGs/info.svg";
import { useNavigate } from "react-router-dom";
import { useMetamaskContext } from "../../../../Contexts/MetamaskProvider";
import { toast } from "react-toastify";
import { BigNumber, ethers } from "ethers";
import { stakeAbiNonPayable, sxidAbi, unstakeAbi } from "../../../../Utils/abi";
import axios from "axios";

import FirstRow from "./Rows/FirstRow";
import SecondRow from "./Rows/SecondRow";
import MiningDevicesTable from "./Rows/MiningDevicesTable";
import UnstakeModal from "./UnstakeModal";

// import ethUtil from "ethereumjs-util";

const IndividualPoolGrid = ({
  params,
  poolData,
  forcerRender,
  setForcerRender,
}) => {
  // stats
  const [devices, setDevices] = useState([]);
  const [onlineMiners, setOnlineMiners] = useState(0);
  const [offlineMiners, setOfflineMiners] = useState(0);
  const [connectedDevices, setConnectedDevices] = useState(0);
  const [isMinterActive, setIsMinterActive] = useState(false);
  const [miningPower, setMiningPower] = useState(0);
  const [publicDifficulty, setPublicDifficulty] = useState(0);
  const [privateDifficulty, setPrivateDifficulty] = useState(0);
  const [xdenStake, setXdenStake] = useState(0);
  const [rewards, setRewards] = useState({
    allRewards: 0,
    latestRewards: 0,
    privateRewardsTotal: 0,
    privateRewardYesterday: 0,
    publicRewardsTotal: 0,
    publicRewardYesterday: 0,
  });

  const [privateRewards, setPrivateRewards] = useState(0);
  // stats loaders
  const [loadingMiners, setLoadingMiners] = useState(true);
  const [loadingConnectedDevices, setLoadingConnectedDevices] = useState(true);
  const [loadingPublicPrivateDifficulty, setLoadingPublicPrivateDifficulty] =
    useState(true);
  const [loadingStaking, setLoadingStaking] = useState(false);
  const [loadingRewards, setLoadingRewards] = useState(false);
  const [loadingXdenStake, setLoadingXdenStake] = useState(true);
  const [poolDetails, setPoolDetails] = useState({
    accountID: 0,
    address: null,
    changeType: null,
    createdAt: "",
    hasStake: false,
    id: "",
    minterID: "",
    name: "",
    type: "",
    unstaked: true,
    unstaked_date: null,
    updatedAt: "",
  });

  const navigate = useNavigate();
  const { wallet, balance } = useMetamaskContext();

  const stakeTp = <Tooltip></Tooltip>;
  // syncTp = <Tooltip></Tooltip>;

  //here we get when the pool has unstaked
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://pools.xiden.com/api/pool/id/" + params?.id,

          {
            headers: {
              Authorization: localStorage.getItem("xiden-pool-session"),
            },
          }
        );
        if (response.status === 200) {
          setPoolDetails(response.data);
          console.log(
            "🚀 ~ file: IndividualPoolGrid.jsx:97 ~ fetchData ~ response.data:",
            response.data
          );
        }
      } catch (err) {
        if (err.response) {
          switch (err.response.status) {
            case 404:
              console.log("404 Not found.");
              return;
            default:
              console.log("Something is temporarily wrong.");
              return;
          }
        }
      }
    };

    fetchData();
  }, [params?.id]);
  useEffect(() => {
    const fetchData = async () => {
      const toSend = {
        poolID: params?.id,
      };
      try {
        const checkReq = await axios.post(
          "https://pools.xiden.com/api/rewards/getPoolRewards",
          toSend,

          {
            headers: {
              Authorization: localStorage.getItem("xiden-pool-session"),
            },
          }
        );
        if (checkReq.status === 200) {
          setPrivateRewards(checkReq.data.privateRewardTotal);
        }
      } catch (err) {
        if (err.response) {
          switch (err.response.status) {
            case 404:
              console.log("404 Not found.");
              return;
            default:
              console.log("Something is temporarily wrong.");
              return;
          }
        }
      }
    };

    fetchData();
  }, []);

  const getXdenStake = async () => {
    try {
      const checkReq = await axios.get(
        "https://pools.xiden.com/api/rewards/getStakedAmound",
        {
          headers: {
            Authorization: localStorage.getItem("xiden-pool-session"),
          },
        }
      );
      if (checkReq.status === 200) {
        setXdenStake(
          checkReq.data.staked ? Number(checkReq.data.staked) * 1000 : 0
        );
      }
    } catch (err) {
      if (err.response) {
        switch (err.response.status) {
          case 404:
            console.log("404 Not found.");
            return;
          default:
            console.log("Something is temporarily wrong.");
            return;
        }
      }
    } finally {
      setLoadingXdenStake(false);
    }
  };

  const fetchConnectedDevices = async (id) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL + `/pool/connectedDevices/${id}`,
        {
          headers: {
            Authorization: localStorage.getItem("xiden-pool-session"),
          },
        }
      );

      if (response.status === 200) {
        if (response.data) {
          setConnectedDevices(response.data?.connectedDevices || 0);
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingConnectedDevices(false);
    }
  };

  const fetchMiningDevices = async (id) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL + `/miners/getMinerStatus/${id}`,
        {
          headers: {
            Authorization: localStorage.getItem("xiden-pool-session"),
          },
        }
      );

      if (response.status === 200) {
        if (response.data) {
          let minersOnline = [];
          let minersOffline = [];
          let miningPower = 0;
          if (response.data.minersOffline.length > 0) {
            minersOffline = response.data.minersOffline.map((el) => ({
              ...el,
              status: "Offline",
            }));
          }
          if (response.data.minersOnline.length > 0) {
            const findMinter = response.data.minersOnline.find(
              (el) => el.device === poolData?.minterID
            );
            if (findMinter) {
              setIsMinterActive(true);
            }

            minersOnline = response.data.minersOnline.map((el) => ({
              ...el,
              status: "Online",
            }));
            miningPower = minersOnline.reduce(
              (acc, curr) => acc + curr.power,
              0
            );
          }
          setDevices([...minersOnline, ...minersOffline]);
          setOnlineMiners(response.data.numberOnline);
          setOfflineMiners(response.data.numberOffline);

          var nft_list = [];
          var octarine_nfts = [];

          minersOnline.map((miner) => {
            if (
              miner.deviceType === "nft77" ||
              miner.deviceType === "nft34" ||
              miner.deviceType === "nft35"
            ) {
              octarine_nfts.push(miner.device);
            } else {
              if (miner.deviceType.indexOf("nft") !== -1)
                nft_list.push(miner.deviceType);
            }
          });

          if (octarine_nfts.length === 0) {
            const boosterPowerBasic = await axios.post(
              "https://nft.xiden.com/api/nft/internal/calculateTotalBoost",
              {
                nftList: nft_list,
              }
            );

            if (boosterPowerBasic) {
              var customBooster = parseFloat(
                boosterPowerBasic.data.boosterPower
              );
              miningPower =
                miningPower + miningPower * parseFloat(customBooster / 100);
            }
          } else {
            //call octarine booster power
            let customBoosterPower = await axios.get(
              "https://nft.xiden.com/api/nft/internal/getBoosterPower?nftID=" +
                octarine_nfts[0]
            );

            if (customBoosterPower && customBoosterPower.data) {
              var customBooster = parseFloat(
                customBoosterPower.data.boosterPower
              );
              miningPower =
                miningPower + miningPower * parseFloat(customBooster / 100);
            }
          }

          console.log("New NFT Double Functionality Online");

          setMiningPower(miningPower);
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingMiners(false);
    }
  };

  const getDiff = async () => {
    var raw = {
      toggleDifficulty: false,
      size: 1,
      basmInput: 1,
      hidrInput: 0,
      minterInput: 0,
      activeDevices: 0,
    };

    try {
      const checkReq = await axios.post(
        "https://api.heimdall.ro/calculatorFunction",
        raw
      );
      if (checkReq.status === 200) {
        setPrivateDifficulty(checkReq.data.privateDifficulty);
        setPublicDifficulty(checkReq.data.publicDifficulty);
      }
    } catch (err) {
      if (err.response) {
        switch (err.response.status) {
          case 404:
            console.log("404 Not found.");
            return;
          default:
            console.log("Something is temporarily wrong.");
            return;
        }
      }
    } finally {
      setLoadingPublicPrivateDifficulty(false);
    }
  };

  const getRewards = async (id) => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL + `/pool/getRewards/${id}`,
        {
          headers: {
            Authorization: localStorage.getItem("xiden-pool-session"),
          },
        }
      );

      if (response.status === 200) {
        // setRewards
        setRewards(response.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingRewards(false);
    }
  };

  const redeem = async () => {
    // check if already redeemed
    if (poolData?.address) {
      toast.error(
        "You have already redeemed your sXID for this pool into another wallet.",
        {
          toastId: "alredy-redeemed-error",
        }
      );
      return;
    }

    const toSend = {
      address: wallet,
      poolID: params?.id,
    };

    // send request to redeem sXID
    try {
      const checkReq = await axios.post(
        process.env.REACT_APP_BACKEND_URL + `/staking/redeem-xsid`,
        toSend,
        {
          headers: {
            Authorization: localStorage.getItem("xiden-pool-session"),
          },
        }
      );
      if (checkReq.status === 200) {
        toast.success(
          "Redeem successful! Transaction is processing and it can take up to 30 seconds",
          { toastId: "redeemSuccess", autoClose: 30000, closeOnClick: false }
        );
        // refresh page
        await new Promise((resolve) => setTimeout(() => resolve(), 30000));
        window.location.reload();
      }
    } catch (err) {
      if (err.response) {
        switch (err.response.status) {
          case 403:
            toast.error("Redeem failed!", { toastId: "redeemFailed" });
            return;
          default:
            toast.error("Something is temporarily wrong.", {
              toastId: "redeemError",
            });
            return;
        }
      }
    }
  };

  // https://pools.xiden.com/api/rewards/getPoolRewards  pool id in body
  // https://pools.xiden.com/api/pool/unstake - pool id in body

  const markStake = async () => {
    try {
      let response = await axios.post(
        process.env.REACT_APP_BACKEND_URL + `/pool/stakeConfirm`,
        {
          poolID: params?.id,
          address: wallet,
        },
        {
          headers: {
            Authorization: localStorage.getItem("xiden-pool-session"),
          },
        }
      );
      if (response.status === 200) {
      }
    } catch (err) {
      console.log(err);
    }
  };
  const markUnstake = async () => {
    try {
      let response = await axios.post(
        process.env.REACT_APP_BACKEND_URL + `/pool/unstake`,
        {
          poolID: params?.id,
        },
        {
          headers: {
            Authorization: localStorage.getItem("xiden-pool-session"),
          },
        }
      );
      if (response.status === 200) {
        toast.success(
          "Unstake successful! Transaction is processing and it can take up to 30 seconds",
          { toastId: "unstakeSuccess", autoClose: 30000, closeOnClick: false }
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const stake = async () => {
    // verify if metamask is installed
    if (!window.ethereum) {
      toast.error("Please install MetaMask to stake.", {
        toastId: "stakeError",
      });
      return;
    }

    try {
      setLoadingStaking(true);

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();

      //get wallet address
      const wallet = await signer.getAddress();

      // define the contracts

      const StakeContract = new ethers.Contract(
        process.env.REACT_APP_STAKE_CONTRACT_ADDRESS,
        stakeAbiNonPayable,
        signer
      );
      const SxidContract = new ethers.Contract(
        process.env.REACT_APP_SXID_CONTRACT_ADDRESS,
        sxidAbi,
        signer
      );
      let hasStakeResponse = await StakeContract.hasStake(signer.getAddress());
      console.log(
        "🚀 ~ file: IndividualPoolGrid.jsx:438 ~ unstake ~ hasStake:",
        hasStakeResponse
      );

      // const addresses = {
      //   ceaErc20: "0xa6dF0C88916f3e2831A329CE46566dDfBe9E74b7",
      //   stakeErc20: "0xe359Cac6C801C97A96f1c073Ab79DcbD3ee35714",
      //   stakeErc20Test: "0x964C4d15B6Faa5CB21EC564ea5E8A824cE327ad2",
      //   sidStaking: "0x9f682C94fCc7cBc5408d72C21B90E220242b5591",
      //   sidStakingTest: "0x09FEd86225adB00672dfa0b657Fa4be7073Ac316",
      //   err: "0x69B97198AB8FEC2a4c8517dE4E1e584d863C511F",
      // };

      // get sXid Balance of the wallet
      const tokenBalance = await SxidContract.balanceOf(wallet);

      const decimals = 18;

      // Convert the hex value to a readable format (in ether)
      // tokenAmountSXid - balance of sXID in wallet
      const tokenAmountSXid = ethers.utils.formatUnits(
        tokenBalance._hex,
        decimals
      );
      if (tokenAmountSXid <= 1) {
        toast.error("You need at least 1 sXID to stake.", {
          toastId: "stakeError",
        });
        return;
      }

      const approve = await SxidContract.approve(
        process.env.REACT_APP_STAKE_CONTRACT_ADDRESS,
        ethers.utils.parseEther(Number(tokenAmountSXid).toFixed(17).toString()) // sxid that you want to send
      );

      approve.wait().then(async (res) => {
        console.log("SXID Approved", { res });

        const options = {
          value: ethers.utils.parseEther(
            (1000 - Number(tokenAmountSXid)).toFixed(17).toString() // xiden that you want to send
          ),
          gasLimit: ethers.utils.parseUnits("500000", "wei"),
          gasPrice: ethers.utils.parseUnits("20", "gwei"),
        };
        const tx = await StakeContract.stake("stringPoolId", options);
        tx.wait().then((res) => {
          markStake();
          toast.success("Stake successful!", { toastId: "stakeSuccess" });
        });

        // verify balance
        // if (balance < 1000) {
        // console.log(
        //   "🚀 ~ file: IndividualPoolGrid.jsx:502 ~ approve.wait ~ balance:",
        //   balance
        // );
      });

      await new Promise((resolve) => setTimeout(() => resolve(), 5000));
      // window.location.reload();
    } catch (err) {
      console.log(err);
      toast.error("Something is temporarily wrong.", { toastId: "stakeError" });
    } finally {
      setLoadingStaking(false);
    }
  };

  const unstake = async () => {
    if (!window.ethereum) {
      toast.error("Please install MetaMask to stake.", {
        toastId: "stakeError",
      });
      return;
    }

    try {
      setLoadingStaking(true);

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();

      const UnStakeContract = new ethers.Contract(
        process.env.REACT_APP_UNSTAKE_CONTRACT_ADRESS,
        unstakeAbi,
        signer
      );

      const tx = await UnStakeContract.unstake(
        ethers.utils.parseUnits((1000 - privateRewards).toString()), // sXidAmout
        ethers.utils.parseUnits(privateRewards.toString()) // XDEN amoount
      );

      await new Promise((resolve) => setTimeout(() => resolve(), 5000));

      let response = await tx.wait();

      markUnstake();
    } catch (err) {
      console.log(err);
      toast.error("Something is temporarily wrong.", { toastId: "stakeError" });
    } finally {
      setLoadingStaking(false);
    }
  };
  useEffect(() => {
    // fetch individual pool data
    const fetchIndividualPoolData = async () => {
      await fetchMiningDevices(params.id);
      await fetchConnectedDevices(params.id);
      await getRewards(params.id);
      await getDiff();
      await getXdenStake();
    };

    fetchIndividualPoolData();
  }, [params]);

  const firstRow = {
    onlineMiners: onlineMiners,
    offlineMiners: offlineMiners,
    connectedDevices: isMinterActive ? connectedDevices : 0,
    miningPower: miningPower,
    loadingMiners: loadingMiners,
    loadingConnectedDevices: loadingConnectedDevices,
    poolData: poolData,
    publicDifficulty: publicDifficulty,
    privateDifficulty: privateDifficulty,
    loadingPublicPrivateDifficulty: loadingPublicPrivateDifficulty,
    allRewards: rewards?.allRewards || 0,
    latestRewards: rewards?.latestRewards || 0,
    privateRewardsTotal: rewards?.privateRewardsTotal || 0,
    loadingRewards: loadingRewards,
    xdenStake: xdenStake,
    loadingXdenStake: loadingXdenStake,
  };

  const secondRow = {
    onlineMiners: onlineMiners,
    offlineMiners: offlineMiners,
    connectedDevices: isMinterActive ? connectedDevices : 0,
    privateRewardYesterday: rewards?.privateRewardYesterday || 0,
    publicRewardYesterday: rewards?.publicRewardYesterday || 0,
    loadingMiners: loadingMiners,
    loadingConnectedDevices: loadingConnectedDevices,
    loadingRewards: loadingRewards,
  };

  let renderError;

  if (!poolData.minterID || !isMinterActive) {
    console.log(
      "🚀 ~ file: IndividualPoolGrid.jsx:652 ~ stake ~ isMinterActive:",
      isMinterActive
    );
    console.log(
      "🚀 ~ file: IndividualPoolGrid.jsx:652 ~ stake ~ poolData:",
      poolData
    );
    renderError = (
      <Col className="mb-4">
        <div className="hero-box main-box height-252">
          <div className="hero-info-icon">
            <OverlayTrigger placement="top" overlay={stakeTp}>
              <Info className="cursor-pointer info-icons" />
            </OverlayTrigger>
          </div>
          <div className="max-width-desktop d-flex flex-column h-100 justify-content-center align-items-center gap-3 mt-sm-0 mt-2">
            <p className="font-pnsb mb-0 font-size-48 red-color text-center">
              Status: Inactive
            </p>
            <p className="font-pnm white-color text-center">
              Your Minter Guardin is Offline. Plese check your router or Sync
              another Minter Guardian.
            </p>
          </div>
        </div>
      </Col>
    );
  } else if (!poolData.hasStake) {
    renderError = (
      <Col className="mb-4">
        <div className="hero-box main-box height-252">
          <div className="hero-info-icon">
            <OverlayTrigger placement="top" overlay={stakeTp}>
              <Info className="cursor-pointer info-icons" />
            </OverlayTrigger>
          </div>
          <div className="max-width-desktop d-flex flex-column h-100 justify-content-center align-items-center gap-3 mt-sm-0 mt-2">
            <>
              <p className="font-pnsb mb-0 font-size-48 red-color text-center">
                Status: Inactive
              </p>
              <p className="font-pnm white-color text-center">
                Not enough XDEN in Staking.
              </p>
              {balance && Number(balance) >= 1000 ? (
                <button
                  onClick={stake}
                  disabled={loadingStaking}
                  className="buttonYellow"
                >
                  Stake now
                </button>
              ) : (
                <button
                  onClick={redeem}
                  disabled={!wallet}
                  className="buttonYellow"
                >
                  Redeem
                </button>
              )}
            </>
          </div>
        </div>
      </Col>
    );
  }
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="individual-pool-grid">
      <UnstakeModal
        show={show}
        unstake={unstake}
        handleClose={handleClose}
        privateRewards={privateRewards}
      />
      {/* sal {poolDetails.unstaked_date} */}
      <Container fluid>
        <Fade>
          {/* inactive pool error */}
          <Row>{renderError}</Row>

          {/* first row */}
          <FirstRow
            stake={stake}
            handleClose={handleClose}
            handleShow={handleShow}
            details={firstRow}
            setForcerRender={setForcerRender}
            forcerRender={forcerRender}
            poolDetails={poolDetails}
          />

          {/* second row */}
          <SecondRow details={secondRow} />

          {/* individual pool table */}
          <MiningDevicesTable
            devices={devices}
            fetchMiningDevices={fetchMiningDevices}
            params={params}
          />
        </Fade>
        {/* <Button
          onClick={async () => {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const contract = new ethers.Contract(
             '0xe359Cac6C801C97A96f1c073Ab79DcbD3ee35714',
              unstakeAbi,
              signer
            );
            const tx = await contract.unstake(
              "0x9fc517424555c7a67602d15594d5face1f04ecefa86ea480a3b3d5c2af178f1c",
              "1",
              ethers.utils.parseEther("1"),
              ethers.utils.parseEther("1")
            );
            console.log(await tx.wait());
          }}
        >
          {" "}
          {"Unstake"}{" "}
        </Button> */}
        {/* <button onClick={markStake}> Stake</button> */}
        {/* <button onClick={() => handleShow()}>unstake</button>

        <br></br>
        <span className="white-color">{privateRewards}</span> */}
      </Container>
    </div>
  );
};

export default IndividualPoolGrid;
