import React, { useState, useEffect } from "react";
import './MiningDevicesTable.scss';
import { Table, Container } from "react-bootstrap";
import axios from "axios";
import Moment from "react-moment";
import { ReactComponent as BasmIcon } from "../../../../Assets/SVGs/basm-icon.svg";
import { ReactComponent as HidrIcon } from "../../../../Assets/SVGs/hidr-icon.svg";
import { ReactComponent as UnknownDevice } from "../../../../Assets/SVGs/unknown-device.svg";
import { ReactComponent as ImpulseIcon } from "../../../../Assets/SVGs/impulse-icon.svg";
import { ReactComponent as NftIcon } from "../../../../Assets/SVGs/nft-mining-icon.svg";
import { ReactComponent as MiningPower } from "../../../../Assets/SVGs/mining_power.svg";
import MinterGuardianIcon from "../../../../Assets/PNGs/minter-guardian-icon.png";

const MiningDevicesTable = () => {
    const [devices, setDevices] = useState([]);

    useEffect(() => {
        const fetchDevices = async () => {
            try {
                const response = await axios.get(
                    process.env.REACT_APP_BACKEND_URL + "/miners",
                    {
                        headers: {
                            Authorization: localStorage.getItem("xiden-pool-session")
                        }
                    }
                );

                console.log(response);

                if (response.status === 200) {
                    setDevices(response.data.length > 0 ? response.data.reverse() : []);
                }
            } catch (err) {
                console.log(err);
            }
        };

        fetchDevices();
    }, []);

    const renderType = (type) => {
        if (type) {
            switch (type) {

                case "phone":
                    return <HidrIcon width="25px" />;

                case "impulse":
                    return <ImpulseIcon width="25px" />;

                case "router":
                    return <img src={MinterGuardianIcon} alt="minter" width="25px" />;

                case "laptop":
                    return <BasmIcon width="25px" />;

                case "nftlaptop":
                    return <><BasmIcon width="25px" /> &nbsp; + &nbsp; <NftIcon /></>;

                case "nftphone":
                    return <><HidrIcon width="25px" /> &nbsp; + &nbsp; <NftIcon /></>;

                case "nftimpulse":
                    return <><ImpulseIcon width="25px" /> &nbsp; + &nbsp; <NftIcon /></>;

                case String(type.match(/(nft\d\d)/g)):
                    return <NftIcon />;

                default:
                    return <UnknownDevice width="25px" />;

            }
        } else {
            return <UnknownDevice width="25px" />;
        }
    }

    return (
        <Container fluid>
            <p className="font-pnb font-size-32 white-color mb-4">Mining devices</p>
            <Table className="workers-table" responsive hover>
                <thead>
                    <tr>
                        <th className="font-size-18 font-pnsb">Type</th>
                        <th className="font-size-18 font-pnsb">Status</th>
                        <th className="font-size-18 font-pnsb">Name</th>
                        <th className="font-size-18 font-pnsb">Device</th>
                        <th className="font-size-18 font-pnsb">Pool Id</th>
                        <th className="font-size-18 font-pnsb">Mining Power</th>
                        <th className="font-size-18 font-pnsb">Uptime</th>
                        <th className="font-size-18 font-pnsb">Last seen</th>
                        <th className="font-size-18 font-pnsb">Last Updated</th>
                        {/* <th className="font-size-18 font-pnsb">Created at</th> */}
                    </tr>
                </thead>
                <tbody>
                    {devices.length > 0 ? (
                        devices.sort((a, b) => b.online.toString().localeCompare(a.online.toString())).map((device, key) => (
                            <tr key={key} valign="middle">
                                <td className="font-size-18 font-pnl">{renderType(device?.deviceType)}</td>
                                <td className={`font-size-18 font-pnl ${!device.online ? "red-color" : "green-color"}`}>{!device.online ? "Offline" : "Online"}</td>
                                <td className="font-size-18 font-pnl">{device?.name || "N/A"}</td>
                                <td className="font-size-18 font-pnl">{device.id}</td>
                                <td className="font-size-18 font-pnl">{device.poolID}</td>
                                <td className="font-size-18 font-pnl">

                                    {
                                        device.miningPower ?
                                            <>
                                                <MiningPower />
                                                {device.miningPower}
                                            </>
                                            : null
                                    }

                                </td>
                                <td className="font-size-14 font-pnl">

                                    {
                                        device.online ?
                                            <>
                                            <svg className="me-3" width="25" height="25" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path fill="none" stroke="#1dcb70" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" shape-rendering="optimizeSpeed" d="M44 24L36 24 30 42 18 6 12 24 4 24" /><path fill="none" stroke="none" d="M0 0L48 0 48 48 0 48Z" /></svg>

                                            {device.uptime}
                                            </>
                                            : ""
                                    }
                                </td>
                                {
                                    device.online ?
                                        <>
                                        <td className="font-size-18 font-pnl"><Moment format="HH:mm a">{device.lastSeen}</Moment></td>
                                        <td className="font-size-18 font-pnl"><Moment format="HH:mm a">{device.updatedAt}</Moment></td>
                                        </>
                                        :
                                        <>
                                            <td></td>
                                            <td></td>
                                        </>
                                }
                                {/* <td className="font-size-18 font-pnl"><Moment format="DD MMM YYYY HH:mm a">{device.lastSeen}</Moment></td>
                                <td className="font-size-18 font-pnl"><Moment format="DD MMM YYYY HH:mm a">{device.updatedAt}</Moment></td> */}
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="7" className="font-size-18 font-pnl">No mining devices found</td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </Container>
    );
};

export default MiningDevicesTable;
