import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { routes } from "../Utils/routes";
import { toast } from "react-toastify";
import { render } from "react-dom";

const UserContext = React.createContext();

export const useUserContext = () => useContext(UserContext);

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [rerender, setRerender] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_BACKEND_URL + "/me",
          {
            headers: {
              Authorization: localStorage.getItem("xiden-pool-session"),
            },
          }
        );
        if (response.status === 200) {
          setUser(response.data);
          // console.log(response.data);
        }
      } catch (err) {
        toast.error("Please log in again.");
        navigate(routes.signIn);
        console.log(err);
      }
    };

    fetchUserData();
  }, [rerender]);

  const stateObject = {
    user,
    rerender,
    setRerender,
  };

  return (
    <UserContext.Provider value={stateObject}>{children}</UserContext.Provider>
  );
};

export default UserProvider;
