import React from "react";
// import "../CreatePool.scss";
import Fade from "react-reveal";
import SetupStepCard from "../../../Components/SetupStepCard/SetupStepCard";
import { steps } from "../../../Utils/setupSteps";
import { routes } from "../../../Utils/routes";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const CreatePoolStep1 = ({ handleActiveStep, poolId, setPoolId, poolName, setPoolName }) => {
   // hooks
   const navigate = useNavigate();

   // data
   const stepCardData = {
      step: 1,
      title: "Create pool",
      info: "Wait until hashrate is displayed. Workers will be automatically added within a few seconds on the Workers page in your account.",
   };

   // functions
   const handlePoolId = event => setPoolId(event.target.value);
   const handlePoolName = event => {
      setPoolName(event.target.value);
   };

   const handleNextStep = () => {
      if (poolId === "") {
         return toast.error("You didn't type the minter id", { toastId: "poolId" });
      };

      if (!poolName) {
         return toast.error("You didn't type the pool name", { toastId: "poolName" });
      };

      if (poolName.length < 3 || poolName.length > 12) {
         return toast.error("Pool name must be between 3 and 12 characters.");
      };

      handleActiveStep(steps.step_2);
   };

   return (
      <Fade>
         <div className="w-100 d-flex flex-column">
            <SetupStepCard data={stepCardData} />

            <div className="setup-card m-auto d-flex flex-column">
               <h6 className="font-pnb font-size-20 mb-4">Pool configuration</h6>
               <label className="font-size-18 font-pnb mb-2">Minter Id:</label>
               <input
                  value={poolId}
                  type="text"
                  className="pool-input"
                  placeholder="Type here..."
                  onChange={event => handlePoolId(event)}
               />

               <label className="font-size-18 font-pnb mb-2 mt-3">Pool Name:</label>
               <input
                  value={poolName}
                  type="text"
                  className="pool-input"
                  placeholder="Type here..."
                  onChange={event => handlePoolName(event)}
               />

               <div className="setup-buttons-container">
                  <button className="setup-outline-btn font-size-20 font-pnm" onClick={() => navigate(routes.homepage)}>
                     Go back
                  </button>

                  <button className="setup-fill-btn font-size-20 font-pnm" onClick={() => handleNextStep()}>
                     Next
                  </button>
               </div>
            </div>
         </div>
      </Fade>
   );
};

export default CreatePoolStep1;
