import React from "react";
import "./UserDropdown.scss";
import Avatar from "../../Assets/PNGs/avatar.png";
import { Dropdown } from "react-bootstrap";
import { routes } from "../../Utils/routes";
// import { useNavigate } from "react-router-dom";

const UserDropdown = ({ toggleLogoutModal, user }) => {
   // hooks
   // const navigate = useNavigate();

   // functions

   return (
      <Dropdown className="user-dropdown">
         <Dropdown.Toggle variant="success" id="dropdown-basic">
            <div className="d-flex align-items-center avatar-container">
               <p className="font-pnr font-size-20 mb-0 white-color">{user?.email}</p>
               <img src={Avatar} alt="avatar" className="avatar ms-2" />
            </div>
         </Dropdown.Toggle>

         <Dropdown.Menu>
            <Dropdown.Item href={routes.security} className="font-size-20 font-pnm">
               Security
            </Dropdown.Item>
            {/* <Dropdown.Item href={routes.wallet} className="font-size-20 font-pnm">
               Wallet action
            </Dropdown.Item> */}
            <Dropdown.Item className="font-size-20 font-pnm" onClick={() => toggleLogoutModal()}>
               Log Out
            </Dropdown.Item>
         </Dropdown.Menu>
      </Dropdown>
   );
};

export default UserDropdown;
