import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import Moment from "react-moment";
import axios from "axios";

const PoolsTable = () => {
   const [pools, setPools] = useState([]);

   useEffect(() => {
      const fetchPools = async () => {
         try {
            const response = await axios.get(
               process.env.REACT_APP_BACKEND_URL + "/pools",
               {
                  headers: {
                     Authorization: localStorage.getItem("xiden-pool-session")
                  }
               }
            );

            if (response.status === 200) {
               setPools(response.data.length > 0 ? response.data : []);
            }
         } catch (err) {
            console.log(err);
         }
      };

      fetchPools();
   }, []);


   return (
      <Table responsive hover>
         <thead>
            <tr>
               <th className="font-size-18 font-pnsb">ID</th>
               <th className="font-size-18 font-pnsb">Type</th>
               <th className="font-size-18 font-pnsb">Name</th>
               {/* <th className="font-size-18 font-pnsb">Minter ID</th> */}
               <th className="font-size-18 font-pnsb">Date created</th>
            </tr>
         </thead>
         <tbody>
            {pools.length > 0 ? (
               pools.map(({ id, type, name, minterID, createdAt }) => (
                  <tr key={id}>
                     <td className="font-size-18 font-pnl">{id}</td>
                     <td className="font-size-18 font-pnl">
                        <div className={`${type}-status`}>{type}</div>
                     </td>
                     <td className="font-size-18 font-pnl">{name}</td>
                     {/* <td className="font-size-18 font-pnl">{minterID}</td> */}
                     <td className="font-size-18 font-pnl">
                        <Moment format="DD/MM/YYYY">{createdAt}</Moment>
                     </td>
                  </tr>
               ))
            ) : (
               <tr>
                  <td colSpan="5" className="font-size-18 font-pnl">
                     No pools found
                  </td>
               </tr>
            )}
         </tbody>
      </Table >
   );
};

export default PoolsTable;
