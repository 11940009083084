import React from "react";
import "../Economics.scss";
import { ReactComponent as Info } from "../../../../../Assets/SVGs/info.svg";
import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import Moment from "react-moment";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { ReactComponent as XdenIcon } from "../../../../../Assets/SVGs/xden_icon.svg";
import 'react-perfect-scrollbar/dist/css/styles.css';
import ThemeLoader from "../../../../../Components/Loader/Loader";

const FirstRow = ({ details }) => {
   const { xdenPrice, xdenStake, totalWithdrawals, allRewards, scanner, loadingXdenPrice, loadingXdenStake, loadingXdenRewards } = details;

   console.log(allRewards.toFixed(5));

   return (
      <Row  className="first-row">
         <Col lg={2} xs={6} className="mb-4">
            <div className="main-box height-252 d-flex flex-column">
               <div className="d-flex justify-content-between mb-3">
                  <p className="font-pnr white-color font-size-20">Total Revenue</p>
                  {/* info icon */}
                  <OverlayTrigger placement="top" overlay={<Tooltip>Combined revenue from both public and private difficulty</Tooltip>}>
                     <Info className="cursor-pointer" />
                  </OverlayTrigger>
               </div>
               <div className="d-flex inherit flex-column justify-content-center align-items-center">
                  {loadingXdenRewards || loadingXdenPrice ? (
                     <ThemeLoader />
                  ) : (
                     <>
                        <p className="font-pnsb d-flex align-items-center mb-0 font-size-40 white-color text-center">{allRewards?.toFixed(5)} <XdenIcon /></p>
                        <p className="font-pnl mb-0 font-size-28 grey-text text-center">{(allRewards * xdenPrice)?.toFixed(2)}$</p>
                     </>
                  )}
               </div>
            </div>
         </Col>

         <Col lg={2} xs={6} className="mb-4">
            <div className="main-box height-252 d-flex flex-column">
               <div className="d-flex justify-content-between mb-3">
                  <p className="font-pnr white-color font-size-20">Staked amount</p>
                  {/* info icon */}
                  <OverlayTrigger placement="top" overlay={<Tooltip>Amount of XDEN currently in stake</Tooltip>}>
                     <Info className="cursor-pointer" />
                  </OverlayTrigger>
               </div>
               <div className="d-flex inherit flex-column justify-content-center align-items-center">
                  {loadingXdenStake || loadingXdenPrice ? (
                     <ThemeLoader />
                  ) : (
                     <>
                        <p className="font-pnsb d-flex align-items-center mb-0 font-size-40 white-color text-center">{xdenStake} <XdenIcon /></p>
                        <p className="font-pnl mb-0 font-size-28 grey-text text-center">{(xdenStake * xdenPrice)?.toFixed(2)}$</p>
                     </>
                  )}
               </div>
            </div>
         </Col>

         <Col lg={2} md={12} sm={12} className="mb-4">
            <div className="main-box height-252 d-flex flex-column">
               <div className="d-flex justify-content-between mb-3">
                  <p className="font-pnr white-color font-size-20">Total withdraw</p>
                  {/* info icon */}
                  <OverlayTrigger placement="top" overlay={<Tooltip>Total amount ever withdrawn</Tooltip>}>
                     <Info className="cursor-pointer" />
                  </OverlayTrigger>
               </div>
               <div className="d-flex inherit flex-column justify-content-center align-items-center">
                  {loadingXdenRewards || loadingXdenPrice ? (
                     <ThemeLoader />
                  ) : (
                     <>
                        <p className="font-pnsb d-flex align-items-center mb-0 font-size-40 white-color text-center">{totalWithdrawals} <XdenIcon /></p>
                        <p className="font-pnl mb-0 font-size-28 grey-text text-center">{(totalWithdrawals * xdenPrice)?.toFixed(2)}$</p>
                     </>
                  )}
               </div>
            </div>
         </Col>

         <Col lg={6} md={12} sm={12} xs={12} className="mb-3 position-relative">
            <div className="main-box height-252 d-flex flex-column justify-content-between">
               <div className="d-flex justify-content-between">
                  <p className="font-pnr white-color font-size-20">Scanner</p>
                  {/* info icon */}
                  <OverlayTrigger placement="top" overlay={<Tooltip>Daily monitor for pool rewards</Tooltip>}>
                     <Info className="cursor-pointer" />
                  </OverlayTrigger>
               </div>
               <div className="height-252 overflow-auto scanner">
                  <PerfectScrollbar className={`${!scanner || scanner.length === 0 ? 'd-flex align-items-center justify-content-center' : ''}`}>
                     {
                        scanner !== undefined && scanner.length > 0 ?
                        scanner.map((scannerRow, index) => (
                           <div key={index} className="transaction d-flex justify-content-between align-items-center">
                              <p className="mb-0 white-color me-2 w-25">
                                 <Moment date={scannerRow.day} format="DD MMM YYYY" />
                              </p>
                              <p title="Pool ID" className="transaction-status mb-0 white-color hash-label w-25 text-center">Pool: {scannerRow.poolID}</p>
                              <p title="Total reward" className="purple-status mb-0 white-color w-25 text-end">Reward: {scannerRow.totalReward?.toFixed(5)}</p>
                           </div>
                        ))
                        :
                        scanner === undefined ?
                           <ThemeLoader />
                        :
                        scanner.length === 0 ?
                        <p className="text-light text-center font-size-20">Unfortunately no data is available. Start by creating a pool.</p>
                        : null
                     }
                  </PerfectScrollbar>
               </div>
            </div>
         </Col>
      </Row>
   );
};

export default FirstRow;
