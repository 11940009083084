import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import Fade from "react-reveal/Fade";
import PoolsOverviewHeader from "./PoolsOverviewHeader/PoolsOverviewHeader";
import axios from "axios";

import FirstRow from "./PoolsOverviewGrid/FirstRow";
import SecondRow from "./PoolsOverviewGrid/SecondRow";
import Announcement from "../../../Homepage/Components/Announcement/Announcement";

const PoolsOverview = () => {
   // state
   const [activeFilter, setActiveFilter] = useState(() => {
      return { all: true, public: false, private: false };
   });
   const [xdenStake, setXdenStake] = useState(0);
   const [latestRewards, setLatestRewards] = useState(0);

   const [aggregatedPools, setAggregatedPools] = useState([]);

   // state loaders
   const [loadingAggregatedPools, setLoadingAggregatedPools] = useState(true);
   const [loadingXdenStake, setLoadingXdenStake] = useState(true);

   const getXdenStake = async () => {
      try {
         const checkReq = await axios.get("https://pools.xiden.com/api/rewards/getStakedAmound", {
            headers: {
               Authorization: localStorage.getItem("xiden-pool-session")
            }
         })
         if (checkReq.status === 200) {
            setXdenStake(checkReq.data.staked ? Number(checkReq.data.staked) * 1000 : 0);
         }
      } catch (err) {
         if (err.response) {
            switch (err.response.status) {
               case 404:
                  console.log("404 Not found.");
                  return;
               default:
                  console.log("Something is temporarily wrong.");
                  return;
            }
         }
      } finally {
         setLoadingXdenStake(false);
      }
   }

   const getXdenReward = async () => {
      try {
         const checkReq = await axios.get("https://pools.xiden.com/api/rewards/getAllRewards", {
            headers: {
               Authorization: localStorage.getItem("xiden-pool-session")
            }
         })
         if (checkReq.status === 200) {
            //setAllRewards(checkReq.data.allRewards || 0);
            setLatestRewards(checkReq.data.latestRewards || 0);
         }
      } catch (err) {
         if (err.response) {
            switch (err.response.status) {
               case 404:
                  console.log("404 Not found.");
                  return;
               default:
                  console.log("Something is temporarily wrong.");
                  return;
            }
         }
      } finally {
         // setLatestRewards(false);
      }
   }

   useEffect(() => {
      // const fetchData = async () => {
      //    if (pools.length > 0) {
      //       const poolsData = [];

      //       for (const pool of pools) {
      //          try {
      //             const connectedResponse = await axios.get(
      //                process.env.REACT_APP_BACKEND_URL + `/pool/connectedDevices/${pool.id}`,
      //                {
      //                   headers: {
      //                      Authorization: localStorage.getItem("xiden-pool-session")
      //                   }
      //                }
      //             );

      //             const minerResponse = await axios.get(
      //                process.env.REACT_APP_BACKEND_URL + `/miners/getMinerStatus/${pool.id}`,
      //                {
      //                   headers: {
      //                      Authorization: localStorage.getItem("xiden-pool-session")
      //                   }
      //                }
      //             );

      //             if (connectedResponse.status === 200 && minerResponse.status === 200) {
      //                const poolData = {
      //                   minersOffline: 0,
      //                   minersOnline: 0,
      //                   connectedDevices: 0,
      //                   power: 0
      //                };

      //                if (minerResponse.data) {
      //                   const findMinter = minerResponse.data.minersOnline.find((el) => el.device === pool.minterID);
      //                   if (findMinter) {
      //                      poolData.isMinterActive = true;
      //                   }

      //                   poolData.minersOffline = minerResponse.data.numberOffline || 0;
      //                   poolData.minersOnline = minerResponse.data.numberOnline || 0;

      //                   if (poolData.isMinterActive) {
      //                      if (minerResponse.data.minersOnline?.length > 0) {
      //                         poolData.power = minerResponse.data.minersOnline.reduce(
      //                            (acc, cur) => acc + cur.power,
      //                            0
      //                         );
      //                      }
      //                   } else {
      //                      poolData.power = 0;
      //                   }
      //                }

      //                if (connectedResponse.data && connectedResponse.data.connectedDevices) {
      //                   if (poolData.isMinterActive) {
      //                      poolData.connectedDevices = connectedResponse.data.connectedDevices;
      //                   } else {
      //                      poolData.connectedDevices = 0;
      //                   }
      //                }

      //                poolsData.push(poolData);
      //             }
      //          } catch (err) {
      //             console.log(err);
      //          }
      //       }

      //       if (poolsData.length > 0) {
      //          const minersOffline = poolsData.reduce((acc, curr) => acc + curr.minersOffline, 0);
      //          const minersOnline = poolsData.reduce((acc, curr) => acc + curr.minersOnline, 0);
      //          const connectedDevices = poolsData.reduce((acc, curr) => acc + curr.connectedDevices, 0);
      //          const power = poolsData.reduce((acc, curr) => acc + curr.power, 0);

      //          const aggregate = {
      //             minersOffline,
      //             minersOnline,
      //             connectedDevices,
      //             power
      //          }

      //          setLoadingAggregatedPools(false);
      //          setAggregatedPools(aggregate);
      //       }
      //    } else {
      //       setAggregatedPools({
      //          minersOffline: 0,
      //          minersOnline: 0,
      //          connectedDevices: 0,
      //          power: 0
      //       });
      //       setLoadingAggregatedPools(false);
      //    }
      // }

      // fetchData();
      getXdenStake();
      getXdenReward();
   }, []);

   const firstRow = {
      // poolsNumber: poolsNumber,
      aggregatedPools: aggregatedPools,
      xdenStake: xdenStake,
      // loadingPoolsNumber: loadingPoolsNumber,
      loadingAggregatedPools: loadingAggregatedPools,
      loadingXdenStake: loadingXdenStake,
      latestRewards: latestRewards
   };

   return (
      <Container fluid>

         <PoolsOverviewHeader activeFilter={activeFilter} setActiveFilter={setActiveFilter} />

         <Fade>
            <FirstRow details={firstRow} />
            <SecondRow />
         </Fade>
      </Container>
   );
};

export default PoolsOverview;
