import React from "react";
import "../Mining.scss";
import { ReactComponent as Info } from "../../../../../Assets/SVGs/info.svg";
import { ReactComponent as SpeedIcon } from "../../../../../Assets/SVGs/speedometer.svg";
import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ReactComponent as AddPool } from "../../../../../Assets/SVGs/add-pool.svg";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../../../Utils/routes";
import Chart from "react-apexcharts";
import ThemeLoader from "../../../../../Components/Loader/Loader";

const FirstRow = ({ details }) => {
   const { aggregatedPools, loadingAggregatedPools } = details;

   const navigate = useNavigate();

   const handleCreatePool = () => {
      navigate(routes.createPool);
   }

   return (
      <Row xl={6} lg={3} md={3} xs={2} className="mining-first-row">
         <Col className="mb-4">
            <div className="main-box height-252">
               <div onClick={handleCreatePool} className="d-flex h-100 flex-column justify-content-center align-items-center cursor-pointer">
                  <AddPool className="card-icons" />
                  <p className="font-pnb mb-0 font-size-28 white-color text-center">Create new Pool</p>
               </div>
            </div>
         </Col>

         <Col className="mb-4">
            <div className="main-box height-252">
               <div className="d-flex justify-content-between">
                  <p className="font-pnr white-color font-size-20">Active pools</p>
                  {/* info icon */}
                  <OverlayTrigger placement="top" overlay={<Tooltip>Number of active and total pools </Tooltip>}>
                     <Info className="cursor-pointer info-icons" />
                  </OverlayTrigger>
               </div>
               <div className="d-flex flex-column justify-content-center align-items-center">
                  {loadingAggregatedPools ? (
                     <ThemeLoader />
                  ) : (
                     <Chart type="radialBar" series={[aggregatedPools?.myActivePoolsNumber / (aggregatedPools?.myPoolsNumber) * 100]} options={{
                        plotOptions: {
                           radialBar: {
                              hollow: {
                                 size: "60%",
                              },
                              track: {
                                 background: "rgba(155, 53, 255, 0.2)"
                              },

                              dataLabels: {
                                 showOn: "always",
                                 name: {
                                    offsetY: 10,
                                    show: true,
                                    color: "#FFFFFF",
                                    fontSize: "30px"
                                 },
                                 value: {
                                    color: "#111",
                                    fontSize: "30px",
                                    show: false,
                                 }
                              }
                           },
                        },
                        fill: {
                           colors: "#9B35FF"
                        },
                        stroke: {
                           lineCap: "round",
                        },
                        labels: [`${aggregatedPools?.myActivePoolsNumber}/${aggregatedPools?.myPoolsNumber}`]
                     }} height="200" />
                  )}
               </div>
            </div>
         </Col>

         <Col className="mb-4">
            <div className="main-box height-252 d-flex justify-content-between flex-column">
               <div className="d-flex justify-content-between w-100 mb-3">
                  <p className="font-pnr white-color font-size-20">Total power</p>
                  {/* info icon */}
                  <OverlayTrigger placement="top" overlay={<Tooltip>Combined mining power of the active miners and boosters</Tooltip>}>
                     <Info className="cursor-pointer info-icons" />
                  </OverlayTrigger>
               </div>
               <div className="d-flex flex-column justify-content-center align-items-center">
                  {/* <p className="font-pnsb mb-0 font-size-40 white-color text-center">icon</p> */}
                  <SpeedIcon className="card-icons" />
                  {loadingAggregatedPools ? (
                     <ThemeLoader />
                  ) : (
                     <p className="font-pnsb mb-0 font-size-32 text-white text-center">{(((0.01 * Number(aggregatedPools.connectedDevices)) * aggregatedPools.power) + aggregatedPools.power).toFixed(2)} cycles</p>
                  )}
               </div>
            </div>
         </Col>

         <Col className="mb-4 d-flex flex-column justify-content-between gap-4">
            <div className="main-box height-114 d-flex justify-content-between align-items-start flex-column">
               <div className="d-flex w-100 justify-content-between">
                  <p className="font-pnr white-color font-size-20 mb-0">Mining power</p>
                  {/* info icon */}
                  <OverlayTrigger placement="top" overlay={<Tooltip>Total of mining cycles from active miner devices</Tooltip>}>
                     <Info className="cursor-pointer info-icons" />
                  </OverlayTrigger>
               </div>
               <div className="d-flex flex-column justify-content-center align-items-center">
                  {loadingAggregatedPools ? (
                     <ThemeLoader />
                  ) : (
                     <p className="font-pnsb mb-0 font-size-32 white-color text-center">{aggregatedPools?.power ? aggregatedPools.power.toFixed(2) : 0} cycles</p>
                  )}
               </div>
            </div>
            <div className="main-box height-114 d-flex justify-content-between align-items-start flex-column">
               <div className="d-flex w-100 justify-content-between">
                  <p className="font-pnr white-color font-size-20 mb-0">Booster power</p>
                  {/* info icon */}
                  <OverlayTrigger placement="top" overlay={<Tooltip>Mining cycles granted by booster devices</Tooltip>}>
                     <Info className="cursor-pointer info-icons" />
                  </OverlayTrigger>
               </div>
               <div className="d-flex flex-column justify-content-center align-items-center">
                  {loadingAggregatedPools ? (
                     <ThemeLoader />
                  ) : (
                     <p className="font-pnsb mb-0 font-size-32 white-color text-center">{((0.01 * Number(aggregatedPools.connectedDevices)) * aggregatedPools.power).toFixed(2)} cycles</p>
                  )}
               </div>
            </div>
         </Col>

         <Col className="mb-4">
            <div className="main-box height-252">
               <div className="d-flex justify-content-between">
                  <p className="font-pnr white-color font-size-20">Active miners</p>
                  {/* info icon */}
                  <OverlayTrigger placement="top" overlay={<Tooltip>Number of active miners</Tooltip>}>
                     <Info className="cursor-pointer info-icons" />
                  </OverlayTrigger>
               </div>
               <div className="d-flex flex-column justify-content-center align-items-center">
                  {loadingAggregatedPools ? (
                     <ThemeLoader />
                  ) : (
                     <Chart type="radialBar" series={[aggregatedPools?.minersOnline / (aggregatedPools?.minersOnline + aggregatedPools?.minersOffline) * 100]} options={{
                        plotOptions: {
                           radialBar: {
                              hollow: {
                                 size: "60%",
                              },
                              track: {
                                 background: "#12401E"
                              },

                              dataLabels: {
                                 showOn: "always",
                                 name: {
                                    offsetY: 10,
                                    show: true,
                                    color: "#FFFFFF",
                                    fontSize: "30px"
                                 },
                                 value: {
                                    color: "#111",
                                    fontSize: "30px",
                                    show: false,
                                 }
                              }
                           },
                        },
                        fill: {
                           colors: "#58FF89"
                        },
                        stroke: {
                           lineCap: "round",
                        },
                        labels: [`${aggregatedPools?.minersOnline}/${aggregatedPools?.minersOnline + aggregatedPools?.minersOffline}`]
                     }} height="200" />
                  )}
               </div>
            </div>
         </Col>

         <Col className="mb-4 d-flex flex-column justify-content-between gap-4">
            <div className="main-box height-114 d-flex justify-content-between align-items-start flex-column">
               <div className="d-flex w-100 justify-content-between">
                  <p className="font-pnr white-color font-size-20 mb-0">Total miners</p>
               </div>
               <div className="d-flex flex-column justify-content-center align-items-center">
                  {loadingAggregatedPools ? (
                     <ThemeLoader />
                  ) : (
                     <p className="font-pnsb mb-0 font-size-32 white-color text-center">{aggregatedPools.minersOnline + aggregatedPools.minersOffline} miners</p>
                  )}
               </div>
            </div>
            <div className="main-box height-114 d-flex justify-content-between align-items-start flex-column">
               <div className="d-flex w-100 justify-content-between">
                  <p className="font-pnr white-color font-size-20 mb-0">Devices connected</p>
               </div>
               <div className="d-flex flex-column justify-content-center align-items-center">
                  {loadingAggregatedPools ? (
                     <ThemeLoader />
                  ) : (
                     <p className="font-pnsb mb-0 font-size-32 white-color text-center">{aggregatedPools.connectedDevices} devices</p>
                  )}
               </div>
            </div>
         </Col>
      </Row>
   );
};

export default FirstRow;
