import React from "react";
import "../Staking.scss";
import { ReactComponent as Info } from "../../../../../Assets/SVGs/info.svg";
import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ReactComponent as BaggsAmount } from "../../../../../Assets/SVGs/money-bag.svg";
import { ReactComponent as StakingSupply } from "../../../../../Assets/SVGs/metro-stack.svg";
// import { ReactComponent as BurnSupply } from "../../../../../Assets/SVGs/awesome-burn.svg";
import { ReactComponent as XdenIcon } from "../../../../../Assets/SVGs/xden_icon.svg";
import ThemeLoader from "../../../../../Components/Loader/Loader";

const FirstRow = ({ details }) => {
  const { xdenStake, privateRewardTotal, publicRewardTotal, publicRewardYesterday, loadingXdenStake, loadingXdenRewards } = details;

  return (
    <Row xs={2} lg={3}>
      <Col md={12} sm={12} className="mb-4 d-flex flex-column justify-content-between gap-4">
        <div className="main-box height-114 d-flex justify-content-between align-items-start flex-column">
          <div className="d-flex w-100 justify-content-between">
            <p className="font-pnr white-color font-size-20 mb-0">Total reward from public</p>
            {/* info icon */}
            <OverlayTrigger placement="top" overlay={<Tooltip>Total rewards earned in the public difficulty</Tooltip>}>
              <Info className="cursor-pointer" />
            </OverlayTrigger>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center">
            {loadingXdenRewards ? (
              <ThemeLoader />
            ) : (
              <p className="font-pnsb d-flex align-items-center mb-0 font-size-32 white-color text-center">{publicRewardTotal?.toFixed(2)} <XdenIcon /></p>
            )}
          </div>
        </div>
        <div className="main-box height-114 d-flex justify-content-between align-items-start flex-column">
          <div className="d-flex w-100 justify-content-between">
            <p className="font-pnr white-color font-size-20 mb-0">24h reward from public</p>
            {/* info icon */}
            <OverlayTrigger placement="top" overlay={<Tooltip>Current daily rewards in the public difficulty</Tooltip>}>
              <Info className="cursor-pointer" />
            </OverlayTrigger>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center">
            {loadingXdenRewards ? (
              <ThemeLoader />
            ) : (
              <p className="font-pnsb d-flex align-items-center mb-0 font-size-32 white-color text-center">{publicRewardYesterday?.toFixed(2)} <XdenIcon /></p>
            )}
          </div>
        </div>
      </Col>

      <Col md={12} sm={12} className="mb-4">
        <div className="main-box height-252 d-flex flex-column">
          <div className="d-flex justify-content-between mb-3">
            <p className="font-pnr white-color font-size-20">Staking supply</p>
            {/* info icon */}
            <OverlayTrigger placement="top" overlay={<Tooltip>Amount of XDEN currently in stake</Tooltip>}>
              <Info className="cursor-pointer" />
            </OverlayTrigger>
          </div>
          <div className="d-flex inherit flex-column justify-content-center align-items-center">
            {/* main icon */}
            <StakingSupply className="staking-card-icons" />
            {loadingXdenStake ? (
              <ThemeLoader />
            ) : (
              <p className="font-pnsb mb-0 d-flex align-items-center font-size-40 white-color">{xdenStake} <XdenIcon /></p>
            )}
          </div>
        </div>
      </Col>

      <Col md={12} xs={12} className="mb-4 d-flex flex-column justify-content-between gap-4">
        <div className="main-box height-114 d-flex justify-content-between align-items-start flex-column">
          <div className="d-flex w-100 justify-content-between">
            <p className="font-pnr white-color font-size-20 mb-0">Locked amount</p>
            {/* info icon */}
            <OverlayTrigger placement="top" overlay={<Tooltip>Amount of XDEN locked in staking</Tooltip>}>
              <Info className="cursor-pointer" />
            </OverlayTrigger>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center">
            {loadingXdenRewards ? (
              <ThemeLoader />
            ) : (
              <p className="font-pnsb d-flex align-items-center mb-0 font-size-32 white-color text-center">
                {(Number(xdenStake) - Number(privateRewardTotal)).toFixed(2)} <XdenIcon />
              </p>
            )}
          </div>
        </div>
        <div className="main-box height-114 d-flex justify-content-between align-items-start flex-column">
          <div className="d-flex w-100 justify-content-between">
            <p className="font-pnr white-color font-size-20 mb-0">Unlocked amount</p>
            {/* info icon */}
            <OverlayTrigger placement="top" overlay={<Tooltip>Amount of XDEN unlocked and kept in staking</Tooltip>}>
              <Info className="cursor-pointer" />
            </OverlayTrigger>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center">
            {loadingXdenRewards ? (
              <ThemeLoader />
            ) : (
              <p className="font-pnsb d-flex align-items-center mb-0 font-size-32 white-color text-center">
                {privateRewardTotal.toFixed(2)} <XdenIcon />
              </p>
            )}
          </div>
        </div>
      </Col>

    </Row>
  );
};

export default FirstRow;
