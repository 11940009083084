import React from "react";
import "./Hero.scss";
import { Container, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap"
import { ReactComponent as Info } from "../../../../Assets/SVGs/info.svg";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../../Utils/routes";

const ActivatePool = () => {
    const navigate = useNavigate();

    const activatePoolTp = <Tooltip></Tooltip>;

    const handleActivatePool = () => {
        navigate(routes.activatePool);
    }

    return (
        <Container fluid>
            <Row>
                <Col className="mb-4">
                    <div className="hero-box main-box height-252">
                        <div className="hero-info-icon">
                            <OverlayTrigger placement="top" overlay={activatePoolTp}>
                                <Info className="cursor-pointer info-icons" />
                            </OverlayTrigger>
                        </div>
                        <div className="max-width-desktop d-flex flex-column h-100 justify-content-center align-items-center gap-3 mt-sm-0 mt-2">
                            <p className="font-pnsb mb-0 font-size-48 white-color text-center">Congrats! Now activate your pool by
                                clicking the button below
                            </p>
                            <button onClick={handleActivatePool} className="buttonYellow">Activate Pool</button>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container >
    );
};

export default ActivatePool;