import React from "react";
import { Container } from "react-bootstrap";

import MyPoolsHeader from "./MyPoolsHeader/MyPoolsHeader";
import MyPoolsTable from "./MyPoolsTable/MyPoolsTable";

const MyPools = () => {
   return (
      <Container fluid>
         <MyPoolsHeader />
         <MyPoolsTable />
      </Container>
   );
};

export default MyPools;
