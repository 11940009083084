import React, { useState, useEffect } from "react";
import "./ActivatePool.scss";
import { steps } from "../../Utils/setupSteps";
import ActivatePoolStep1 from "./Steps/ActivatePoolStep1";
import ActivatePoolStep2 from "./Steps/ActivatePoolStep2";
import ActivatePoolStep3 from "./Steps/ActivatePoolStep3";
import ActivatePoolStep4 from "./Steps/ActivatePoolStep4";

const ActivatePool = () => {
   // state
   const [wallet, setWallet] = useState("");
   const [activeStep, setActiveStep] = useState(() => steps.step_1);

   // utils
   let stepToRender;

   // functions
   const handleActiveStep = step => setActiveStep(step);

   switch (activeStep) {
      case steps.step_1:
         stepToRender = <ActivatePoolStep1 handleActiveStep={handleActiveStep} setWallet={setWallet} />;
         break;

      case steps.step_2:
         stepToRender = <ActivatePoolStep2 handleActiveStep={handleActiveStep} wallet={wallet} />;
         break;

      case steps.step_3:
         stepToRender = <ActivatePoolStep3 handleActiveStep={handleActiveStep} />;
         break;

      case steps.step_4:
         stepToRender = <ActivatePoolStep4 handleActiveStep={handleActiveStep} />;
         break;

      default:
         stepToRender = <ActivatePoolStep1 handleActiveStep={handleActiveStep} />;
   }

   useEffect(() => {
      window.scrollTo(0, 0)
   }, []);

   return (
      <div className="activate-pool py-3 text-white">
         <div className="container-main d-flex justify-content-center align-items-center">{stepToRender}</div>
      </div>
   );
};

export default ActivatePool;
