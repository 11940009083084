import React, { useState } from "react";
import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import ChartOverlay from "../../../../../Components/ChartOverlay/ChartOverlay";
import { ReactComponent as GreenDotIcon } from "../../../../../Assets/SVGs/green-dot.svg";
import { ReactComponent as YellowDotIcon } from "../../../../../Assets/SVGs/yellow-dot.svg";
import { ReactComponent as Info } from "../../../../../Assets/SVGs/info.svg";
import ThemeChart from "../../../../../Components/Chart/Chart";

const FifthRow = () => {
   // state
   const [activeButton, setActiveButton] = useState(() => "day");

   // tooltips
   const chartLegendTooltip = (
      <Tooltip>
         <div className="d-flex flex-column">
            <span className="font-pnr font-size-16 mb-3">
               <GreenDotIcon /> Devices
            </span>
            <span className="font-pnr font-size-16">
               <YellowDotIcon /> Miners
            </span>
         </div>
      </Tooltip>
   );

   return (
      <Row>
         <Col className="mb-4">
            <div className="main-box height-252 position-relative d-flex align-items-end">

               <ThemeChart
                  uri={`${process.env.REACT_APP_BACKEND_URL}/mining-stats/allpower/`}
                  measurement="bonus"
                  singleData={undefined}
                  excludeDates={["24hours"]}
                  defaultDate="7days"
                  single={true}
                  label="Bonus"
                  chartTitle="Mining power" />

            </div>
         </Col>
      </Row>
   );
};

export default FifthRow;
