import React, { useState, useEffect, useRef } from 'react';
import { Form } from 'react-bootstrap';
// import './Styling/StepsStyling.scss';
import Dropzone from 'react-dropzone';
import { BsCardImage } from 'react-icons/bs';
import { GoVerified } from 'react-icons/go';
import { FormControl } from 'react-bootstrap';
import GuideModal from './Guide/GuideModal';
import { useKycContext } from '../KycContext/KycProvider';

const SendData = ({ chosenLevel })=> {
  const [show, setShow] = useState(false);

  const { kycData, updateFiles, updatePersonalDetails } = useKycContext();
  const textAreaRef = useRef();

  const onHide = () => setShow(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [show]);

  useEffect(() => {
    setShow(true);
  }, []);

  const disablePastDate = ()=> {
    const today = new Date();
    const dd = String(today.getDate() + 1).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const yyyy = today.getFullYear();
    const hh = String(today.getHours()).padStart(2, '0');
    const min = String(today.getMinutes()).padStart(2, '0');

    return yyyy + '-' + mm + '-' + dd + 'T' + hh + ':' + min;
  };

  const autoGrow = ()=> {
    textAreaRef.current.style.height = '5px';
    textAreaRef.current.style.height = textAreaRef.current.scrollHeight + 'px';
  };

  const realLength = kycData.files.filter((n) => n);
  const backsideIdCardExists = kycData.files.some((file) => file.name.includes('backsideIdCard'));

  return (
    <div className="steps-styling">
      <p className="font-size-24 font-pnsb">{'Step'} 2/2:</p>
      <Form className="d-flex flex-column align-items-center">
        {(() => {
          switch (chosenLevel) {
            case 1:
              return (
                <>
                  <Dropzone
                    onDrop={(files) => updateFiles(files, 0, 'idCard')}
                    maxSize={10000000}
                    multiple={false}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className="custom-flex w-100 mb-2 add-doc-input" {...getRootProps()}>
                        <div className="d-flex justify-content-between align-items-center font-pnr">
                          <input {...getInputProps()} />
                          <Form.Label className="mr-2 mb-0 font-size-16 flex-label text-left font-pnr">
                            {' '}
                            {'Valid Passport or Government-issued ID:'}
                          </Form.Label>
                          <button className="uploader" onClick={(event) => event.preventDefault()}>
                            <BsCardImage className="upload-icon mx-2" />
                          </button>
                        </div>
                        {kycData.files.length > 0 && kycData.files[0] && (
                          <p className="mb-0 font-pnr font-size-14 uploaded-file">
                            {'Uploaded'}:{' '}
                            <span className="color-neon-green">{kycData.files[0].realName}</span>
                          </p>
                        )}
                      </div>
                    )}
                  </Dropzone>

                  <Dropzone
                    onDrop={(files) => updateFiles(files, 1, 'backsideIdCard')}
                    maxSize={10000000}
                    multiple={false}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className="custom-flex w-100 mb-2 add-doc-input" {...getRootProps()}>
                        <div className="d-flex justify-content-between align-items-center font-pnr">
                          <input {...getInputProps()} />
                          <Form.Label className="mr-2 mb-0 font-size-16 flex-label text-left font-pnr">
                            {'Backside of Government-issued ID:'}
                          </Form.Label>
                          <button className="uploader" onClick={(event) => event.preventDefault()}>
                            <BsCardImage className="upload-icon mx-2" />
                          </button>
                        </div>
                        {/* <p className="mb-0 font-pnr font-size-14 uploaded-file color-neon-green">
                          {'*Not required for Passport.'}
                        </p> */}
                        {kycData.files.length > 0 && kycData.files[1] && (
                          <p className="mb-0 font-pnr font-size-14 uploaded-file">
                            {'Uploaded'}:{' '}
                            <span className="color-neon-green">{kycData.files[1].realName}</span>
                          </p>
                        )}
                      </div>
                    )}
                  </Dropzone>
{/* 
                  <Dropzone
                    onDrop={(files) => updateFiles(files, 2, 'selfieIdCard')}
                    maxSize={10000000}
                    multiple={false}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className="custom-flex w-100 mb-2" {...getRootProps()}>
                        <div className="d-flex  justify-content-between font-pnr">
                          <input {...getInputProps()} />
                          <Form.Label className="mr-2 mb-0 font-size-16 flex-label text-left font-pnr">
                            {'Selfie with Passport / ID in hand'}:{' '}
                          </Form.Label>
                          <button className="uploader" onClick={(event) => event.preventDefault()}>
                            <BsCardImage className="upload-icon mx-2" />
                          </button>
                        </div>
                        {kycData.files.length > 0 && kycData.files[2] && (
                          <p className="mb-0 font-pnr font-size-14 uploaded-file">
                            {'Uploaded'}:{' '}
                            <span className="color-neon-green">{kycData.files[2].realName}</span>
                          </p>
                        )}
                      </div>
                    )}
                  </Dropzone> */}

                  <Dropzone
                    onDrop={(files) => updateFiles(files, 2, 'utilityBill')}
                    maxSize={10000000}
                    multiple={false}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps()} className="w-100 custom-flex add-doc-input">
                        <div className="d-flex justify-content-between align-items-center font-pnr">
                          <input {...getInputProps()} />
                          <Form.Label className="mr-2 mb-0 font-size-16 flex-label text-left font-pnr">
                            {'Proof of Address (utility bill)'}:{' '}
                          </Form.Label>
                          <button className="uploader" onClick={(event) => event.preventDefault()}>
                            <BsCardImage className="upload-icon mx-2" />
                          </button>
                        </div>
                        {kycData.files.length > 0 && kycData.files[2] && (
                          <p className="mb-0 font-pnr font-size-14 uploaded-file">
                            {'Uploaded'}:{' '}
                            <span className="color-neon-green">{kycData.files[2].realName}</span>
                          </p>
                        )}
                      </div>
                    )}
                  </Dropzone>

                  <div className="d-flex align-items-center my-3">
                    <p className="mb-0 font-size-16 mr-2 font-pnr">
                      {'Uploaded'}: {realLength.length}/3
                    </p>
                    {((backsideIdCardExists && realLength.length === 4) ||
                      (!backsideIdCardExists && realLength.length === 3)) && (
                      <GoVerified className="go-verified" />
                    )}
                  </div>
                </>
              );
            case 2:
              return (
                <>
                  <Dropzone
                    onDrop={(files) => updateFiles(files, 0, 'idCard')}
                    maxSize={10000000}
                    multiple={false}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className="custom-flex w-100 mb-2 add-doc-input" {...getRootProps()}>
                        <div className="d-flex justify-content-between align-items-center font-pnr">
                          <input {...getInputProps()} />
                          <Form.Label className="mr-2 mb-0 font-size-16 flex-label text-left font-pnr">
                            {' '}
                            {'Valid Passport or Government-issued ID:'}
                          </Form.Label>
                          <button className="uploader" onClick={(event) => event.preventDefault()}>
                            <BsCardImage className="upload-icon mx-2" />
                          </button>
                        </div>
                        {kycData.files.length > 0 && kycData.files[0] && (
                          <p className="mb-0 font-pnr font-size-14 uploaded-file">
                            {'Uploaded'}:{' '}
                            <span className="color-neon-green">{kycData.files[0].realName}</span>
                          </p>
                        )}
                      </div>
                    )}
                  </Dropzone>

                  <Dropzone
                    onDrop={(files) => updateFiles(files, 1, 'backsideIdCard')}
                    maxSize={10000000}
                    multiple={false}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className="custom-flex w-100 mb-2" {...getRootProps()}>
                        <div className="d-flex justify-content-between align-items-center font-pnr">
                          <input {...getInputProps()} />
                          <Form.Label className="mr-2 mb-0 font-size-16 flex-label text-left font-pnr">
                            {'Backside of Government-issued ID:'}
                          </Form.Label>
                          <button className="uploader" onClick={(event) => event.preventDefault()}>
                            <BsCardImage className="upload-icon mx-2" />
                          </button>
                        </div>
                        <p className="mb-0 font-pnr font-size-14 uploaded-file color-neon-green">
                          {'*Not required for Passport.'}
                        </p>
                        {kycData.files.length > 0 && kycData.files[1] && (
                          <p className="mb-0 font-pnr font-size-14 uploaded-file">
                            {'Uploaded'}:{' '}
                            <span className="color-neon-green">{kycData.files[1].realName}</span>
                          </p>
                        )}
                      </div>
                    )}
                  </Dropzone>

                  <Dropzone
                    onDrop={(files) => updateFiles(files, 2, 'selfieIdCard')}
                    maxSize={10000000}
                    multiple={false}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className="custom-flex w-100 mb-2" {...getRootProps()}>
                        <div className="d-flex  justify-content-between font-pnr">
                          <input {...getInputProps()} />
                          <Form.Label className="mr-2 mb-0 font-size-16 flex-label text-left font-pnr">
                            {'Selfie with Passport / ID in hand'}:{' '}
                          </Form.Label>
                          <button className="uploader" onClick={(event) => event.preventDefault()}>
                            <BsCardImage className="upload-icon mx-2" />
                          </button>
                        </div>
                        {kycData.files.length > 0 && kycData.files[2] && (
                          <p className="mb-0 font-pnr font-size-14 uploaded-file">
                            {'Uploaded'}:{' '}
                            <span className="color-neon-green">{kycData.files[2].realName}</span>
                          </p>
                        )}
                      </div>
                    )}
                  </Dropzone>

                  <Dropzone
                    onDrop={(files) => updateFiles(files, 3, 'utilityBill')}
                    maxSize={10000000}
                    multiple={false}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps()} className="w-100 custom-flex mb-2">
                        <div className="d-flex justify-content-between align-items-center font-pnr">
                          <input {...getInputProps()} />
                          <Form.Label className="mr-2 mb-0 font-size-16 flex-label text-left font-pnr">
                            {'Proof of Address (utility bill)'}:{' '}
                          </Form.Label>
                          <button className="uploader" onClick={(event) => event.preventDefault()}>
                            <BsCardImage className="upload-icon mx-2" />
                          </button>
                        </div>
                        {kycData.files.length > 0 && kycData.files[3] && (
                          <p className="mb-0 font-pnr font-size-14 uploaded-file">
                            {'Uploaded'}:{' '}
                            <span className="color-neon-green">{kycData.files[3].realName}</span>
                          </p>
                        )}
                      </div>
                    )}
                  </Dropzone>

                  <Dropzone
                    onDrop={(files) => updateFiles(files, 4, 'proofOfFunds')}
                    maxSize={10000000}
                    multiple={false}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps()} className="w-100 custom-flex mb-2">
                        <div className="d-flex justify-content-between align-items-center font-pnr">
                          <input {...getInputProps()} />
                          <Form.Label className="mr-2 mb-0 font-size-16 flex-label text-left font-pnr">
                            {'Proof of funds'}:{' '}
                          </Form.Label>
                          <button className="uploader" onClick={(event) => event.preventDefault()}>
                            <BsCardImage className="upload-icon mx-2" />
                          </button>
                        </div>
                        {kycData.files.length > 0 && kycData.files[4] && (
                          <p className="mb-0 font-pnr font-size-14 uploaded-file">
                            {'Uploaded'}:{' '}
                            <span className="color-neon-green">{kycData.files[4].realName}</span>
                          </p>
                        )}
                      </div>
                    )}
                  </Dropzone>

                  <div className="w-100 custom-flex">
                    <div className="font-pnr">
                      <Form.Label className="mr-2 mb-0 font-size-16 flex-label text-left font-pnr mb-2">
                        {'Source of wealth explanation'}:{' '}
                      </Form.Label>
                      <textarea
                        ref={textAreaRef}
                        onInput={autoGrow}
                        className="kyc-link-input text-area-kyc primary-input"
                        onChange={(event) =>
                          updatePersonalDetails('sourceOfWealthExplanation', event.target.value)
                        }
                        placeholder="Minimum 50 characters"
                        style={{ maxWidth: '100%', width: '100%' }}
                      />
                    </div>
                    {kycData.personalDetails?.sourceOfWealthExplanation &&
                      kycData.personalDetails?.sourceOfWealthExplanation.length > 50 && (
                        <div className="d-flex flex-wrap align-items-center">
                          <p className="mb-0 font-pnr font-size-14 uploaded-file mr-1">
                            {kycData.personalDetails?.sourceOfWealthExplanation.length}{' '}
                            {'characters'}
                          </p>
                          <GoVerified className="go-verified" style={{ width: '15px' }} />
                        </div>
                      )}
                  </div>

                  <div className="d-flex align-items-center my-3">
                    <p className="mb-0 font-size-16 mr-2 font-pnr">
                      {'Uploaded'}: {realLength.length}/5
                    </p>
                    {((backsideIdCardExists && realLength.length === 5) ||
                      (!backsideIdCardExists && realLength.length === 4)) && (
                      <GoVerified className="go-verified" />
                    )}
                  </div>
                </>
              );
            case 3:
              return (
                <>
                  <Dropzone
                    onDrop={(files) => updateFiles(files, 0, 'idCard')}
                    maxSize={10000000}
                    multiple={false}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className="custom-flex w-100 mb-2" {...getRootProps()}>
                        <div className="d-flex justify-content-between align-items-center font-pnr">
                          <input {...getInputProps()} />
                          <Form.Label className="mr-2 mb-0 font-size-16 flex-label text-left font-pnr">
                            {' '}
                            {'Valid Passport or Government-issued ID:'}
                          </Form.Label>
                          <button className="uploader" onClick={(event) => event.preventDefault()}>
                            <BsCardImage className="upload-icon mx-2" />
                          </button>
                        </div>
                        {kycData.files.length > 0 && kycData.files[0] && (
                          <p className="mb-0 font-pnr font-size-14 uploaded-file">
                            {'Uploaded'}:{' '}
                            <span className="color-neon-green">{kycData.files[0].realName}</span>
                          </p>
                        )}
                      </div>
                    )}
                  </Dropzone>

                  <Dropzone
                    onDrop={(files) => updateFiles(files, 1, 'backsideIdCard')}
                    maxSize={10000000}
                    multiple={false}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className="custom-flex w-100 mb-2" {...getRootProps()}>
                        <div className="d-flex justify-content-between align-items-center font-pnr">
                          <input {...getInputProps()} />
                          <Form.Label className="mr-2 mb-0 font-size-16 flex-label text-left font-pnr">
                            {'Backside of Government-issued ID:'}
                          </Form.Label>
                          <button className="uploader" onClick={(event) => event.preventDefault()}>
                            <BsCardImage className="upload-icon mx-2" />
                          </button>
                        </div>
                        <p className="mb-0 font-pnr font-size-14 uploaded-file color-neon-green">
                          {'*Not required for Passport.'}
                        </p>
                        {kycData.files.length > 0 && kycData.files[1] && (
                          <p className="mb-0 font-pnr font-size-14 uploaded-file">
                            {'Uploaded'}:{' '}
                            <span className="color-neon-green">{kycData.files[1].realName}</span>
                          </p>
                        )}
                      </div>
                    )}
                  </Dropzone>

                  <Dropzone
                    onDrop={(files) => updateFiles(files, 2, 'selfieIdCard')}
                    maxSize={10000000}
                    multiple={false}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className="custom-flex w-100 mb-2" {...getRootProps()}>
                        <div className="d-flex  justify-content-between font-pnr">
                          <input {...getInputProps()} />
                          <Form.Label className="mr-2 mb-0 font-size-16 flex-label text-left font-pnr">
                            {'Selfie with Passport / ID in hand'}:{' '}
                          </Form.Label>
                          <button className="uploader" onClick={(event) => event.preventDefault()}>
                            <BsCardImage className="upload-icon mx-2" />
                          </button>
                        </div>
                        {kycData.files.length > 0 && kycData.files[2] && (
                          <p className="mb-0 font-pnr font-size-14 uploaded-file">
                            {'Uploaded'}:{' '}
                            <span className="color-neon-green">{kycData.files[2].realName}</span>
                          </p>
                        )}
                      </div>
                    )}
                  </Dropzone>

                  <Dropzone
                    onDrop={(files) => updateFiles(files, 3, 'utilityBill')}
                    maxSize={10000000}
                    multiple={false}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps()} className="w-100 custom-flex mb-2">
                        <div className="d-flex justify-content-between align-items-center font-pnr">
                          <input {...getInputProps()} />
                          <Form.Label className="mr-2 mb-0 font-size-16 flex-label text-left font-pnr">
                            {'Proof of Address (utility bill)'}:{' '}
                          </Form.Label>
                          <button className="uploader" onClick={(event) => event.preventDefault()}>
                            <BsCardImage className="upload-icon mx-2" />
                          </button>
                        </div>
                        {kycData.files.length > 0 && kycData.files[3] && (
                          <p className="mb-0 font-pnr font-size-14 uploaded-file">
                            {'Uploaded'}:{' '}
                            <span className="color-neon-green">{kycData.files[3].realName}</span>
                          </p>
                        )}
                      </div>
                    )}
                  </Dropzone>

                  <Dropzone
                    onDrop={(files) => updateFiles(files, 4, 'proofOfFunds')}
                    maxSize={10000000}
                    multiple={false}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps()} className="w-100 custom-flex mb-2">
                        <div className="d-flex justify-content-between align-items-center font-pnr">
                          <input {...getInputProps()} />
                          <Form.Label className="mr-2 mb-0 font-size-16 flex-label text-left font-pnr">
                            {'Proof of funds'}:{' '}
                          </Form.Label>
                          <button className="uploader" onClick={(event) => event.preventDefault()}>
                            <BsCardImage className="upload-icon mx-2" />
                          </button>
                        </div>
                        {kycData.files.length > 0 && kycData.files[4] && (
                          <p className="mb-0 font-pnr font-size-14 uploaded-file">
                            {'Uploaded'}:{' '}
                            <span className="color-neon-green">{kycData.files[4].realName}</span>
                          </p>
                        )}
                      </div>
                    )}
                  </Dropzone>

                  <div className="w-100 custom-flex mb-2">
                    <div className="font-pnr">
                      <Form.Label className="mr-2 mb-0 font-size-16 flex-label text-left font-pnr mb-2">
                        {'Source of wealth explanation'}:{' '}
                      </Form.Label>
                      <textarea
                        ref={textAreaRef}
                        onInput={autoGrow}
                        className="kyc-link-input text-area-kyc primary-input"
                        onChange={(event) =>
                          updatePersonalDetails('sourceOfWealthExplanation', event.target.value)
                        }
                        placeholder="Minimum 50 characters"
                        style={{ maxWidth: '100%', width: '100%' }}
                      />
                    </div>
                    {kycData.personalDetails?.sourceOfWealthExplanation &&
                      kycData.personalDetails?.sourceOfWealthExplanation.length > 50 && (
                        <div className="d-flex flex-wrap align-items-center">
                          <p className="mb-0 font-pnr font-size-14 uploaded-file mr-1">
                            {kycData.personalDetails?.sourceOfWealthExplanation.length}{' '}
                            {'characters'}
                          </p>
                          <GoVerified className="go-verified" style={{ width: '15px' }} />
                        </div>
                      )}
                  </div>

                  <div className="w-100 custom-flex">
                    <div className="font-pnr">
                      <Form.Label className="mr-2 mb-0 font-size-16 flex-label text-left font-pnr mb-2">
                        {'Schedule video meeting (with agent)'}:{' '}
                      </Form.Label>
                      <FormControl
                        min={disablePastDate()}
                        type="datetime-local"
                        className="kyc-link-input font-pnr w-100 primary-input"
                        style={{ maxWidth: '100%' }}
                        aria-label=""
                        aria-describedby="basic-addon1"
                        placeholder="Date of appointment"
                        onChange={(event) =>
                          updatePersonalDetails('videoAppointment', event.target.value)
                        }
                      />
                    </div>
                    {kycData.personalDetails?.videoAppointment && (
                      <React.Fragment>
                        <p className="mb-0 font-pnr font-size-14 uploaded-file mt-1">
                          {'You will receive an invitation email on'}{' '}
                          <span className="color-neon-green">
                            {new Date(
                              kycData.personalDetails?.videoAppointment
                            ).toLocaleDateString() +
                              ' - ' +
                              new Date(
                                kycData.personalDetails?.videoAppointment
                              ).toLocaleTimeString()}
                          </span>{' '}
                          {
                            'with a link that allows you to attend the meeting for video confirmation. In the meantime, please contact us if you have any questions.'
                          }
                        </p>
                      </React.Fragment>
                    )}
                  </div>

                  <div className="d-flex align-items-center my-3">
                    <p className="mb-0 font-size-16 mr-2 font-pnr">
                      {'Uploaded'}: {realLength.length}/5
                    </p>
                    {((backsideIdCardExists && realLength.length === 5) ||
                      (!backsideIdCardExists && realLength.length === 4)) && (
                      <GoVerified className="go-verified" />
                    )}
                  </div>
                </>
              );
            default:
              return <p className="mt-3">{'Kyc Level not selected'}</p>;
          }
        })()}
      </Form>
      <GuideModal show={show} onHide={onHide} chosenLevel={chosenLevel} />
    </div>
  );
};

export default SendData;
