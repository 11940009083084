import React, { useState, useEffect } from "react";
import axios from 'axios'

import "./Rows/RowsStyling/Rows.scss";
import { Container } from "react-bootstrap";
import Fade from 'react-reveal';

import FirstRow from "./Rows/FirstRow";
import SecondRow from "./Rows/SecondRow";
import ThirdRow from "./Rows/ThirdRow";
import FourthRow from "./Rows/FourthRow";
import FifthRow from "./Rows/FifthRow";
// import SixthRow from "./Rows/SixthRow";
import PoolsTable from "./Rows/PoolsTable";

const StatisticsGrid = () => {
  // stats
  const [xdenPrice, setXdenPrice] = useState(0);
  const [xdenSupply, setXdenSupply] = useState(0);
  const [xdenMarketCap, setXdenMarketCap] = useState(0);
  const [publicDifficulty, setPublicDifficulty] = useState(0);
  const [privateDifficulty, setPrivateDifficulty] = useState(0);
  const [lastBlock, setLastBlock] = useState(0);
  const [minersNumber, setMinersNumber] = useState(0);
  const [mintersNumber, setMintersNumber] = useState(0);
  const [poolsNumber, setPoolsNumber] = useState(0);
  const [hashPower, setHashPower] = useState(0);
  const [activePoolsNumber, setActivePoolsNumber] = useState(0);

  // stats loaders
  const [loadingXdenPrice, setLoadingXdenPrice] = useState(true);
  const [loadingXdenSupply, setLoadingXdenSupply] = useState(true);
  const [loadingXdenMarketCap, setLoadingXdenMarketCap] = useState(true);
  const [loadingPublicPrivateDiffculty, setLoadingPublicPrivateDifficulty] = useState(true);
  const [loadingLastBlock, setLoadingLastBlock] = useState(true);
  const [loadingMinersNumber, setLoadingMinersNumber] = useState(true);
  const [loadingMintersNumber, setLoadingMintersNumber] = useState(true);
  const [loadingPoolsNumber, setLoadingPoolsNumber] = useState(true);
  const [loadingHashPower, setLoadingHashPower] = useState(true);
  const [loadingActivePoolsNumber, setLoadingActivePoolsNumber] = useState(true);

  const getXdenPrice = async () => {
    try {
      const checkReq = await axios.get("https://pools.xiden.com/api/price")
      if (checkReq.status === 200) {
        setXdenPrice(checkReq.data.price);
      }
    } catch (err) {
      if (err.response) {
        switch (err.response.status) {
          case 404:
            console.log("404 Not found.");
            return;
          default:
            console.log("Something is temporarily wrong.");
            return;
        }
      }
    } finally {
      setLoadingXdenPrice(false);
    }
  }

  const getXdenSupply = async () => {
    try {
      const checkReq = await axios.get("https://xiden.com/supply/")
      if (checkReq.status === 200) {
        setXdenSupply(checkReq.data);
      }
    } catch (err) {
      if (err.response) {
        switch (err.response.status) {
          case 404:
            console.log("404 Not found.");
            return;
          default:
            console.log("Something is temporarily wrong.");
            return;
        }
      }
    } finally {
      setLoadingXdenSupply(false);
    }
  }

  const getXdenMarketCap = async () => {
    try {
      const checkReq = await axios.get("https://explorer.xiden.com/api?module=stats&action=coinsupply")
      if (checkReq.status === 200) {
        setXdenMarketCap(checkReq.data);
      }
    } catch (err) {
      if (err.response) {
        switch (err.response.status) {
          case 404:
            console.log("404 Not found.");
            return;
          default:
            console.log("Something is temporarily wrong.");
            return;
        }
      }
    } finally {
      setLoadingXdenMarketCap(false);
    }
  }

  const getLastBlock = async () => {
    try {
      const checkReq = await axios.get("https://explorer.xiden.com/api?module=block&action=eth_block_number")
      if (checkReq.status === 200) {
        setLastBlock(Number(checkReq.data.result).toString(10));
      }
    } catch (err) {
      if (err.response) {
        switch (err.response.status) {
          case 404:
            console.log("404 Not found.");
            return;
          default:
            console.log("Something is temporarily wrong.");
            return;
        }
      }
    } finally {
      setLoadingLastBlock(false);
    }
  }

  const getDiff = async () => {
    var raw = {
      toggleDifficulty: false,
      size: 1,
      basmInput: 1,
      hidrInput: 0,
      minterInput: 0,
      activeDevices: 0
    };

    try {
      const checkReq = await axios.post('https://api.heimdall.ro/calculatorFunction', raw);
      if (checkReq.status === 200) {
        setPrivateDifficulty(checkReq.data.privateDifficulty);
        setPublicDifficulty(checkReq.data.publicDifficulty);
      }
    } catch (err) {
      if (err.response) {
        switch (err.response.status) {
          case 404:
            console.log("404 Not found.");
            return;
          default:
            console.log("Something is temporarily wrong.");
            return;
        }
      }
    } finally {
      setLoadingPublicPrivateDifficulty(false);
    }
  }

  const countMiners = async () => {
    try {
      const checkReq = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/miners-count",
        {
          headers: {
            Authorization: localStorage.getItem("xiden-pool-session")
          }
        }
      );

      if (checkReq.status === 200) {
        setMinersNumber(checkReq.data);
      }
    } catch (err) {
      if (err.response) {
        switch (err.response.status) {
          case 404:
            console.log("404 Not found.");
            return;
          default:
            console.log("Something is temporarily wrong.");
            return;
        }
      }
    } finally {
      setLoadingMinersNumber(false);
    }
  }

  const countMinters = async () => {
    try {
      const checkReq = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/minters-count",
        {
          headers: {
            Authorization: localStorage.getItem("xiden-pool-session")
          }
        }
      );

      if (checkReq.status === 200) {
        setMintersNumber(checkReq.data);
      }
    } catch (err) {
      if (err.response) {
        switch (err.response.status) {
          case 404:
            console.log("404 Not found.");
            return;
          default:
            console.log("Something is temporarily wrong.");
            return;
        }
      }
    } finally {
      setLoadingMintersNumber(false);
    }
  }

  const countPools = async () => {
    try {
      const checkReq = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/pools/count/all",
        {
          headers: {
            Authorization: localStorage.getItem("xiden-pool-session")
          }
        }
      );

      if (checkReq.status === 200) {
        setPoolsNumber(checkReq.data);
      }
    } catch (err) {
      if (err.response) {
        switch (err.response.status) {
          case 404:
            console.log("404 Not found.");
            return;
          default:
            console.log("Something is temporarily wrong.");
            return;
        }
      }
    } finally {
      setLoadingPoolsNumber(false);
    }
  }

  const getHashPower = async () => {
    try {
      const checkReq = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/mining-stats/total-power",
        {
          headers: {
            Authorization: localStorage.getItem("xiden-pool-session")
          }
        }
      );

      if (checkReq.status === 200) {
        setHashPower(checkReq.data);
      }
    } catch (err) {
      if (err.response) {
        switch (err.response.status) {
          case 404:
            console.log("404 Not found.");
            return;
          default:
            console.log("Something is temporarily wrong.");
            return;
        }
      }
    } finally {
      setLoadingHashPower(false);
    }
  }

  const countActivePools = async () => {
    try {
      const checkReq = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/pools/count/active",
        {
          headers: {
            Authorization: localStorage.getItem("xiden-pool-session")
          }
        }
      );

      if (checkReq.status === 200) {
        setActivePoolsNumber(checkReq.data);
      }
    } catch (err) {
      if (err.response) {
        switch (err.response.status) {
          case 404:
            console.log("404 Not found.");
            return;
          default:
            console.log("Something is temporarily wrong.");
            return;
        }
      }
    } finally {
      setLoadingActivePoolsNumber(false);
    }
  }

  useEffect(() => {
    // Create an scoped async function in the hook
    const fetchData = async () => {
      await getXdenPrice();
      await getXdenSupply();
      await getXdenMarketCap();
      await getDiff();
      await getLastBlock();
      await countMiners();
      await countMinters();
      await countPools();
      await getHashPower();
      await countActivePools();
    };

    fetchData();
  }, []);

  const firstRow = {
    minersNumber: minersNumber,
    mintersNumber: mintersNumber,
    poolsNumber: poolsNumber,
    activePoolsNumber: activePoolsNumber,
    loadingMinersNumber: loadingMinersNumber,
    loadingMintersNumber: loadingMintersNumber,
    loadingPoolsNumber: loadingPoolsNumber,
    loadingActivePoolsNumber: loadingActivePoolsNumber,
  };

  const secondRow = {
    hashPower: hashPower,
    loadingHashPower: loadingHashPower,
  }

  const thirdRow = {
    publicDifficulty: publicDifficulty,
    privateDifficulty: privateDifficulty,
    lastBlock: lastBlock,
    loadingPublicPrivateDiffculty: loadingPublicPrivateDiffculty,
    loadingLastBlock: loadingLastBlock
  };

  const fourthRow = {
    xdenPrice: xdenPrice,
    xdenSupply: xdenSupply,
    xdenMarketCap: xdenMarketCap,
    loadingXdenPrice: loadingXdenPrice,
    loadingXdenSupply: loadingXdenSupply,
    loadingXdenMarketCap: loadingXdenMarketCap
  };

  return (
    <div className="statistics-grid">
      <Container fluid>
        <p className="font-pnb font-size-32 white-color mb-3 mt-5">Statistics</p>
        <Fade>
          {/* four cols row */}
          <FirstRow details={firstRow} />
          {/* two cols row */}
          <SecondRow details={secondRow} />
          {/* three cols row */}
          <ThirdRow details={thirdRow} />
          {/* four cols row */}
          <FourthRow details={fourthRow} />
          {/* one col row */}
          <FifthRow />
          {/* three cols row */}
          {/* <SixthRow /> */}
          {/* pools table */}
          <p className="font-pnb font-size-32 white-color my-3">Pools</p>
          <PoolsTable />
        </Fade>
      </Container>
    </div>
  );
};

export default StatisticsGrid;
