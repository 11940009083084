import React from "react";
import "./RowsStyling/Rows.scss";
import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ReactComponent as Info } from "../../../../../Assets/SVGs/info.svg";
import { ReactComponent as BlueIcon } from "../../../../../Assets/SVGs/blue-difficulty.svg";
import { ReactComponent as OrangeIcon } from "../../../../../Assets/SVGs/orange-difficulty.svg";
import { ReactComponent as PurpleIcon } from "../../../../../Assets/SVGs/purple-stack.svg";
import ThemeLoader from "../../../../../Components/Loader/Loader";

const ThirdRow = ({ details }) => {
   const { publicDifficulty, privateDifficulty, lastBlock, loadingPublicPrivateDiffculty, loadingLastBlock } = details;

   return (
      <Row lg={3} xs={2}>
         <Col className="mb-4">
            <div className="main-box height-252 d-flex justify-content-between flex-column">
               <div className="d-flex justify-content-between mb-3 w-100">
                  <p className="font-pnr white-color font-size-20">Private difficulty</p>
               </div>
               <div className="d-flex flex-column justify-content-center align-items-center">
                  {/* main icon */}
                  <BlueIcon className="card-icons" />
                  {loadingPublicPrivateDiffculty ? (
                     <ThemeLoader />
                  ) : (
                     <p className="font-pnsb mb-0 font-size-40 white-color">{privateDifficulty}</p>
                  )}
               </div>
            </div>
         </Col>

         <Col className="mb-4">
            <div className="main-box height-252 d-flex justify-content-between flex-column">
               <div className="d-flex justify-content-between mb-3 w-100">
                  <p className="font-pnr white-color font-size-20">Public difficulty</p>
               </div>
               <div className="d-flex flex-column justify-content-center align-items-center">
                  {/* main icon */}
                  <OrangeIcon className="card-icons" />
                  {loadingPublicPrivateDiffculty ? (
                     <ThemeLoader />
                  ) : (
                     <p className="font-pnsb mb-0 font-size-40 white-color">{publicDifficulty}</p>
                  )}
               </div>
            </div>
         </Col>

         <Col xs={12} className="mb-4">
            <div className="main-box height-252 d-flex justify-content-between flex-column">
               <div className="d-flex justify-content-between mb-3 w-100">
                  <p className="font-pnr white-color font-size-20">Last block</p>
                  {/* info icon */}
                  <OverlayTrigger placement="top" overlay={<Tooltip>Last validated block number</Tooltip>}>
                     <Info className="cursor-pointer" />
                  </OverlayTrigger>
               </div>
               <div className="d-flex flex-column justify-content-center align-items-center">
                  {/* main icon */}
                  <PurpleIcon className="card-icons" />
                  {loadingLastBlock ? (
                     <ThemeLoader />
                  ) : (
                     <p className="font-pnsb mb-0 font-size-40 white-color">{lastBlock}</p>
                  )}
               </div>
            </div>
         </Col>
      </Row>
   );
};

export default ThirdRow;
