import React, { useState, useEffect } from "react";
import './MyWithdrawalsTable.scss';
import { Table } from "react-bootstrap";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ReactComponent as XdenIcon } from "../../../../Assets/SVGs/xden_icon.svg";

const MyWithdrawalsTable = ({withdrawalsList}) => {
   const [computedWithdrawals, setComputedWithdrawals] = useState([]);
   const [loading, setLoading] = useState(false);
   const navigate = useNavigate();

   const handleIndividualPool = (id) => {
      navigate("/pool/" + id);
   };

   const convertToStatus = (status) => {

      switch(status){
         case "processing":
            return {color: "font-size-18 font-pnl yellow-color", text:"Processing"}
         case "failed":
            return {color: "font-size-18 font-pnl red-color", text:"Failed"}
         case "success":
            return {color: "font-size-18 font-pnl green-color", text:"Succeded"}
      }
   }
   const convertToExplorer = (transactionID) => {

      return "https://explorer.xiden.com/tx/" + transactionID + "/internal-transactions"
   }
   useEffect(() => {
      

   }, []);

   let toRender;

   if (loading) {
      toRender = (
         <tr>
            <td colSpan="10" className="font-size-18 font-pnl">
               Loading...
            </td>
         </tr>
      );
   } else {
      toRender = (
         withdrawalsList.length > 0 ? (
            withdrawalsList.map(({ wID, totalSum, address, transactionType, status, transactionID, createdAt}) => (
               <tr>
                  <td className="font-size-18 font-pnl">{wID}</td>
                  <td className="font-size-18 font-pnl">
                     <span className="w-50 d-inline-block">
                        {totalSum}
                     </span>
                     <XdenIcon />
                  </td>

                  <td className="font-size-18 font-pnl">{address}</td>
                  {/* <td className={`font-size-18 font-pnl ${!minterID || !hasStake || !isMinterActive ? "red-color" : "green-color"}`}>{!minterID || !hasStake || !isMinterActive ? `Inactive` : `Active`}</td> */}
                  <td className="font-size-18 font-pnl">{transactionType}</td>
                  {/* <td className="font-size-18 font-pnl">{status}</td> */}
                  <td className="font-size-18 font-pnl"><a target="_blank" href={convertToExplorer(transactionID)}>Go to Transaction</a> </td> 

                  <td className={convertToStatus(status).color}>{convertToStatus(status).text}</td> 
                  {/* className={`font-size-18 font-pnl ${convertToStatus(status)}` */}

                  <td className="font-size-18 font-pnl">
                     <Moment format="DD/MM/YYYY">{createdAt}</Moment>
                  </td>
               </tr>
            ))
         ) : (
            <tr>
               <td colSpan="11" className="font-size-18 font-pnl">
                  No withdrawals found
               </td>
            </tr>
         )
      )
   }

   return (
      <Table className="my-pools-table" responsive hover>
         <thead>
            <tr>
               <th className="font-size-18 font-pnsb">Withdrawal ID</th>
               <th className="font-size-18 font-pnsb">Total Sum</th>
               <th className="font-size-18 font-pnsb">Address</th>
               <th className="font-size-18 font-pnsb">Trasanction Type</th>
               <th className="font-size-18 font-pnsb">Transaction Details</th>
               <th className="font-size-18 font-pnsb">Status</th>
               <th className="font-size-18 font-pnsb">Date created</th>
            </tr>
         </thead>
         <tbody>
            {toRender}
         </tbody>
      </Table>
   );
};

export default MyWithdrawalsTable;
