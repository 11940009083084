import React from "react";
import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ReactComponent as Info } from "../../../../../Assets/SVGs/info.svg";
import { ReactComponent as ActiveInfo } from "../../../../../Assets/SVGs/active-info.svg";
import { ReactComponent as XdenIcon } from "../../../../../Assets/SVGs/xden_icon.svg";
import ThemeLoader from "../../../../../Components/Loader/Loader";

const FourthRow = ({ details }) => {
   const { xdenPrice, xdenSupply, xdenMarketCap, loadingXdenPrice, loadingXdenSupply, loadingXdenMarketCap } = details;

   return (
      <Row lg={4} md={2} sm={2} xs={1} className="fourth-row">
         <Col className="mb-4">
            <div className="main-box height-252 d-flex flex-column">
               <div className="d-flex justify-content-between mb-3">
                  <p className="font-pnr white-color font-size-20">Total supply</p>
                  {/* info icon */}
                  <OverlayTrigger placement="top" overlay={<Tooltip>The maximum amount of XDEN that will ever be available</Tooltip>}>
                     <Info className="cursor-pointer" />
                  </OverlayTrigger>
               </div>
               <div className="d-flex inherit flex-column justify-content-center align-items-center">
                  {loadingXdenMarketCap ? (
                     <ThemeLoader />
                  ) : (
                     <>
                        <p className="font-pnsb d-flex align-items-center mb-2 font-size-40 white-color text-center">300000000 <XdenIcon /></p>
                        <p className="font-pnl mb-0 font-size-36 grey-text text-center">({(Number(300000000) * Number(xdenPrice)).toFixed(2)} USDT)</p>
                     </>
                  )}
               </div>
            </div>
         </Col>

         <Col className="mb-4">
            <div className="main-box height-252 d-flex flex-column">
               <div className="d-flex justify-content-between mb-3">
                  <p className="font-pnr white-color font-size-20">Circulating supply</p>
                  {/* info icon */}
                  <OverlayTrigger placement="top" overlay={<Tooltip>Current amount of XDEN circulating within the network and on the open markets</Tooltip>}>
                     <Info className="cursor-pointer" />
                  </OverlayTrigger>
               </div>
               <div className="d-flex inherit flex-column justify-content-center align-items-center">
                  {loadingXdenSupply ? (
                     <ThemeLoader />
                  ) : (
                     <>
                        <p className="font-pnsb d-flex align-items-center mb-2 font-size-40 white-color text-center">{(xdenSupply).toFixed(2)} <XdenIcon /></p>
                        <p className="font-pnl mb-0 font-size-36 grey-text text-center">({(Number(xdenSupply) * Number(xdenPrice)).toFixed(2)} USDT)</p>
                     </>
                  )}
               </div>
            </div>
         </Col>

         <Col xs={6} className="mb-4">
            <div className="main-box height-252 d-flex flex-column">
               <div className="d-flex justify-content-between mb-3">
                  <p className="font-pnr white-color font-size-20">Age</p>
                  {/* info icon */}
                  <OverlayTrigger placement="top" overlay={<Tooltip>The current operational phase of the Xiden blockchain</Tooltip>}>
                     <Info className="cursor-pointer" />
                  </OverlayTrigger>
               </div>
               <div className="d-flex inherit flex-column justify-content-center align-items-center">
                  <p className="font-pnsb mb-0 font-size-40 white-color text-center">Big Bang</p>
               </div>
            </div>
         </Col>

         <Col xs={6} className="mb-4">
            <div className="main-active-box height-252 d-flex flex-column">
               <div className="d-flex justify-content-between mb-3">
                  <p className="font-pnr white-color font-size-20">Price</p>
                  {/* info icon */}
                  <OverlayTrigger placement="top" overlay={<Tooltip>Current market price for the XDEN asset</Tooltip>}>
                     <ActiveInfo className="cursor-pointer" />
                  </OverlayTrigger>
               </div>
               <div className="d-flex inherit flex-column justify-content-center align-items-center">
                  {loadingXdenPrice ? (
                     <ThemeLoader />
                  ) : (
                     <>
                        <p className="font-pnsb mb-0 font-size-40 white-color text-center"> {parseFloat(xdenPrice).toFixed(2)} $</p>
                        <p className="font-pnl mb-0 font-size-36 grey-text text-center">{parseFloat(xdenPrice).toFixed(2)} USDT</p>
                     </>
                  )}
               </div>
            </div>
         </Col>
      </Row>
   );
};

export default FourthRow;
