import axios from "axios";
import React from "react";
import { toast } from "react-toastify";
import "../Security.scss";


const EmailCard = (props) => {

   let oldEmail = props.email.personalEmail;

   const handleFormSubmit = async (form) => {

      form.preventDefault()

      const newEmail = form.target.elements.new.value.trim()
      const confirmEmail = form.target.elements.confirm.value.trim()

      let error = false;

      // Validation case
      if(newEmail !== confirmEmail) {
         error = true;
         toast.warn("Email confirmation does not match your new email address.", {
            toastId: "erorr_notequal",
            hideProgressBar: true
         })
      }

      // Validation case
      if(newEmail === "") {
         error = true;
         toast.warn("You need to add your email address.", {
            toastId: "erorr_new_empty",
            hideProgressBar: true
         })
      }

      // Validation case
      if(newEmail !== "" && confirmEmail === "") {
         error = true;
         toast.warn("You need to confirm your email address.", {
            toastId: "erorr_confirm_empty",
            hideProgressBar: true
         })
      }

      // Make request if there are no errors
      if(!error) {
         await axios.post(`${process.env.REACT_APP_BACKEND_URL}/request-change-email`,
         {
            email: newEmail
         },
         {
            headers: {
               Authorization: localStorage.getItem("xiden-pool-session")
            }
         }).then((response) => {
            toast.success("Email address change request sent.", { toastId: "success_request" });
         }).catch(err => console.log(err))
      }

   }

   return (
      <div className="d-flex flex-column personal-data-card">
         <h5 className="font-size-20 font-pnb mb-5">Security - Change e-mail</h5>

         {/* actual email */}
         <div className="profile-input-container mb-3">
            <label className="font-size-18 font-pnr input-label">E-mail:</label>
            <input
               value={oldEmail}
               name="old"
               type="email"
               className="pool-input profile-input"
               disabled
            />
         </div>

         {/* new email */}
         <form onSubmit={handleFormSubmit} autoComplete="off">
            <div className="profile-input-container mb-3">
               <label className="font-size-18 font-pnr input-label">New e-mail:</label>
               <input
                  name="new"
                  type="email"
                  className="pool-input profile-input"
                  placeholder="Add new email"
               />
            </div>

            {/* confirm email */}
            <div className="profile-input-container mb-3">
               <label className="font-size-18 font-pnr input-label">Confirm e-mail:</label>
               <input
                  name="confirm"
                  type="email"
                  className="pool-input profile-input"
                  placeholder="Confirm email"
               />
            </div>

            {/* control buttons */}
            <div className="setup-buttons-container d-block text-end">
               <button className="setup-fill-btn font-size-20 font-pnm">Save</button>
            </div>

         </form>
      </div>
   );
};

export default EmailCard;
