let generateHoursInDay = [];  
let generateHoursForData = [];

for(let i = 0; i < 24; i++){
   generateHoursInDay.push(i === 0 ? "00" + ":00" : i + ":00");
   generateHoursForData.push(0)
}

/**
 * Get all hours in a day, e.g. 00:00
 * @type string
 * @returns string[]
 */
export const getHoursInADayForCategoryChart = () => {
    return generateHoursInDay;
}

/**
 * Get all hours in a day, e.g. 0
 * @type number
 * @returns number[]
 */
export const getHoursInADayForSeriesChart = () => {
    return generateHoursForData;
}

/**
 * 
 * @param {*} month 
 * @param {*} year 
 */
export const getDaysInAMonth = () => {

    const month = new Date().getMonth() + 1;
    const year = new Date().getFullYear();

    const days = Array.from(
        {
            length: new Date(year, month, 0).getDate()
        },
        (_, i) => new Date(year, month - 1, i + 1)
    );

    return days.map(day => day.getDate())

}