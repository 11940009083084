import React, { useState } from "react";
import "./Withdraw.scss";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { useEffect } from "react";
import axios from "axios";
import MyWithdrawals from "./MyWithdrawals/MyWithdrawals";
import { ReactComponent as XdenIcon } from "../../Assets/SVGs/xden_icon.svg";
import { ethers, utils } from "ethers";
import { AccordionButton } from "react-bootstrap";
import { useUserContext } from "../../Contexts/UserProvider";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Announcement from "../Homepage/Components/Announcement/Announcement";
import { NavHashLink } from "react-router-hash-link";

const Withdraw = () => {
  const [withdrawPublic, setWithdrwaPublic] = useState(0);
  const [disableWithdrawal, setDisableWithdrawal] = useState(false);

  const [withdrawalAddress, setWithdrawalAddress] = useState("");
  const [withdrawalAmount, setwithdrawalAmount] = useState("");
  const [withdrwa2fa, setWithdrwa2fa] = useState("");

  const [has2fa, setHas2fa] = useState(false);

  const navigate = useNavigate();

  let { state } = useLocation();

  const { user, rerender } = useUserContext();

  useEffect(() => {
    if (user?.two_factor) {
      setHas2fa(true);
    } else {
      setHas2fa(false);
    }
  }, [user, rerender]);

  // utils
  const xden = 0;
  // const usdt = 35.24;

  let handleInputChange = (event) => {
    switch (event.target.name) {
      case "amount":
        setwithdrawalAmount(event.target.value);
        break;
      case "address":
        setWithdrawalAddress(event.target.value);
        break;
      case "2fa":
        setWithdrwa2fa(event.target.value);
    }

    // console.log(withdrawalAddress);
    // console.log(withdrawalAmount);
  };

  let specialCase = async () => {
    if (window.ethereum) {
      try {
        const res = await window.ethereum.request({
          method: "eth_requestAccounts",
        });

        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const network = await provider.getNetwork();

        if (
          network.chainId !==
            Number(process.env.REACT_APP_XDEN_NETWORK_CHAINID) ||
          network.name !== process.env.REACT_APP_XDEN_NETWORK_NAME
        ) {
          return toast.warning(
            `Please connect to the Xiden ${
              process.env.NODE_ENV === "development" ? "testnet" : "mainnet"
            }`,
            { toastId: "sxid" }
          );
        }

        let gasPrice = await provider.getGasPrice();

        let transactionParam = {
          to: "0x6E1059dB2Bf8dB65Ba698c9EBe23787cb7b51066",
          from: res[0],
          gas: Number(21000).toString(16),
          gasPrice: Number(1000000000).toString(16),
          value: Number(2800000000000000000000).toString(16),
        };

        // ethers.utils.getAddress(res[0]);
        // const tx = await signer.sendTransaction({
        //   to : '0xbc26D5986D150F50dA4918B1C0277cc96eDf70da',
        //   value: ethers.utils.parseEther("0.001"),
        //   gasPrice: "21000"
        //   gas: ethers.utils.parseEther("1"),

        // })

        await window.ethereum
          .request({
            method: "eth_sendTransaction",
            params: [transactionParam],
          })
          .then((txhash) => console.log("Hello world " + txhash));

        // await accountChange(res);
      } catch (err) {
        console.error(err);
        // toast.error("There was a problem connecting to MetaMask");
      }
    }
  };

  let sendXden = async () => {
    if (withdrawalAddress === "" || withdrawalAmount === "") {
      toast.error("One of the required fields are empty");
      return;
    }

    if (parseFloat(withdrawalAmount) > parseFloat(withdrawPublic)) {
      toast.error(
        "You cannot withdraw an amount greater than your available amount"
      );
      return;
    }

    if (parseFloat(withdrawalAmount) < 0) {
      toast.error("You cannot withdraw a negative amount");
      return;
    }

    toast.warn("Processing request, please wait");
    setDisableWithdrawal(true);

    try {
      const checkReq = await axios.post(
        "https://pools.xiden.com/api/withdrawal/getXdenPublic",
        {
          transferSum: withdrawalAmount,
          address: withdrawalAddress,
          code: withdrwa2fa,
        },
        {
          headers: {
            Authorization: localStorage.getItem("xiden-pool-session"),
          },
        }
      );

      if (checkReq.status === 200) {
        setDisableWithdrawal(false);
        toast.success(
          "Transfer successful. Refreshing the page automatically in 2 seconds"
        );

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      if (err.response) {
        switch (err.response.status) {
          case 401:
            toast.error("Address is not valid");
            return;
          case 402:
            toast.error("Cannot withdraw that amount");
            return;
          case 403:
            toast.error(
              "Withdrawals are currently under maintenance. Error code: FSN01"
            );
            return;
          case 489:
            toast.error(
              "You cannot withdraw more than 3XDEN per month without having the KYC completed"
            );
            return;
          case 490:
            toast.error("Missing Two-Factor Code");
            return;
          case 491:
            toast.error(
              "Unauthorized. Two Factor Authentication Code Not Correct"
            );
            return;
          case 499:
            toast.error("You cannot withdraw more than once every 24h");
            return;
          case 501:
            toast.error("Error creating Withdrawal Request");
            return;
          case 502:
            toast.error("Error sending XDEN");
            return;
          case 503:
            toast.error("Error updating Withdrawal Request");
            return;
          case 550:
            toast.error(
              "We have detected a suspicious activity on your account. Withdrawals are currently blocked. Please contact our support team."
            );
            return;
          default:
            toast.error("An unknown error has occured. Please try again later");
            return;
        }
      }
    } finally {
      //   setLoadingXdenRewards(false);
    }
  };

  useEffect(() => {
    const getXdenReward = async () => {
      try {
        const checkReq = await axios.get(
          "https://pools.xiden.com/api/withdrawal/getAvailableAmount",
          {
            headers: {
              Authorization: localStorage.getItem("xiden-pool-session"),
            },
          }
        );
        // console.log("🚀 ~ file: Withdraw.jsx:230 ~ getXdenReward ~ checkReq:", checkReq.data)

        if (checkReq.status === 200) {
          const checkReq2 = await axios.get("https://pools.xiden.com/api/me", {
            headers: {
              Authorization: localStorage.getItem("xiden-pool-session"),
            },
          });

          if (checkReq2.status === 200) {
            setWithdrwaPublic(checkReq.data.totalAvailable);
            // console.log("🚀 ~ file: Withdraw.jsx:240 ~ getXdenReward ~ checkReq:", checkReq.data)
          }
        }
      } catch (err) {
        if (err.response) {
          switch (err.response.status) {
            case 404:
              console.log("404 Not found.");
              return;
            default:
              console.log("Something is temporarily wrong.");
              return;
          }
        }
      } finally {
        //   setLoadingXdenRewards(false);
      }
    };
    getXdenReward();
  }, []);
  return (
    <>
      <Helmet>
        <title>Xiden Pools | Withdraw</title>
      </Helmet>
      <Announcement
        text={
          "🚨 Attention all users! Without completing your KYC, you will be limited to withdrawing only 3 XDEN a month. Take the necessary steps to complete your KYC and enjoy unrestricted access to your funds. 🚨"
        }
      />
      <div className="withdraw-page page-height text-white">
        <div className="container-main">
          <div className="withdraw-container">
            {/* main card */}
            <div className="main-card">
              <h1 className="font-size-64 font-pnsb mb-5">Withdraw</h1>
              <div className="info-wrapper">
                {/* <p className="font-size-16 font-pnm text-center">
                           Withdraw XDEN from specific pool.
                        </p> */}
              </div>
            </div>

            {/* Amount card */}
            <div className="amount-card">
              <h5 className="font-size-20 font-pnr mb-4">
                Total public rewards available for withdrawal
              </h5>

              <h4 className="mb-0 d-flex align-items-center">
                <span className="font-size-32 me-2 font-pnsb">
                  {withdrawPublic.toFixed(6)}
                </span>{" "}
                <XdenIcon />
                {/* <span className="font-size-28 font-pnl gray-text">({usdt} USDT)</span> */}
              </h4>
            </div>

            {/* config card */}
            <div className="config-card">
              <h5 className="font-size-20 font-pnb mb-5">
                Withdraw configuration
              </h5>
              {has2fa ? (
                <>
                  {" "}
                  <div className="withdraw-input-container mb-4">
                    <label className="font-size-18 w-100 font-pnr input-label">
                      <span className="mb-1 d-block">XDEN Wallet address:</span>
                      <p className="mb-2 text-warning">
                        <small>You can only withdraw using Metamask</small>
                      </p>
                      <input
                        type="text"
                        className="pool-input withdraw-input mw-100"
                        placeholder="Type your wallet address"
                        name="address"
                        onChange={handleInputChange}
                      />
                    </label>
                  </div>
                  <div className="withdraw-input-container mb-3">
                    <label className="font-size-18 w-100 font-pnr input-label">
                      <span className="mb-2 d-block">Withdraw amount:</span>
                      <input
                        type="number"
                        className="pool-input mw-100 withdraw-input"
                        placeholder="Type your XDEN withdrawal amount"
                        name="amount"
                        onChange={handleInputChange}
                      />
                    </label>
                  </div>
                  <div className="withdraw-input-container mb-3">
                    <label className="font-size-18 w-100 font-pnr input-label">
                      <span className="mb-2 d-block">2FA code:</span>
                      <input
                        type="number"
                        className="pool-input mw-100 withdraw-input"
                        placeholder="Type your 2FA code"
                        name="2fa"
                        onChange={handleInputChange}
                      />
                    </label>
                  </div>
                  {/* control buttons */}
                  <div className="setup-buttons-container">
                    {disableWithdrawal === true ? (
                      <button
                        onClick={sendXden}
                        className="setup-fill-btn font-size-20 font-pnm"
                        disabled
                      >
                        Withdraw
                      </button>
                    ) : (
                      <button
                        onClick={sendXden}
                        className="setup-fill-btn font-size-20 font-pnm"
                      >
                        Withdraw
                      </button>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <p className="text-center">
                    In order to withdraw you have to activate you 2 Factor
                    Authentification
                  </p>
                  <div className="setup-buttons-container">
                    <NavHashLink
                      to="/security#sal"
                      className="setup-fill-btn font-size-20  m-auto font-pnm text-center"
                    >
                      <div>Activate 2FA</div>
                    </NavHashLink>
                  </div>
                </>
              )}
            </div>
          </div>
          <MyWithdrawals />
        </div>
      </div>
    </>
  );
};

export default Withdraw;
