import React from "react";
import Fade from "react-reveal";
import SetupStepCard from "../../../Components/SetupStepCard/SetupStepCard";
import { steps } from "../../../Utils/setupSteps";

const ActivatePoolStep3 = ({ handleActiveStep }) => {
   // data
   const stepCardData = {
      step: 3,
      title: "Select Wallet",
      info: "Wait until hashrate is displayed. Workers will be automatically added within a few seconds on the Workers page in your account.",
   };

   return (
      <Fade>
         <div className="w-100 d-flex flex-column">
            <SetupStepCard data={stepCardData} />

            <div className="setup-card m-auto d-flex flex-column">
               <h6 className="font-pnb font-size-20 mb-4">Activate Pool Configuration</h6>

               <div className="inner-wrapper">
                  <p className="font-size-16 font-pnm text-center">{stepCardData.info}</p>
               </div>

               <div className="setup-buttons-container">
                  <button
                     className="setup-fill-btn font-size-20 font-pnm"
                     onClick={() => handleActiveStep(steps.step_4)}
                  >
                     Finish
                  </button>
               </div>
            </div>
         </div>
      </Fade>
   );
};

export default ActivatePoolStep3;
