import React, { useState } from "react";
import "../Security.scss";
import QRCode from "react-qr-code";
import { toast } from "react-toastify";
import Disable2FAModal from "../../../Components/Disable2FAModal/Disable2FAModal";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../../Contexts/UserProvider";
import { useEffect } from "react";
import axios from "axios";

const TwoFACard = () => {
  // state
  const [active, setActive] = useState(() => true);
  const [codeValue, setCodeValue] = useState(() => "");
  const [showDisableModal, setShowDisableModal] = useState(() => false);

  const { user, rerender, setRerender } = useUserContext();

  useEffect(() => {
    if (user?.two_factor) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [user]);

  // console.log(user.two_factor)
  const navigate = useNavigate();
  // functions
  const handleCodeValue = (event) => setCodeValue(event.target.value);

  const handleActivateTwoFA = () => {
    navigate("/enable-2fa");
  };

  const toggleDisableModal = () => setShowDisableModal(!showDisableModal);

  const handleDisableTwoFA = async (codeValue) => {
    // setActive(false);

    try {
      let response = await axios.post(
        "https://pools.xiden.com/api/deactivateTwoFactor",
        {
          code: codeValue,
        },
        {
          headers: {
            Authorization: localStorage.getItem("xiden-pool-session"),
          },
        }
      );
      if (response.status === 200) {
        toast.success("2FA disabled successfully");
        setRerender(!rerender);

        setActive(false);
        setShowDisableModal(false);
      }
    } catch (err) {
      console.log(err);

      switch (err.response.status) {
        case 400:
          toast.error("Invalid code");
          break;
        case 401:
          toast.error("Unauthorized");
          break;
        default:
          toast.error("Something went wrong");
          break;
      }
    }
  };

  return (
    <>
      {/* modals */}
      <Disable2FAModal
        show={showDisableModal}
        toggle={toggleDisableModal}
        handleDisableTwoFA={handleDisableTwoFA}
      />

      {/* component */}

      <div className="col-md-6 ps-0 ps-md-0">
        <div className="two-fa-card position-relative mb-4 ">
          <h5 className="font-size-20 font-pnb ">
            Security - 2 Factor Authentication
          </h5>
          <p>
            2FA improves security on our website for a better user experience.
          </p>
          {/* status */}
          <div className="status-wrapper mb-4">
            <h6 className="font-size-18 font-pnr mb-0">Status:</h6>
            <div className="d-flex align-items-center">
              <span
                className={`font-pnb font-size-20  ${
                  active ? "active-status" : "disabled-status"
                }`}
              >
                {active ? "ACTIVE" : "DISABLED"}
              </span>
            </div>
          </div>

          {/* setup */}
          {active ? (
            <div className="fa-setup-container d-flex">
              <button
                className="fa-button font-size-12 font-pnm deactivate-bg m-auto"
                onClick={toggleDisableModal}
              >
                Disable
              </button>
            </div>
          ) : (
            <div className="fa-setup-container d-flex">
              <button
                className={`fa-button font-size-12 font-pnm activate-2fa m-auto`}
                onClick={handleActivateTwoFA}
              >
                Activate
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default TwoFACard;
