import React, { useEffect } from "react";
import { useMetamaskContext } from "../../../../Contexts/MetamaskProvider";
import ClipLoader from "react-spinners/ClipLoader";

import Header from "../Header/Header";
import IndividualPoolGrid from "./IndividualPoolGrid";

const IndividualPoolContent = ({
  poolData,
  params,
  loadingPool,
  fetchPool,
  forcerRender,
  setForcerRender,
}) => {
  const { hasStake, loadingStake, setLoadingStake } = useMetamaskContext();

  useEffect(() => {
    const fetchData = async () => {
      if (!loadingPool) {
        if (poolData?.address) {
          // when the pool data is loaded
          // if it has address saved, it means that he redeemd sxid
          // so we check if the user has already staked
          await hasStake(
            poolData?.address,
            params?.id,
            poolData?.hasStake,
            fetchPool
          );
        } else {
          setLoadingStake(false);
        }
      }
    };

    fetchData();
  }, [poolData?.address, loadingPool, params?.id]);

  if (loadingStake) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <ClipLoader loading={loadingStake} color="#1890ff" size={150} />
      </div>
    );
  }

  return (
    <>
      <Header poolData={poolData} />
      <IndividualPoolGrid
        setForcerRender={setForcerRender}
        forcerRender={forcerRender}
        params={params}
        poolData={poolData}
      />
    </>
  );
};

export default IndividualPoolContent;
