import React, { ReactText, useState } from 'react';
import '../Enable2FA.scss';
import SmsIcon from '../Assets/sms.png';
import Fade from 'react-reveal/Fade';
// import { IProps } from "./MainSetup";
import PhoneInput from 'react-phone-input-2';
import { ReactComponent as ArrowLeft } from '../../../Assets/Icons/arrow-left.svg';
import axios from 'axios';
import { toast } from 'react-toastify';
import BeatLoader from 'react-spinners/BeatLoader';
import { Buffer } from 'buffer';

// interfaces

const SmsSetup = ({ handleViewChange, handleEncodedRequest }) => {
  // state
  const [phoneNumber, setPhoneNumber] = useState(() => '');

  const [loading, setLoading] = useState(() => false);

  // functions
  const handlePhoneNumber = (value) => setPhoneNumber(value);

  const verifyPhoneNumber = async (
    event
  ) => {
    event.preventDefault();

    if (!phoneNumber) {
      toast.error('You must type a phone number', { toastId: 'sms-setup-phone-empty' });
      return;
    }

    try {
      setLoading(true);

      const obj = {
        setting: 'SMS',
        phone: `+${phoneNumber}`
      };

      const sms2FA = await axios.post(
        process.env.REACT_APP_EDAIN_URL + '/api/auth/twofactor',
        obj,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwt')}`
          }
        }
      );

      if (sms2FA.status === 200) {
        const request = {
          obj: {
            setting: obj.setting
          },
          route: '/api/auth/twofactor/confirm',
          status: 'pending',
          successMsg: 'Two-factor authentication has been enabled'
        };

        const encodedRequest = Buffer.from(JSON.stringify(request)).toString('hex');

        handleEncodedRequest(encodedRequest);
        handleViewChange('step3');
      }
    } catch (err) {
      console.log(err);
      toast.error('An error occured', { toastId: 'sms-setup-error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fade>
      <div className="main-card-2fa">
        <div className="d-flex flex-column">
          <h1 className="font-size-32 font-pnb text-center mb-5">Setup 2FA with SMS</h1>

          <div className="set-container">
            <div className="p-2 d-flex flex-column align-items-center justify-content-center setup-card sms-card single-setup-card">
              <img src={SmsIcon} alt="sms" className="tfa-img" />
              <p className="mt-3 mb-2 font-size-32 font-pnb text-center card-heading">Use SMS</p>
              <p className="font-size-20 font-pnr text-center tfa-info">
                Security codes will be sent <br /> to your mobile phones.
              </p>
            </div>

            <div className="d-flex flex-column">
              <p className="mb-1 font-pnb font-size-32 centered-heading">
                Two Factor Authentification
              </p>
              <div className="mb-4 d-flex flex-column font-size-20 font-pnr">
                <span>
                  A verification code will be sent to the phone number below. This code is valid
                  only for 10 minutes.
                </span>
              </div>

              <form onSubmit={verifyPhoneNumber}>
                <div className="d-flex code-container">
                  <PhoneInput
                    country={'us'}
                    inputClass={`inputClass`}
                    containerClass={`containerClass`}
                    buttonClass={`buttonClass`}
                    dropdownClass={`dropdownClass`}
                    value={phoneNumber}
                    onChange={handlePhoneNumber}
                  />
                </div>

                <button
                  className="zixxar-outline-white-btn w-100 font-size-18 font-pnr btn-height-48 radius-10 mt-3"
                  onClick={verifyPhoneNumber}
                >
                  {loading ? <BeatLoader size={15} color="#ffffff" /> : 'Send SMS'}
                </button>
              </form>
            </div>
          </div>

          {/* back button */}
          <button
            className="zixxar-blue-button btn-height-56 font-size-20 font-pnm radius-12 control-2fa-btn m-auto mt-5"
            disabled={loading}
            onClick={() => handleViewChange('step1')}
          >
            <ArrowLeft className="me-2" />
            Go back
          </button>
        </div>
      </div>
    </Fade>
  );
};

export default SmsSetup;
