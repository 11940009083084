import React from 'react';
import { Navigate } from 'react-router-dom';
import { routes } from '../../Utils/routes';

const PrivateRoute = ({ children }) => {
    const isLogin = localStorage.getItem("xiden-pool-session");

    return isLogin ? children : <Navigate to={routes.signIn} />;
};

export default PrivateRoute;
