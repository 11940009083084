import React from "react";
import "./SetupStepCard.scss";
import { ReactComponent as BasmIcon } from "../../Assets/SVGs/basm-icon.svg";
import { ReactComponent as HidrIcon } from "../../Assets/SVGs/hidr-icon.svg";
import { ReactComponent as ImpulseIcon } from "../../Assets/SVGs/impulse-icon.svg";
import MinterGuardianIcon from "../../Assets/PNGs/minter-guardian-icon.png";

const SetupStepCard = ({ data }) => {

   const handleSelectMiner = (type) => {
      data.setMinerType(type);
   }

   return (
      <div className="setup-card step-card d-flex flex-column justify-content-center align-items-center mx-auto space-bottom">
         <div className="d-flex flex-column justify-content-center align-items-center py-3">
           
            <div className="step-tag font-size-20 font-pnm">Step {data.step}</div>
            
            <h1 className="font-size-56 font-pnsb my-4 text-center">{data.title}</h1>
            <div className="inner-wrapper">
               <p className="font-size-16 font-pnm text-center">{data.info}</p>
            </div>
            {data.checkboxes && (
               <div className="mt-3 d-flex flex-column gap-3 w-100 justify-content-between">
                  <div
                     onClick={() => handleSelectMiner('miner')}
                     className={`main-box w-100 cursor-pointer d-flex justify-content-between align-items-center ${data.minerType !== "miner" ? "inactive-type" : "active-type"}`}
                     style={{ width: "47%" }}
                  >
                     <div className="devices-container d-flex flex-column align-items-center">
                        <div className="device-icon">
                           <BasmIcon />
                        </div>
                        <p className="mb-0 font-pnr device-label">Basm</p>
                     </div>
                     <p className="mb-0 text-center font-pnl">or</p>
                     <div className="devices-container d-flex flex-column align-items-center">
                        <div className="device-icon">
                           <ImpulseIcon />
                        </div>
                        <p className="mb-0 font-pnr device-label">Impulse</p>
                     </div>
                     <p className="mb-0 text-center font-pnl">or</p>
                     <div className="devices-container d-flex flex-column align-items-center">
                        <div className="device-icon">
                           <HidrIcon />
                        </div>
                        <p className="mb-0 font-pnr device-label">HidR</p>
                     </div>
                  </div>
                  <div
                     onClick={() => handleSelectMiner('minter')}
                     className={`main-box w-100 cursor-pointer d-flex justify-content-center align-items-center ${data.minerType !== "minter" ? "inactive-type" : "active-type"}`}
                     style={{ width: "47%" }}
                  >
                     <div className="d-flex gap-2 devices-container flex-column align-items-center">
                        <div className="device-icon">
                           <img src={MinterGuardianIcon} alt="minter" />
                        </div>
                        <p className="mb-0 font-pnr device-label">Minter</p>
                     </div>
                  </div>
               </div>
            )}
         </div>
      </div>
   );
};

export default SetupStepCard;
