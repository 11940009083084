import React from "react";
import { Modal } from "react-bootstrap";

const Disable2FAModal = ({ show, toggle, handleDisableTwoFA }) => {

   const [step, setStep] = React.useState(0);
   const [code , setCode] = React.useState("");

   // functions
   const handleconfirmation = () => {
      
      handleDisableTwoFA(code);
      // setStep(1);
   };

   return (
      <Modal show={show} onHide={toggle} centered size="lg" className="text-white">
         <Modal.Body>
            <h2 className="font-pnsb font-size-32 mb-5 text-center">
               Are you sure you want to disable 2 Factor Authentication?
            </h2>
      {step === 0 && (   <div className="w-100 d-flex justify-content-center">
               <button className="font-size-24 font-pnm modal-button-outline me-4" onClick={toggle}>
                  No
               </button>

               <button className="font-size-24 font-pnm modal-button-blue" onClick={()=>setStep(1)}>
                  Yes
               </button>
            </div>)}
            {step === 1 && (   <div className="w-100 d-flex flex-column justify-content-center">
           
               <input className="pool-input profile-input m-auto mb-3" type="text" placeholder="Enter 2FA code here"  value={code} onChange={(e)=>setCode(e.target.value)}/>

               <button className="font-size-24 font-pnm modal-button-blue m-auto" onClick={handleconfirmation}>
                  Confirm
               </button>
            </div>)}
         
         </Modal.Body>
      </Modal>
   );
};

export default Disable2FAModal;
