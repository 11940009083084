import React, { useState } from "react";
import "./SignUp.scss";
import Fade from "react-reveal/Fade";
import { AiOutlineEye as EyeOpenIcon, AiOutlineEyeInvisible as EyeCloseIcon } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../Utils/routes";
import { Helmet } from "react-helmet";
import axios from "axios";
import qs from "qs";
import { toast } from 'react-toastify';
import { emailPattern, pswPattern } from "../../../Utils/regexPatterns";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ReactComponent as Info } from "../../../Assets/SVGs/info.svg";

// state values
const stateValues = {
   // name: "",
   email: "",
   password: "",
   repeatPassword: "",
};

const SignUp = () => {
   // state
   const [values, setValues] = useState(() => stateValues);
   const [hidePassword, setHidePassword] = useState(() => true);
   const [hideRepeatPassword, setHideRepeatPassword] = useState(() => true);

   const navigate = useNavigate();

   // functions
   const togglePasswordView = () => setHidePassword(!hidePassword);
   const toggleRepeatPasswordView = () => setHideRepeatPassword(!hideRepeatPassword);

   const handleValues = event => {
      const target = event.target;
      const { name, value } = target;
      setValues({ ...values, [name]: value });
   };

   const pswTooltip = (
      <Tooltip>
         <p className="mb-0">Password should contain:</p>
         <ul className="text-start psw-list">
            <li>Minimum 8 characters</li>
            <li>At least one number</li>
            <li>One lowercase letter</li>
            <li>One special character</li>
            <li>One uppercase letter</li>
         </ul>
      </Tooltip>
   );

   const handleRegister = async (event) => {
      event.preventDefault();

      try {
         const toSend = {
            email: values.email,
            password: values.password
         };

         // form validation
         if (!values.email || !values.password || !values.repeatPassword) {
            toast.error("Please fill in all fields!");
            return;
         }

         if (!emailPattern.test(values.email)) {
            toast.error("Please enter a valid email!");
            return;
         }

         if (!pswPattern.test(values.password)) {
            toast.error("Please enter a valid password!");
            return;
         }

         if (values.password !== values.repeatPassword) {
            toast.error("Passwords do not match!");
            return;
         }

         const response = await axios.post(
            process.env.REACT_APP_BACKEND_URL + "/register",
            qs.stringify(toSend),
         );

         if (response.status === 200) {
            toast.success("Account created successfully", { toastId: "signup-200" });
            navigate(routes.signIn);
         }
      } catch (err) {
         console.log(err);
         if (err.response) {
            switch (err.response.status) {
               case 400:
                  toast.error(err.response.data.error.charAt(0).toUpperCase() + err.response.data.error.slice(1), { toastId: "signup-400" });
                  return;
               default:
                  toast.error("Oops.. There was an error!", { toastId: "signup-default" });
                  return;
            }
         }
      }
   };

   return (
      <>
         <Helmet>
            <title>Xiden Pools | Sign up</title>
         </Helmet>

         <div className="container-main">
            <Fade>
               <div className="sign-up-page">
                  <form className="main-box d-flex flex-column py-5" onSubmit={handleRegister}>
                     <h3 className="text-center font-pnb white-color font-size-24 mb-2">Sign Up</h3>

                     {/* <label htmlFor="name" className="font-pnr font-size-18 white-color mb-1">
                        Name
                     </label>
                     <input
                        value={values.name}
                        autoComplete="off"
                        name="name"
                        className="pool-input mb-2"
                        type="text"
                        placeholder="Enter name"
                        onChange={handleValues}
                     /> */}

                     <label htmlFor="email" className="font-pnr font-size-18 white-color mb-1">
                        E-mail
                     </label>
                     <input
                        value={values.email}
                        autoComplete="off"
                        name="email"
                        className="pool-input mb-2"
                        type="email"
                        placeholder="Enter e-mail"
                        onChange={handleValues}
                     />

                     <div className="d-flex gap-2 align-items-center mb-1">
                        <label htmlFor="password" className="font-pnr font-size-18 white-color">
                           Password
                        </label>
                        <OverlayTrigger placement="top" overlay={pswTooltip}>
                           <Info className="cursor-pointer psw-icon" />
                        </OverlayTrigger>
                     </div>
                     <div className="w-100 position-relative mb-2">
                        <input
                           value={values.password}
                           autoComplete="off"
                           name="password"
                           className="pool-input w-100"
                           type={hidePassword ? "password" : "text"}
                           placeholder="Enter password"
                           onChange={handleValues}
                        />
                        {hidePassword ? (
                           <EyeOpenIcon className="hide-pwd-icon" onClick={() => togglePasswordView()} />
                        ) : (
                           <EyeCloseIcon className="hide-pwd-icon" onClick={() => togglePasswordView()} />
                        )}
                     </div>

                     <label htmlFor="repeatPassword" className="font-pnr font-size-18 white-color mb-1">
                        Repeat Password
                     </label>
                     <div className="w-100 position-relative mb-4">
                        <input
                           value={values.repeatPassword}
                           autoComplete="off"
                           name="repeatPassword"
                           className="pool-input w-100"
                           type={hideRepeatPassword ? "password" : "text"}
                           placeholder="Repeat password"
                           onChange={handleValues}
                        />
                        {hideRepeatPassword ? (
                           <EyeOpenIcon className="hide-pwd-icon" onClick={() => toggleRepeatPasswordView()} />
                        ) : (
                           <EyeCloseIcon className="hide-pwd-icon" onClick={() => toggleRepeatPasswordView()} />
                        )}
                     </div>

                     <div className="d-flex justify-content-center align-items-center">
                        <button className="buttonOutlineBlue font-size-18 m-0" onClick={handleRegister}>
                           Sign Up
                        </button>
                     </div>
                  </form>
               </div>
            </Fade>
         </div>
      </>
   );
};

export default SignUp;
