import React, { useState, useEffect } from "react";
import { ReactComponent as ChevronDown } from "../../../../Assets/SVGs/chevron-down.svg";
import Fade from "react-reveal/Fade";
import { Container } from "react-bootstrap";
import axios from "axios";

import FirstRow from "./StakingGrid/FirstRow";
// import SecondRow from "./StakingGrid/SecondRow";

const Staking = () => {
   const [showContent, setShowContent] = useState(true);

   // stats
   const [xdenStake, setXdenStake] = useState(0);
   const [rewards, setRewards] = useState({
      allRewards: 0,
      latestRewards: 0,
      privateRewardTotal: 0,
      privateRewardYesterday: 0,
      publicRewardTotal: 0,
      publicRewardYesterday: 0,
   });

   // stats loaders
   const [loadingXdenStake, setLoadingXdenStake] = useState(true);
   const [loadingXdenRewards, setLoadingXdenRewards] = useState(true);

   const toggleContent = () => {
      setShowContent(prevContent => !prevContent);
   };

   const getXdenStake = async () => {
      try {
         const checkReq = await axios.get("https://pools.xiden.com/api/rewards/getStakedAmound", {
            headers: {
               Authorization: localStorage.getItem("xiden-pool-session")
            }
         })
         if (checkReq.status === 200) {
            setXdenStake(checkReq.data.staked ? Number(checkReq.data.staked) * 1000 : 0);
         }
      } catch (err) {
         if (err.response) {
            switch (err.response.status) {
               case 404:
                  console.log("404 Not found.");
                  return;
               default:
                  console.log("Something is temporarily wrong.");
                  return;
            }
         }
      } finally {
         setLoadingXdenStake(false);
      }
   }

   const getXdenReward = async () => {
      try {
         const checkReq = await axios.get("https://pools.xiden.com/api/rewards/getAllRewards", {
            headers: {
               Authorization: localStorage.getItem("xiden-pool-session")
            }
         })
         if (checkReq.status === 200) {
            setRewards(checkReq.data);
         }
      } catch (err) {
         if (err.response) {
            switch (err.response.status) {
               case 404:
                  console.log("404 Not found.");
                  return;
               default:
                  console.log("Something is temporarily wrong.");
                  return;
            }
         }
      } finally {
         setLoadingXdenRewards(false);
      }
   }

   useEffect(() => {
      // Create an scoped async function in the hook
      const fetchData = async () => {
         await getXdenStake();
         await getXdenReward();
      };

      fetchData();
   }, []);

   const firstRow = {
      xdenStake: xdenStake,
      privateRewardTotal: rewards.privateRewardTotal || 0,
      publicRewardTotal: rewards.publicRewardTotal || 0,
      publicRewardYesterday: rewards.publicRewardYesterday || 0,
      loadingXdenStake: loadingXdenStake,
      loadingXdenRewards: loadingXdenRewards
   };

   return (
      <div className="staking">
         <Container fluid>
            <div onClick={toggleContent} className="d-flex gap-2 align-items-center mb-3 cursor-pointer">
               <p className="font-pnb font-size-32 white-color mb-0">Staking</p>
               <ChevronDown className={`chevron-icon ${showContent ? "show" : "hide"} `} />
            </div>
            {showContent && (
               <Fade>
                  <FirstRow details={firstRow} />
                  {/* <SecondRow /> */}
               </Fade>
            )}
         </Container>
      </div>
   );
};

export default Staking;
