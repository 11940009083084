import React, { useState } from 'react';
// import './GuideModal.scss';
import { Modal, Button } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';


const GuideModal = ({ show, onHide, chosenLevel }) => {
  const [step, setStep] = useState(0);

  const handleNextStep = ()=> {
    if (chosenLevel === 1 && step === 1) {
      onHide();
    } else if (chosenLevel === 2 && step === 3) {
      onHide();
    } else if (chosenLevel === 3 && step === 4) {
      onHide();
    } else {
      setStep((prevStep) => prevStep + 1);
    }
  };

  let header;
  let body;
  let buttonText = 'Next';
  let showCurrentStep;

  if (chosenLevel === 1 && step === 1) {
    buttonText = 'Start verification';
  } else if (chosenLevel === 2 && step === 3) {
    buttonText = 'Start verification';
  } else if (chosenLevel === 3 && step === 4) {
    buttonText = 'Start verification';
  }

  if (chosenLevel === 1) {
    showCurrentStep = <h6 className="font-pnb font-size-20">{step + 1}/2</h6>;
  } else if (chosenLevel === 2) {
    showCurrentStep = <h6 className="font-pnb font-size-20">{step + 1}/4</h6>;
  }
  if (chosenLevel === 3) {
    showCurrentStep = <h6 className="font-pnb font-size-20">{step + 1}/5</h6>;
  }

  if (step === 0) {
    header = <h6 className="mb-0 font-pnb font-size-24">{'Identity Verification'}</h6>;
    body = (
      <React.Fragment>
        <p className="mb-2 font-pnsb font-size-20">
          {
            'To verify your identity, please upload a clear picture of your official Government-issued ID document.'
          }
        </p>
        <ul className="ul-verification">
          <li>
            <p className="mb-0 font-pnr font-size-18">
              {'Passport or National Identification Card are both accepted.'}
            </p>
          </li>
          <li>
            <p className="mb-0 font-pnr font-size-18">
              {
                'If you are using an ID card, you are required to take two photos (front & back of your ID card).'
              }
            </p>
          </li>
          <li>
            <p className="mb-0 font-pnr font-size-18">
              {'If you are using a passport, only the front side is required.'}
            </p>
          </li>
        </ul>
        <p className="font-size-16 font pnr">
          <span className="info-paragraph me-1">{'Note'}:</span>
          {
            "Please check that the personal information you sent us previously is consistent with the information on your identity card or passport, as you won't be able to change it once it's confirmed."
          }
        </p>
      </React.Fragment>
    );
  } 
   else if (step === 1) {
    header = <h6 className="mb-0 font-pnb font-size-24">{'Proof of Address'}</h6>;
    body = (
      <React.Fragment>
        <p className="mb-2 font-pnsb font-size-20">
          {
            'We need to verify your address before approval. Upload an utility bill as proof of address. Please make sure all the details are clearly visible.'
          }
        </p>
      </React.Fragment>
    );
  } 

 else {
    header = <p></p>;
    body = <p></p>;
  }

  return (
    <Modal
      size="lg"
      centered
      show={show}
      onHide={onHide}
      id="guide-modal"
      className="text-white"
      backdrop="static"
    >
      <Modal.Header className="d-flex flex-column justify-content-center align-items-center text-center">
        <Fade spy={step}>
          {header}
          {showCurrentStep}
        </Fade>
      </Modal.Header>
      <Modal.Body className="m-auto">
        <Fade spy={step}>
          {body}
          <div className="d-flex justify-content-center">
            <Button
              variant="primary"
              onClick={handleNextStep}
              className="primary-btn-kyc font-size-16 font-pnr d-flex justify-content-center align-items-center mt-3"
            >
              {buttonText}
            </Button>
          </div>
        </Fade>
      </Modal.Body>
    </Modal>
  );
};

export default GuideModal;
