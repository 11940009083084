import React, { useEffect, useState } from "react";
import "../Economics.scss";
import { ReactComponent as Info } from "../../../../../Assets/SVGs/info.svg";
import { ReactComponent as ActiveInfo } from "../../../../../Assets/SVGs/active-info.svg";
import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import ChartOverlay from "../../../../../Components/ChartOverlay/ChartOverlay";
import Chart from "react-apexcharts";
import { getDaysInAMonth } from "../../../../../Utils/chartTimeStamps";
import ThemeChart from "../../../../../Components/Chart/Chart";
import { ReactComponent as XdenIcon } from "../../../../../Assets/SVGs/xden_icon.svg";
import ThemeLoader from "../../../../../Components/Loader/Loader";

const SecondRow = ({ details }) => {

  const {
    xdenPrice,
    latestRewards,
    privateRewardYesterday,
    publicRewardYesterday,
    rewardsChart,
    loadingXdenPrice,
    loadingXdenRewards,
  } = details;

  return (
    <Row
      className="second-row economics-second-row"
    >
      <Col lg={2} xs={6} className="mb-4">
        <div className="main-box height-252 d-flex flex-column">
          <div className="d-flex justify-content-between mb-3">
            <p className="font-pnr white-color font-size-20">24h Revenue</p>
            {/* info icon */}
            <OverlayTrigger placement="top" overlay={<Tooltip>Daily revenue for the active pool</Tooltip>}>
              <Info className="cursor-pointer" />
            </OverlayTrigger>
          </div>
          <div className="d-flex inherit flex-column justify-content-center align-items-center">
            {loadingXdenPrice || loadingXdenRewards ? (
              <ThemeLoader />
            ) : (
              <>
                <p className="font-pnsb d-flex align-items-center mb-0 font-size-40 white-color text-center">
                  {latestRewards?.toFixed(5)} <XdenIcon />
                </p>
                <p className="font-pnl mb-0 font-size-28 grey-text text-center">
                  {(latestRewards * xdenPrice)?.toFixed(2)}$
                </p>
              </>
            )}
          </div>
        </div>
      </Col>

      <Col
        xs={6}
        lg={2}
        className="mb-4 d-flex flex-column justify-content-between gap-4"
      >
        <div className="main-box height-114 d-flex justify-content-between flex-column align-items-start">
          <div className="d-flex w-100 justify-content-between">
            <p className="font-pnr white-color font-size-20 mb-0">
              24h Private revenue
            </p>
            {/* info icon */}
            <OverlayTrigger placement="top" overlay={<Tooltip>Daily revenue in the Private Difficulty</Tooltip>}>
              <Info className="cursor-pointer info-icons" />
            </OverlayTrigger>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center">
            {loadingXdenRewards ? (
              <ThemeLoader />
            ) : (
              <p className="font-pnsb d-flex align-items-center mb-0 font-size-32 white-color text-center">
                {privateRewardYesterday?.toFixed(5)} <XdenIcon />
              </p>
            )}
          </div>
        </div>
        <div className="main-box height-114 d-flex justify-content-between flex-column align-items-start">
          <div className="d-flex w-100 justify-content-between">
            <p className="font-pnr white-color font-size-20 mb-0">
              24h Public revenue
            </p>
            {/* info icon */}
            <OverlayTrigger placement="top" overlay={<Tooltip>Daily revenue in the Public Difficulty</Tooltip>}>
              <Info className="cursor-pointer info-icons" />
            </OverlayTrigger>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center">
            {loadingXdenRewards ? (
              <ThemeLoader />
            ) : (
              <p className="font-pnsb d-flex align-items-center mb-0 font-size-32 white-color text-center">
                {publicRewardYesterday?.toFixed(5)} <XdenIcon />
              </p>
            )}
          </div>
        </div>
      </Col>

      <Col lg={6} className="mb-4">
        <div
          className={`${
            rewardsChart.length
              ? ""
              : "d-flex align-items-center justify-content-center"
          } main-box height-252 position-relative d-flex align-items-end`}
        >

          <ThemeChart
            uri={`${process.env.REACT_APP_BACKEND_URL}/mining-stats/reward/`}
            measurement="reward"
            singleData={true}
            excludeDates={["24hours"]}
            defaultDate="7days"
            single={true}
            label="Revenue"
            chartTitle="Revenue" />

        </div>
      </Col>

      <Col lg={2} md={12} sm={12} className="mb-4">
        <div className="main-active-box height-252 d-flex flex-column">
          <div className="d-flex justify-content-between mb-3">
            <p className="font-pnr white-color font-size-20">Current price</p>
            {/* info icon */}
            <OverlayTrigger placement="top" overlay={<Tooltip>Last reported XDEN price</Tooltip>}>
              <ActiveInfo className="cursor-pointer" />
            </OverlayTrigger>
          </div>
          <div className="d-flex inherit flex-column justify-content-center text-center">
            {loadingXdenPrice ? (
              <ThemeLoader />
            ) : (
              <p className="font-pnsb mb-0 font-size-40 white-color">
                ${parseFloat(xdenPrice)?.toFixed(2)}
              </p>
            )}
            <p className="font-pnr mb-0 font-size-12 grey-text">
              Updated on{" "}
              {new Date(Date.now()).toLocaleDateString() +
                " / " +
                new Date(Date.now()).toLocaleTimeString()}
            </p>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default SecondRow;
