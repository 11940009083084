import loader from "../../Assets/SVGs/theme_loader.svg";

const ThemeLoader = () => {
    return(
        <div className="loader">
            <img src={loader} alt="" />
        </div>
    )
}

export default ThemeLoader;