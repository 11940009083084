import React from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const LogoutModal = ({ show, toggle }) => {
   const navigate = useNavigate();

   // functions
   const handleLogout = () => {
      localStorage.removeItem("xiden-pool-session");
      navigate("/");
      toast.success("You have been successfully logged out.");
      toggle();
   };

   return (
      <Modal show={show} onHide={toggle} centered size="lg" className="text-white">
         <Modal.Body>
            <h2 className="font-pnsb font-size-32 mb-5 text-center">Are you sure you want to log out?</h2>

            <div className="w-100 d-flex justify-content-center">
               <button className="font-size-24 font-pnm modal-button-outline me-4" onClick={toggle}>
                  No
               </button>

               <button className="font-size-24 font-pnm modal-button-blue" onClick={handleLogout}>
                  Yes
               </button>
            </div>
         </Modal.Body>
      </Modal>
   );
};

export default LogoutModal;
