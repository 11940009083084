import React from "react";
import { Outlet } from "react-router-dom";

import OnboardingNavigation from "../../Components/OnboardingNavigation/OnboardingNavigation";

const OnboardingLayout = () => {
    return (
        // layout for new and unlogged users
        <div className="position-relative layout h-100">
            <OnboardingNavigation />

            <div>
                {/* main content */}
                <Outlet />
            </div>
        </div>
    );
};

export default OnboardingLayout;
