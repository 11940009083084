import React from 'react';
import '../Enable2FA.scss';
import { ReactComponent as ArrowLeft } from '../../../Assets/Icons/arrow-left.svg';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Buffer } from 'buffer';

// interfaces

const Confirmation2FA = ({
  handleViewChange,
  handleSmsConfirmed,
  encodedRequest
}) => {
  const [secureCode, setSecureCode] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const handleSecureCodeChange = (e) => {
    setSecureCode(e.target.value);
  };

  const handleVerify = async () => {
    const requestDecoded = Buffer.from(encodedRequest, 'hex').toString();
    const requestParsed = JSON.parse(requestDecoded);

    try {
      setLoading(true);

      const body = {
        ...requestParsed.obj
      };

      body.code = secureCode;

      let verifyCode = await axios.post(
        process.env.REACT_APP_EDAIN_URL + requestParsed.route,
        body,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwt')}`,
            'x-auth-two-factor': secureCode
          }
        }
      );

      if (verifyCode.status === 200 || verifyCode.status === 201) {
        handleSmsConfirmed();
        toast.success(requestParsed.successMsg);
      }
    } catch (err) {
      if (
        err.response &&
        err.response.data &&
        err.response.data.errors &&
        err.response.data.errors.message
      ) {
        switch (err.response.status) {
          case 400:
            toast.error(err.response.data.errors.message, {
              toastId: 'confirm-secure-code-error-400'
            });
            return;

          case 401:
            toast.error(err.response.data.errors.message, {
              toastId: 'confirm-secure-code-error-401'
            });
            return;

          case 402:
            toast.error(err.response.data.errors.message, {
              toastId: 'confirm-secure-code-error-402'
            });
            return;

          case 403:
            toast.error(err.response.data.errors.message, {
              toastId: 'confirm-secure-code-error-403'
            });
            return;

          case 404:
            toast.error('User not found', { toastId: 'confirm-secure-code-error-404' });
            return;

          case 411:
            toast.error('Platform is under maintanance, please try again later.', {
              toastId: 'confirm-secure-code-error-411'
            });
            return;

          default:
            toast.error('Oops.. There was an error!', { toastId: 'confirm-secure-code-error' });
            return;
        }
      } else {
        toast.error('Oops.. There was an error!', { toastId: 'confirm-secure-code-error-2' });
        return;
      }
    } finally {
      setLoading(false);
    }
  };

  // functions
  const handleconfirm = async (event) => {
    event.preventDefault();

    await handleVerify();
  };

  return (
    <form className="mt-5 confirm-form" onSubmit={handleconfirm}>
      <h1 className="font-size-32 font-pnb text-center mb-5">
        Enter two-factor authentication code
      </h1>

      <input
        value={secureCode}
        type="text"
        className="primary-input"
        placeholder="Type security code..."
        onChange={(event) => handleSecureCodeChange(event)}
      />

      <button
        className="zixxar-blue-button w-100 font-size-18 font-pnr btn-height-48 radius-10 mt-3 process-btn-code"
        onClick={handleconfirm}
        disabled={loading}
      >
        Process
      </button>

      {/* back button */}
      <div className="w-100 d-flex justify-content-center">
        <button
          className="zixxar-blue-button btn-height-56 font-size-20 font-pnm radius-12 control-2fa-btn m-auto mt-5"
          onClick={() => handleViewChange('step2')}
        >
          <ArrowLeft className="me-2" />
          Go back
        </button>
      </div>
    </form>
  );
};

export default Confirmation2FA;
