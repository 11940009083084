import { Line } from "react-chartjs-2";
import {
   Chart as ChartJS,
   CategoryScale,
   LinearScale,
   PointElement,
   LineElement,
   TimeScale,
   Title,
   Tooltip as ChartJSTooltip,
   Legend,
 } from 'chart.js';
import React, { useEffect, useState } from "react";
import moment from "moment";
import ToggleBar from "../ToggleBar/ToggleBar";
import axios from "axios";
import { extendMoment } from "moment-range";
import "./Chart.scss";

/**
 * Chart
 * @param {*} chartTitle
 * @param {*} endpoint
 * @param {*} time
 * @returns JSX.Element
 */
const ThemeChart = (props) => {

    const {chartTitle, uri, measurement, single, defaultDate, excludeDates, singleData} = props;

    let format, tickLimit;

    const [chartData, setChartData] = useState([]);
    const [toggleButton, setToggleButton] = useState(defaultDate);
    const [empty, setEmpty] = useState(false);

    // Register modules
    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        TimeScale,
        Title,
        ChartJSTooltip,
        Legend
    )

    // Force graph to render when some props are available
    setTimeout(() => {
        if(toggleButton === "" || toggleButton === undefined) {
            setToggleButton("24hours")
        }
    }, 1000)

    if(toggleButton === "24hours") {
        format = "DD MMM HH:mm"
        tickLimit = 12
    }
    if(toggleButton === "7days") {
        format = "DD MMM"
        tickLimit = 12
    }
    if(toggleButton === "30days") {
        format = "DD MMM"
        tickLimit = 12
    }
    if(toggleButton === "12months") {
        format = "DD MMM YYYY"
        tickLimit = 13
    }

    const calculateLabels = () => {

        const extend = extendMoment(moment);
        const start = new Date()

        let end, range, array, month;

        if(toggleButton === "24hours") {
            end = moment().subtract(1, "day");
            range = extend.range(moment(end), moment(start))
            array = Array.from(range.by('day'))
            month = array.map(m => m.format("DD MMM HH:mm"))
        }

        if(toggleButton === "7days") {
            end = moment().subtract(7, "days");
            range = extend.range(moment(end).utc(), moment(start).utc())
            array = Array.from(range.by('day'))
            month = array.map(m => m.format("DD MMM"))
        }

        if(toggleButton === "30days") {
            end = moment().subtract(1, "month");
            range = extend.range(moment(end), moment(start))
            array = Array.from(range.by('day'))
            month = array.map(m => m.format("DD MMM"))
        }

        if(toggleButton === "12months") {
            end = moment().subtract(1, "year");
            range = extend.range(moment(end), moment(start))
            array = Array.from(range.by('day'))
            month = array.map(m => m.format("DD MMM YYYY"))
        }

        return month

    }

    const Chart = async (time) => {
        try {

            // Reset state
            setChartData([])

            await axios.get(`${uri}${toggleButton}`, {
                headers: {
                  Authorization: localStorage.getItem("xiden-pool-session")
                }
            }).then((response) => {
                if(response.status === 200) {

                    if(singleData) {

                        let chartData;

                        if(Array.isArray(measurement)) {

                            // Alter object and calculate two params included in string[]
                            chartData = response.data.map(time => {
                                return {
                                    x: moment(time.datetime).format(format),
                                    y: time[measurement[0]] + time[measurement[1]]
                                }
                            })

                        }
                        else {
                            // Alter object
                            chartData = response.data.map(time => {
                                return {
                                    x: moment(time.datetime).format(format),
                                    y: time[measurement]
                                }
                            })
                        }

                        // Set state for chart datasets
                        setChartData(prevState => [
                            ...prevState,
                            {
                                label: props.label,
                                data: chartData,
                                borderColor: [
                                    '#2FDE00',
                                    '#00A6B4',
                                    '#6800B4'
                                ],
                                borderWidth: 2,
                                pointBackgroundColor: ["#fff"],
                                pointBorderColor: ["#1c1839"],
                                pointBorderWidth: 5,
                                pointRadius: 6,
                            }
                        ])

                    }
                    // Quick adjustement for double chart without array :(
                    else if(singleData === undefined) {

                        // Alter object
                        const filter1 = response.data.map(time => {
                            return {
                                x: moment(time.datetime).format(format),
                                y: time.bonus
                            }
                        })
                        const filter2 = response.data.map(time => {
                            return {
                                x: moment(time.datetime).format(format),
                                y: time.power
                            }
                        })

                        // Set state for chart datasets
                        setChartData([
                            {
                                label: "Bonus",
                                data: filter1,
                                borderColor: [
                                    '#2FDE00',
                                    // '#00A6B4',
                                    // '#6800B4'
                                ],
                                borderWidth: 2,
                                pointBackgroundColor: ["#fff"],
                                pointBorderColor: ["#1c1839"],
                                pointBorderWidth: 5,
                                pointRadius: 6,
                            },
                            {
                                label: "Power",
                                data: filter2,
                                borderColor: [
                                    // '#2FDE00',
                                    '#00A6B4',
                                    // '#6800B4'
                                ],
                                borderWidth: 2,
                                pointBackgroundColor: ["#fff"],
                                pointBorderColor: ["#1c1839"],
                                pointBorderWidth: 5,
                                pointRadius: 6,
                            }
                        ])

                    }
                    else {

                        if(single) {

                            if(!response.data.data.length) {
                                setEmpty(true)
                            }

                            // Alter object
                            const filter = response.data.data.map(time => {
                                return {
                                    x: moment(time.datetime).format(format),
                                    y: time[measurement]
                                }
                            })

                            // Set state for chart datasets
                            setChartData(prevState => [
                                ...prevState,
                                {
                                    label: response.data.poolName,
                                    data: filter,
                                    borderColor: [
                                        '#B21F00',
                                        '#C9DE00',
                                        '#2FDE00',
                                        '#00A6B4',
                                        '#6800B4'
                                    ],
                                    borderWidth: 2,
                                    pointBackgroundColor: ["#fff"],
                                    pointBorderColor: ["#1c1839"],
                                    pointBorderWidth: 5,
                                    pointRadius: 6,
                                }
                            ])

                        }
                        else {
                            response.data.forEach((pool) => {

                                // Alter object
                                const filter = pool.data.map(time => {
                                    return {
                                        x: moment(time.datetime).format(format),
                                        y: time[measurement]
                                    }
                                })

                                // Set state for chart datasets
                                setChartData(prevState => [
                                    ...prevState,
                                    {
                                        label: pool.poolName,
                                        data: filter,
                                        borderColor: [
                                            '#B21F00',
                                            '#C9DE00',
                                            '#2FDE00',
                                            '#00A6B4',
                                            '#6800B4'
                                        ],
                                        borderWidth: 2,
                                        pointBackgroundColor: ["#fff"],
                                        pointBorderColor: ["#1c1839"],
                                        pointBorderWidth: 5,
                                        pointRadius: 6,
                                    }
                                ])

                            })
                        }

                    }

                }
            })

        } catch (err) {
            if (err.response) {
                switch (err.response.status) {
                  case 404:
                    console.log("404 Not found.");
                    break;
                }
              }
        }
    }

    // Initiate chart
    useEffect(() => {
        if(toggleButton) {
            Chart(toggleButton)
        }
    }, [toggleButton])

    return(
        <React.Fragment>
            {
                empty ?
                <div className="no-chart text-light d-flex align-items-center justify-content-center">
                    <div className="chart-overlay">
                        <div className="controls-container d-sm-flex d-block text-center">
                            <h6 className="font-pnr mb-0 font-size-20 white-color">{chartTitle}</h6>
                        </div>
                    </div>
                    <p className="font-pnr mb-0 font-size-20 text-light">No data available</p>
                </div>
                :
                <div
                className="chart-container w-100"
                style={{height: 160}}>

                    <div className="chart-overlay">
                        <div className="controls-container d-sm-flex d-block text-center">
                            <h6 className="font-pnr mb-0 font-size-20 white-color">{chartTitle}</h6>
                            <ToggleBar
                            excludeDates={excludeDates}
                            activeButton={toggleButton}
                            setActiveButton={setToggleButton}
                            />
                        </div>
                    </div>

                    <Line
                    options={{
                        maintainAspectRatio: false,
                        responsive: true,
                        plugins: {
                            legend: {
                                display: false
                            },
                            filter: {
                                propagate: false
                            },
                            tooltip: {
                                displayColors: false,
                                backgroundColor: "#fff",
                                titleColor: "#222",
                                callbacks: {
                                    labelTextColor: () => {
                                        return '#222';
                                    }
                                }
                            }
                        },
                        elements: {
                            line: {
                                tension: 0.3
                            }
                        },
                        interaction: {
                            mode: "x",
                            intersect: false
                        },
                        stacked: false,
                        scales: {
                            y: {
                                min: 0,
                                stacked: false,
                                ticks: {
                                    color: "rgba(255, 255, 255, 0.3)",
                                    font: {
                                        size: "11px"
                                    }
                                }
                            },
                            x: {
                                ticks: {
                                    maxRotation: 0,
                                    minRotation: 0,
                                    maxTicksLimit: window.innerWidth >= 576 ? tickLimit : 4,
                                    color: "rgba(255, 255, 255, 0.5)",
                                    font: {
                                        size: "11px"
                                    }
                                }
                            }
                        }
                    }}
                    data={{
                        labels: calculateLabels(),
                        datasets: chartData
                    }}
                    />
                </div>
            }
        </React.Fragment>
    )

}
export default ThemeChart