import React from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";

const DeleteMinerModal = ({ show, onHide, id, params, refetch }) => {

   const deleteMiner = async () => {
      try {
         const deleteReq = await axios.delete(
            process.env.REACT_APP_BACKEND_URL + `/miner/${id}`,
            {
               headers: {
                  Authorization: localStorage.getItem("xiden-pool-session")
               }
            }
         );

         if (deleteReq.status === 200) {
            await refetch(params?.id);
            onHide();
            toast.success("Miner deleted successfully");
         }
      } catch (err) {
         console.log(err);
      }
   }

   return (
      <Modal show={show} onHide={onHide} centered size="lg" className="text-white">
         <Modal.Body>
            <h2 className="font-pnsb font-size-32 mb-5 text-center">Are you sure you want to delete this miner?</h2>

            <div className="w-100 d-flex justify-content-center">
               <button className="font-size-24 font-pnm modal-button-outline me-4" onClick={onHide}>
                  No
               </button>

               <button className="font-size-24 font-pnm modal-button-blue" onClick={deleteMiner}>
                  Yes
               </button>
            </div>
         </Modal.Body>
      </Modal>
   );
};

export default DeleteMinerModal;
