import React, { useState } from "react";
import "./Navigation.scss";
import { Navbar, Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { ReactComponent as Logo } from "../../Assets/SVGs/xiden-logo.svg";
import { ReactComponent as OverviewIcon } from "../../Assets/SVGs/overview-icon.svg";
import { ReactComponent as PoolsIcon } from "../../Assets/SVGs/pools-icon.svg";
// import { ReactComponent as RevenueIcon } from "../../Assets/SVGs/revenue-icon.svg";
import { ReactComponent as WorkersIcon } from "../../Assets/SVGs/workers-icon.svg";
import { routes } from "../../Utils/routes";
import UserDropdown from "../UserDropdown/UserDropdown";
// import { FaWallet as WalletIcon } from "react-icons/fa";
import { AiOutlinePoweroff as LogoutIcon } from "react-icons/ai";
import LogoutModal from "../LogoutModal/LogoutModal";
import { useLocation } from "react-router-dom";
import {
  BsCashCoin as WithdrawIcon,
  BsFillShieldLockFill as SecurityIcon,
} from "react-icons/bs";
import { ReactComponent as MenuIcon } from "../../Assets/SVGs/dots.svg";
import { useUserContext } from "../../Contexts/UserProvider";

const Navigation = () => {
  // state
  const [modalShow, setModalShow] = useState(() => false);

  // router location
  const location = useLocation();

  // context
  const { user } = useUserContext();

  // functions
  const toggleLogoutModal = () => setModalShow(!modalShow);

  return (
    <>
      {/* modals */}
      <LogoutModal show={modalShow} toggle={toggleLogoutModal} />

      <div className="p-4">
        <Navbar className="navigation" expand="lg" collapseOnSelect={true}>
          <div className="container-fluid px-5">
            <Navbar.Brand href={routes.homepage}>
              <Logo className={`logo`} />
            </Navbar.Brand>
            <Navbar.Toggle className="toggle" aria-controls="basic-navbar-nav">
              <MenuIcon color="#22aaff" size={50} height="40px" />
            </Navbar.Toggle>
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav>
                <Nav.Link
                  className={`route-link ${
                    location.pathname === routes.overview
                      ? "active-navigation"
                      : ""
                  }`}
                  eventKey="1"
                  as={NavLink}
                  to={routes.overview}
                >
                  <div className="d-flex align-items-center">
                    <OverviewIcon className="me-2" />
                    <p className="mb-0 font-size-20 font-pnr white-color">
                      Overview
                    </p>
                  </div>
                  <div className="highlight-underline"></div>
                </Nav.Link>

                <Nav.Link
                  className={`route-link ${
                    location.pathname === routes.pool ? "active-navigation" : ""
                  }`}
                  eventKey="2"
                  as={NavLink}
                  to={routes.pool}
                >
                  <div className="d-flex align-items-center">
                    <PoolsIcon className="me-2" />
                    <p className="mb-0 font-size-20 font-pnr white-color">
                      Pools
                    </p>
                  </div>
                  <div className="highlight-underline"></div>
                </Nav.Link>

                <Nav.Link
                  className={`route-link ${
                    location.pathname === routes.worker
                      ? "active-navigation"
                      : ""
                  }`}
                  eventKey="3"
                  as={NavLink}
                  to={routes.worker}
                >
                  <div className="d-flex align-items-center">
                    <WorkersIcon className="me-2" />
                    <p className="mb-0 font-size-20 font-pnr white-color">
                      Workers
                    </p>
                  </div>
                  <div className="highlight-underline"></div>
                </Nav.Link>

                <Nav.Link
                  className={`route-link ${
                    location.pathname === routes.withdraw
                      ? "active-navigation"
                      : ""
                  }`}
                  eventKey="4"
                  as={NavLink}
                  to={routes.withdraw}
                >
                  <div className="d-flex align-items-center">
                    {/* <RevenueIcon className="me-2" /> */}
                    <WithdrawIcon className="me-2 link-icon" />
                    {/* <p className="mb-0 font-size-20 font-pnr white-color">Add Router</p> */}
                    <p className="mb-0 font-size-20 font-pnr white-color">
                      Withdraw
                    </p>
                  </div>
                  <div className="highlight-underline"></div>
                </Nav.Link>
                

                <Nav.Link
                  className={`route-link mobile-links ${
                    location.pathname === routes.security
                      ? "active-navigation"
                      : ""
                  }`}
                  eventKey="5"
                  as={NavLink}
                  to={routes.security}
                >
                  <div className="d-flex align-items-center">
                    <SecurityIcon className="me-2 link-icon" />
                    <p className="mb-0 font-size-20 font-pnr white-color">
                      Security
                    </p>
                  </div>
                </Nav.Link>

                {/* <Nav.Link className={`route-link mobile-links ${location.pathname === routes.wallet ? "active-navigation" : ""}`} eventKey="6" as={NavLink} to={routes.wallet}>
                           <div className="d-flex align-items-center">
                              <WalletIcon className="me-2 link-icon" />
                              <p className="mb-0 font-size-20 font-pnr white-color">Wallet</p>
                           </div>
                        </Nav.Link> */}

                <Nav.Link
                  eventKey="7"
                  className="logout-link mobile-links"
                  onClick={toggleLogoutModal}
                >
                  <div className="d-flex align-items-center">
                    <LogoutIcon className="me-2 link-icon" />
                    <p className="mb-0 font-size-20 font-pnr white-color">
                      Log Out
                    </p>
                  </div>
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>

            <UserDropdown user={user} toggleLogoutModal={toggleLogoutModal} />
          </div>
        </Navbar>
      </div>
    </>
  );
};

export default Navigation;
