import React, { useState } from "react";
import "./SignIn.scss";
import Fade from "react-reveal/Fade";
import { BiKey as KeyIcon } from "react-icons/bi";
import { routes } from "../../../Utils/routes";
import { useNavigate, NavLink } from "react-router-dom";
import { AiOutlineEye as EyeOpenIcon, AiOutlineEyeInvisible as EyeCloseIcon } from "react-icons/ai";
import { Helmet } from "react-helmet";
import axios from "axios";
import qs from "qs";
import { toast } from 'react-toastify';

// state values
const stateValues = {
   email: "",
   password: "",
};

const SignIn = () => {
   // state
   const [values, setValues] = useState(() => stateValues);
   const [hidePassword, setHidePassword] = useState(() => true);

   const navigate = useNavigate();

   // functions
   const togglePasswordView = () => setHidePassword(!hidePassword);

   const handleValues = event => {
      const target = event.target;
      const { name, value } = target;
      setValues({ ...values, [name]: value });
   };

   const handleSignIn = async (event) => {
      event.preventDefault();

      try {
         const toSend = {
            email: values.email,
            password: values.password
         };

         const response = await axios.post(
            process.env.REACT_APP_BACKEND_URL + "/login",
            qs.stringify(toSend),
         );

         if (response.status === 200) {
            if (response.data.length > 0) {
               response.data.forEach(el => {
                  let token = el.split(";")[0];
                  localStorage.setItem(token.split("=")[0], token.split("=")[1])
               });
               navigate(routes.homepage);
               toast.success("Successfully signed in!", { toastId: "signin-200" });
            }
         }
      } catch (err) {
         if (err.response) {
            switch (err.response.status) {
               case 400:
                  if (err.response.data.error === "not confirmed") {
                     toast.error("Please confirm your email first!", { toastId: "signin-400-not-confirmed" });
                     return;
                  }
                  toast.error("Invalid email or password", { toastId: "signin-400" });
                  return;
               default:
                  toast.error("Oops.. There was an error!", { toastId: "signin-default" });
                  return;
            }
         }
      }
   };

   return (
      <>
         <Helmet>
            <title>Xiden Pools | Sign In</title>
         </Helmet>

         <div className="container-main">
            <Fade>
               <div className="sign-in-page">
                  <form className="main-box d-flex flex-column py-5" onSubmit={handleSignIn}>
                     <h3 className="text-center font-pnb white-color font-size-24 mb-2">Sign In</h3>

                     <label htmlFor="email" className="font-pnr font-size-18 white-color mb-1">
                        E-mail
                     </label>
                     <input
                        value={values.email}
                        autoComplete="off"
                        name="email"
                        className="pool-input mb-2"
                        type="email"
                        placeholder="Enter e-mail"
                        onChange={handleValues}
                     />

                     <label htmlFor="password" className="font-pnr font-size-18 white-color mb-1">
                        Password
                     </label>
                     <div className="w-100 position-relative mb-2">
                        <input
                           value={values.password}
                           autoComplete="off"
                           name="password"
                           className="pool-input w-100"
                           type={hidePassword ? "password" : "text"}
                           placeholder="Enter password"
                           onChange={handleValues}
                        />
                        {hidePassword ? (
                           <EyeOpenIcon className="hide-pwd-icon" onClick={() => togglePasswordView()} />
                        ) : (
                           <EyeCloseIcon className="hide-pwd-icon" onClick={() => togglePasswordView()} />
                        )}
                     </div>

                     <div className="w-100 d-flex justify-content-center align-items-center mb-4">
                        <NavLink className="text-decoration-none" to={routes.sendemail}>
                           <KeyIcon className="me-1 key-icon" />
                           <span className="text-center reset-psw">Forgot password?</span>
                        </NavLink>
                     </div>

                     <div className="d-flex justify-content-center align-items-center">
                        <button className="buttonOutlineBlue font-size-18 m-0" onClick={handleSignIn}>
                           Sign In
                        </button>
                     </div>
                  </form>
               </div>
            </Fade>
         </div>
      </>
   );
};

export default SignIn;
