import React from 'react';
import '../Enable2FA.scss';
import SmsIcon from '../Assets/sms.png';
import AppIcon from '../Assets/app.png';
import Fade from 'react-reveal/Fade';
import { useNavigate } from 'react-router-dom';
import { IActiveCard } from '../Enable2FA';
import { routes } from '../../../Utils/routes';

// interfaces


const MainSetup = ({
  handleViewChange,
  activeCard,
  handleActiveCard
})=> {
  // hooks
  const navigate = useNavigate();

  // functions
  const handleNextStep = (nextStep) => {
    if (!activeCard.smsCard && !activeCard.appCard) {
      return;
    }
    handleViewChange(nextStep);
  };

  return (
    <Fade>
      <div className="main-card-2fa">
        {/* title */}
        <h2 className="font-size-32 font-pnb text-center mb-5">
         
        </h2>

        {/* cards */}
        <div className="setup-cards-container">
          {/* <div
            className={`p-2 d-flex flex-column align-items-center justify-content-center pointer setup-card card-hover-effect sms-card ${
              activeCard.smsCard && 'active-2fa-card'
            }`}
            onClick={() => handleActiveCard('sms')}
          >
            <img src={SmsIcon} alt="sms" className="tfa-img" />
            <p className="mt-3 mb-2 font-size-32 font-pnb text-center card-heading">Use SMS</p>
            <p className="font-size-20 font-pnr text-center tfa-info">
              Security codes will be sent <br /> to your mobile phones.
            </p>
          </div> */}

          <div
            className={`p-2 d-flex flex-column align-items-center justify-content-center pointer setup-card card-hover-effect app-card ${
              activeCard.appCard && 'active-2fa-card'
            }`}
            onClick={() => handleActiveCard('app')}
          >
            <img src={AppIcon} alt="sms" className="tfa-img" />
            <p className="mt-3 mb-2 font-size-32 font-pnb text-center card-heading">
              Use a mobile app
            </p>
            <p className="font-size-20 font-pnr text-center tfa-info">
              Security codes will be generated <br /> by an authenticator app.
            </p>
          </div>
        </div>

        {/* continue button */}
        <button
          className="zixxar-blue-button btn-height-56 font-size-20 font-pnm radius-12 control-2fa-btn"
          disabled={!activeCard.smsCard && !activeCard.appCard && true}
          onClick={() => handleNextStep('step2')}
        >
          Continue
        </button>

        {/* skip */}
        <span
          className="font-size-18 font-pnm skip-span mt-4 pointer"
          onClick={() => navigate(routes.security)}
        >
          {/* Skip 2 factor authentification */}
        </span>
      </div>
    </Fade>
  );
};

export default MainSetup;
