import { Routes, Route, Navigate } from "react-router-dom";
import "./template/main.scss";

import OnboardingLayout from "./Layouts/OnboardingLayout/OnboardingLayout";
import AdminLayout from "./Layouts/AdminLayout/AdminLayout";
import SignIn from "./Views/Onboarding/SignIn/SignIn";
import SignUp from "./Views/Onboarding/SignUp/SignUp";
import Homepage from "./Views/Homepage/Homepage";
import IndividualPool from "./Views/IndividualPool/IndividualPool";
import Overview from "./Views/Overview/Overview";
import Pending from "./Views/Pending/Pending";
import Pool from "./Views/Pool/Pool";
import Revenue from "./Views/Revenue/Revenue";
import Workers from "./Views/Workers/Workers";
import AddRouter from "./Views/AddRouter/AddRouter";
import ErrorPage from "./Views/ErrorPage/ErrorPage";
import SendEmail from "./Views/Onboarding/ForgotPassword/SendEmail";
import ChangePassword from "./Views/Onboarding/ForgotPassword/ChangePassword";
import Security from "./Views/SecurityPage/Security";
// import Wallet from "./Views/Wallet/Wallet";
import CreatePool from "./Views/CreatePool/CreatePool";
import ActivatePool from "./Views/ActivatePool/ActivatePool";
import Withdraw from "./Views/Withdraw/Withdraw";
import AddMiner from "./Views/AddMiner/AddMiner";

import { routes } from "./Utils/routes";
import { ToastContainer } from "react-toastify";
import KycProcess from "./Views/KycProcess/KycProcess";
import KycProvider from "./Views/KycProcess/KycContext/KycProvider";
import Enable2FA from "./Views/Enable2FA/Enable2FA";

function App() {
   
   return (
      <>
      <KycProvider>
         <ToastContainer
            position="top-center"
            autoClose={5000}
            // autoClose={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            closeButton={true}
         />

         <div className="App">
            <Routes>
               <Route element={<OnboardingLayout />}>
                  <Route path={routes.signIn} element={<SignIn />} />
                  <Route path={routes.signUp} element={<SignUp />} />
                  <Route path={routes.sendemail} element={<SendEmail />} />
                  <Route path={routes.changePassword} element={<ChangePassword />} />
               </Route>
               <Route element={<AdminLayout />}>
                  <Route path={routes.homepage} element={<Homepage />} />
                  <Route path={routes.overview} element={<Overview />} />
                  <Route path={routes.pending} element={<Pending />} />
                  <Route path={routes.pool} element={<Pool />} />
                  <Route path={routes.pool + "/:id"} element={<IndividualPool />} />
                  <Route path={routes.revenue} element={<Revenue />} />
                  <Route path={routes.worker} element={<Workers />} />
                  <Route path={routes.addRouter} element={<AddRouter />} />
                  <Route path={routes.security} element={<Security />} />
                  {/* <Route path={routes.wallet} element={<Wallet />} /> */}
                  <Route path={routes.createPool} element={<CreatePool />} />
                  <Route path={routes.activatePool} element={<ActivatePool />} />
                  <Route path={routes.withdraw} element={<Withdraw />} />
                  <Route path={routes.addMiner} element={<AddMiner />} />
                  <Route path={routes.kycProcess} element={<KycProcess />} />
                  <Route path={routes.enable2FA} element={<Enable2FA />} />

               </Route>
               <Route path={routes.error} element={<ErrorPage />} />
               <Route path="*" element={<Navigate to={routes.error} />} />
            </Routes>
         </div>
         </KycProvider>
      </>
   );
}

export default App;
