import React from "react";
import "./PoolsOverviewHeader.scss";
import { Row, Col } from "react-bootstrap";

const PoolsOverviewHeader = ({ activeFilter, setActiveFilter }) => {
   return (
      <Row xs={1} sm={1} md={2} className="mb-4 filter-container">
         <Col>
            <p className="font-pnb font-size-32 white-color mb-sm-3 mb-md-0">Pools Overview</p>
         </Col>
      </Row>
   );
};

export default PoolsOverviewHeader;
