import React, { useState, useEffect } from "react";
import "./Pool.scss";
import { Helmet } from "react-helmet";
import axios from "axios";

import PoolsOverview from "./Components/PoolsOverview/PoolsOverview";
import MyPools from "./Components/MyPools/MyPools";
import { PoolsContext } from "../../Contexts/PoolsContext";
import { toast } from "react-toastify";

const Pool = () => {

   const [poolsInformation, setPoolsInformation] = useState([]);

   const poolsData = async () => {

      try {

         await axios.get(`${process.env.REACT_APP_BACKEND_URL}/mining-stats/pools/stats`, {
            headers: {
               Authorization: localStorage.getItem("xiden-pool-session")
            }
         }).then((res) => {
               if(res.status === 200) {
                  setPoolsInformation(res.data);
               }
         })

      } catch (err) {
         if (err.response) {
            switch (err.response.status) {
               case 404:
                  toast.warn("Pool not found", {
                     toastId: "pool_404"
                  })
                  return;
               default:
                  console.log("Something is temporarily wrong.");
                  return;
            }
         }
      }

   }

   useEffect(() => {
      poolsData()
   }, []);

   return (
      <>
         <Helmet>
            <title>Xiden Pools | Pools</title>
         </Helmet>

         <div className="py-4 pools">
            <div className="container-main">

               <PoolsContext.Provider value={poolsInformation}>
                  <PoolsOverview />
                  <MyPools />
               </PoolsContext.Provider>
            </div>
         </div>
      </>
   );
};

export default Pool;
