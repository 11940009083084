import React, { useState } from "react";
import "./Overview.scss";
import { Helmet } from "react-helmet";

import Economics from "./Components/Economics/Economics";
import Mining from "./Components/Mining/Mining";
import Staking from "./Components/Staking/Staking";
// import BlocksTable from "./Components/BlocksTable/BlocksTable";
import ActivatePool from "./Components/Hero/ActivatePool";
import CreatePool from "./Components/Hero/CreatePool";
import { useEffect } from "react";
import axios from "axios";
import { getDaysInAMonth } from "../../Utils/chartTimeStamps";

const Overview = () => {
   const [activeHero, setActiveHero] = useState("create");

   let toRenderHero;

   if (activeHero === "create") {
      toRenderHero = <CreatePool />;
   } else if (activeHero === "activate") {
      toRenderHero = <ActivatePool />;
   } else {
      toRenderHero = null;
   }

   const [economicsScanner, setEconomicsScanner] = useState();
   const [chartRewardOverview, setChartRewardOverview] = useState([]);
   const [chartPoolForOverviewMiningPower, setChartPoolForOverviewMiningPower] = useState([]);

    /**
    * Get rewards and scanner information
    */
   const allRewardsEconomics = async () => {
      try {
         await axios.get(process.env.REACT_APP_BACKEND_URL + `/rewards/getAllRewardsEconomics`, {
               headers: {
                  Authorization: localStorage.getItem("xiden-pool-session")
               }
         }).then((response) => {
               if(response.status === 200) {

                  // Sort scanner by last date
                  if(response.data.allScannerInfo) {
                     response.data.allScannerInfo.sort((a, b) => {

                           let
                           dateA = new Date(a.day).getTime(),
                           dateB = new Date(b.day).getTime();

                           return dateA < dateB ? 1 : -1;

                     })
                  }
                  setEconomicsScanner(response.data.allScannerInfo)

                  response.data.allRewards.forEach((pool) => {

                     let generateHoursForDataDay = [], dataForMiningPower = [];
                     for(let i = 0; i < getDaysInAMonth().length; i++){
                        generateHoursForDataDay.push(0)
                        dataForMiningPower.push(0)
                     }

                     let poolID;
                     pool.forEach((singlePool) => {

                           let singlepoolPosition = new Date(singlePool.day).getDate()

                           generateHoursForDataDay.splice(singlepoolPosition, 1, singlePool.totalReward);

                           dataForMiningPower.splice(singlepoolPosition, 1, singlePool.power);

                           poolID = singlePool.poolID

                     })

                     if(pool.length) {

                        setChartRewardOverview(prevState => [...prevState, generateHoursForDataDay])

                        setChartPoolForOverviewMiningPower(prevState => [...prevState, dataForMiningPower])

                     }

                  })

               }
         })
      } catch (err) {
         console.log(err);
         if (err.response) {
               switch (err.response.status) {
                  case 404:
                     console.log("404 Not found.");
                     return;
                  default:
                     console.log("Something is temporarily wrong.");
                     return;
               }
         }
      }
   }

   useEffect(() => {
      allRewardsEconomics()
   }, [])


   const sumArrayInObjectForRevenue = chartRewardOverview.reduce(function(array1, array2) {
      return array2.map(function(value, index) {
        return value + (array1[index] || 0);
      }, 0);
    }, []);

    const sumArrayInObjectForMining = chartPoolForOverviewMiningPower.reduce(function(array1, array2) {
      return array2.map(function(value, index) {
        return value + (array1[index] || 0);
      }, 0);
    }, []);

   return (
      <>
         <Helmet>
            <title>Xiden Pools | Overview</title>
         </Helmet>

         <div className="py-4 overview">
            <div className="container-main">
               {toRenderHero}
               <Economics
               data={{
                  economicsScanner: economicsScanner,
                  chartRewardOverview: [{data: sumArrayInObjectForRevenue, name: 'Revenue'}]
               }} />
               <Mining
               data={{
                  miningPower: [{data: sumArrayInObjectForMining, name: 'Mining power'}]
               }}
               />
               <Staking />
            </div>
         </div>
      </>
   );
};

export default Overview;
