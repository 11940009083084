import React, { useState } from "react";
// import { ReactComponent as Info } from "../../../../../Assets/SVGs/info.svg";
import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import ChartOverlay from "../../../../../Components/ChartOverlay/ChartOverlay";
import { getDaysInAMonth } from "../../../../../Utils/chartTimeStamps";
import Chart from "react-apexcharts";
import ThemeChart from "../../../../../Components/Chart/Chart";

const SecondRow = ({ details }) => {
  // const { publicDifficulty, privateDifficulty, loadingPublicPrivateDiffculty } = details;

  const { miningPower } = details;

  // state
  const [activeButton, setActiveButton] = useState(() => "month");

  // tooltips
  // const privateDifficultyTp = <Tooltip></Tooltip>,
  // publicDifficultyTp = <Tooltip></Tooltip>;

  return (
    <Row lg={1} md={1} sm={1} xs={1} className="mining-second-row">
      <Col lg={12} className="mb-4">
        <div
          className={`${
            miningPower.length
              ? ""
              : "d-flex align-items-center justify-content-center"
          }  main-box height-252 position-relative d-flex align-items-end`}
        >
          <ThemeChart
            uri={`${process.env.REACT_APP_BACKEND_URL}/mining-stats/reward/`}
            measurement="power"
            singleData={true}
            excludeDates={["24hours"]}
            defaultDate="7days"
            single={true}
            label="Power"
            chartTitle="Mining power" />

          {/* {miningPower.length ? (
            <Chart
              type="line"
              series={miningPower}
              options={{
                chart: {
                  zoom: {
                    enabled: false,
                  },
                },
                toolbar: {
                  show: false,
                  tools: {
                    selection: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    download: false,
                  },
                },
                colors: ["#E91E63", "#66DA26", "#FF9800"],
                dataLabels: {
                  enabled: false,
                },
                grid: {
                  show: false,
                },
                stroke: {
                  curve: "smooth",
                  width: 3,
                },
                xaxis: {
                  categories: getDaysInAMonth(),
                  labels: {
                    show: true,
                    formatter: (x) => {
                      return (
                        (x < 10 ? 0 + "" + x : x) +
                        "." +
                        String(new Date().getMonth() + 1).padStart(2, "0")
                      );
                    },
                    style: {
                      colors: "#fff",
                    },
                  },
                  axisBorder: {
                    show: false,
                  },
                  axisTicks: {
                    show: false,
                  },
                },
                yaxis: {
                  show: false,
                },
                legend: {
                  labels: {
                    colors: "#fff",
                  },
                },
                tooltip: {
                  enabled: true,
                  x: {
                    show: true,
                    format: "dd MMM",
                    formatter: (hour) => {
                      return `Day: ${
                        (hour < 10 ? 0 + "" + hour : hour) +
                        "." +
                        String(new Date().getMonth() + 1).padStart(2, "0")
                      }`;
                    },
                  },
                  y: {
                    formatter: (value) => {
                      return value.toFixed(2);
                    },
                    // title: {
                    //    formatter: (seriesName) => `Power:`,
                    // },
                  },
                },
              }}
              height="200"
            />
          ) : (
            <p className="text-light text-center font-size-20">
              Loading, please wait ...
            </p>
          )} */}

          {/* <ChartOverlay
            chartTitle="Mining power"
            activeButton={activeButton}
            setActiveButton={setActiveButton}
          /> */}
        </div>
      </Col>
    </Row>
  );
};

export default SecondRow;
