import React from "react";
import "./Announcement.scss";
import { Row, Col, Container } from "react-bootstrap";
import BannerImg from "../../../../Assets/PNGs/mining-banner.png";
// import MiningIcon from "../../../../Assets/PNGs/mining-icon.png";
import { ReactComponent as Logo } from "../../../../Assets/SVGs/xiden-logo.svg";
import Fade from "react-reveal";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../../Utils/routes";

const Announcement = ({ text }) => {
  const navigate = useNavigate();

  return (
    <Container fluid className="px-4">
      <Fade>
        <div className="mining-banner mb-3">
          <div className="main-box banner-announcement p-2">
            <Col className="d-flex flex-column justify-content-center pool-col">
              {/* <Logo className="mb-1 mining-icon" /> */}
              {/* <img src={MiningIcon} className="mb-1 mining-icon" alt="mining-icon" /> */}
              {/* <p className="font-size-64 white-color mb-1 font-pnr">CDPool</p> */}
              <p className="font-size-16 yellow-color text-center ">{text}</p>
              {/* <button onClick={() => navigate(routes.overview)} className="buttonBlue me-0">Start now</button> */}
            </Col>
          </div>
        </div>
      </Fade>
    </Container>
  );
};

export default Announcement;
