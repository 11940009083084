import React, { useEffect, useState } from "react";
import "../Security.scss";
import QRCode from "react-qr-code";
import { toast } from "react-toastify";
import Disable2FAModal from "../../../Components/Disable2FAModal/Disable2FAModal";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useKycContext } from "../../KycProcess/KycContext/KycProvider";

export default function KYC() {
  // state
  const [active, setActive] = useState(() => false);
  const [codeValue, setCodeValue] = useState(() => "");
  const [showDisableModal, setShowDisableModal] = useState(() => false);
  const [status, setStatus] = useState("Inactive");
  const [rejectMessage,setRejectMessage]=useState(()=>"")

  const { setCurrentStep,setRejectReason,rejectReason } = useKycContext();

  const navigate = useNavigate();

  // functions
  const handleCodeValue = (event) => setCodeValue(event.target.value);

  const handleActivateTwoFA = () => {
    if (codeValue === "") {
      return toast.error("You didn't type the QR code");
    } else {
      setActive(true);
    }
  };

  const toggleDisableModal = () => setShowDisableModal(!showDisableModal);

  const handleDisableTwoFA = () => {
    setActive(false);
  };

  let rejectionReason = ["documents", "personal_info", "both"];
  const handleCompleteKYC = () => {
    if (rejectReason === rejectionReason[0]) {
      setCurrentStep(1);
    }
    if (rejectReason === rejectionReason[1]) {
      setCurrentStep(0);
    }
    if (rejectReason === rejectionReason[2]) {
      setCurrentStep(0);
    }

    navigate("/kyc");
  };

  useEffect(async () => {
    try {
      const checkReq = await axios.get(
        `https://kyc.xiden.com/kyc/getKYCStatus`,
        {
          headers: {
            customauth: localStorage.getItem("xiden-pool-session"),
          },
        }
      );

      if (checkReq.status === 200) {
        setRejectMessage(checkReq.data.rejected_message);
        setRejectReason(checkReq.data.rejection_reason);

        if(checkReq.data.status){
        setStatus(checkReq.data.status)}
      }
    } catch (err) {
      toast.error("An unknown error has occured. Please try again later");
      

        }
  }, []);

  return (
    <>
      {/* modals */}
      <Disable2FAModal
        show={showDisableModal}
        toggle={toggleDisableModal}
        handleDisableTwoFA={handleDisableTwoFA}
      />

      {/* component */}
      <div className="col-md-6 pe-md-0 pe-0">
        <div className=" two-fa-card position-relative ">
          <h5 className="font-size-20 font-pnb ">KYC verification</h5>
          <p>KYC enhances security and prevents fraud on our website.</p>
          {/* status */}
          <div className="status-wrapper mb-4">
            <strong className="font-size-18 font-pnb mb-0 ">Status:</strong>
            <div className="d-flex align-items-center">
              {/* <div className={`status-badge ${active ? "active-bg" : "disabled-bg"}`}></div> */}
              <span
                className={`font-pnb font-size-20 ${status}-status `}
              >
                {status?.toLocaleUpperCase()}
              </span>
            </div>
          </div>
          {rejectMessage?.length > 0 && status!=="accepted" && (
            <p>
              <strong>Reject message:</strong> {rejectMessage}
            </p>
          )}

          {/* setup */}
          <div className="d-flex">
            {status === "rejected" && (
              <button
                className="fa-button m-auto font-size-12 font-pnm deactivate-bg"
                onClick={handleCompleteKYC}
              >
                Update 
              </button>
            )}
            {status === "Inactive" && (
              <button
                className="fa-button m-auto font-size-12 font-pnm activate-2fa "
                onClick={handleCompleteKYC}
              >
                Start
              </button>
            )}
          </div>


        </div>
      </div>
    </>
  );
}
