import React from "react";
import { Row, Col } from "react-bootstrap";
import ThemeChart from "../../../../../Components/Chart/Chart";

const SecondRow = () => {

   return (
      <Row>
         <Col className="mb-4">
            <div className="main-box height-252 position-relative d-flex align-items-end">

               <ThemeChart
               uri={`${process.env.REACT_APP_BACKEND_URL}/mining-stats/pools/`}
               measurement="power"
               singleData={false}
               excludeDates={[]}
               defaultDate="24hours"
               single={false}
               chartTitle="Mining Power" />

            </div>
         </Col>
      </Row>
   );

};

export default SecondRow;
