import React, { useState } from "react";
import Fade from "react-reveal";
import { ReactComponent as Clipboard } from "../../../Assets/SVGs/clipboard.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";

const ActivatePoolStep4 = ({ handleActiveStep }) => {
   // state
   const [inputValue, setInputValue] = useState(() => "");

   // data
   const stepCardData = {
      step: 4,
      title: "Select Wallet",
      info: "Wait until hashrate is displayed. Workers will be automatically added within a few seconds on the Workers page in your account.",
   };

   // functions
   const handleInputChange = event => setInputValue(event.target.value);

   const handleCopyMessage = () => {
      if (inputValue === "") {
         return toast.error("The input is empty");
      } else {
         return toast.success("The value was successfully copied");
      }
   };

   return (
      <Fade>
         <div className="w-100 d-flex flex-column">
            <div className="setup-card m-auto d-flex flex-column">
               <h6 className="font-pnr text-center font-size-24 mb-4">Staking Status</h6>

               <div className="inner-wrapper">
                  <p className="font-size-16 font-pnm text-center">{stepCardData.info}</p>
               </div>

               <button className="buttonYellow mx-auto my-3 d-flex align-items-center justify-content-center gap-2">
                  <div className="spinner-border" role="status" style={{ width: "15px", height: "15px" }}>
                     <span className="sr-only"></span>
                  </div>
                  Pending
               </button>

               <div className="my-3 d-flex align-items-center justify-content-center flex-wrap gap-3">
                  <input
                     value={inputValue}
                     type="text"
                     className="pool-input tx-id"
                     placeholder="TX ID"
                     onChange={event => handleInputChange(event)}
                  />

                  <CopyToClipboard text={inputValue} onCopy={handleCopyMessage}>
                     <div className="copy-to-clipboard">
                        <Clipboard />
                        <p className="mb-0 ms-1">Copy</p>
                     </div>
                  </CopyToClipboard>
               </div>

               <div className="p font-size-64 font-pnsb text-center">1000 XDEN</div>
            </div>
         </div>
      </Fade>
   );
};

export default ActivatePoolStep4;
