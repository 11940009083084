import React, { useState, useEffect, useContext } from "react";
import "./MyPoolsTable.scss";
import { Table } from "react-bootstrap";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
import loader from "../../../../../Assets/SVGs/loader.svg";
import { PoolsContext } from "../../../../../Contexts/PoolsContext";
import eventBus from "../../../../../Utils/eventBus";
import axios from "axios";
import { toast } from "react-toastify";
import { ReactComponent as XdenIcon } from "../../../../../Assets/SVGs/xden_icon.svg";
import { ReactComponent as MiningPower } from "../../../../../Assets/SVGs/mining_power.svg";
import { ReactComponent as Miners } from "../../../../../Assets/SVGs/table_miners.svg";

const MyPoolsTable = () => {
  const navigate = useNavigate();
  const tableData = useContext(PoolsContext);

  const [pending, setPending] = useState(true);
  const [search, setSearch] = useState("");

  const [rewards, setRewards] = useState([]);

  const getRewardsForPools = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/rewards/newGetAllRewards`, {
          headers: {
            Authorization: localStorage.getItem("xiden-pool-session"),
          },
        })
        .then((res) => {
          if (res.status === 200) {
            res.data.forEach((reward) => {
              setRewards((prevState) => ({
                ...prevState,
                [reward.poolId]: reward,
              }));
            });
          }
        });
    } catch (err) {
      if (err.response) {
        switch (err.response.status) {
          case 404:
            toast.warn("Pool not found", {
              toastId: "pool_404",
            });
            return;
          default:
            console.log("Something is temporarily wrong.");
            return;
        }
      }
    }
  };

  // Map rewards
  const rewardTableData = (poolID, type) => {
    let result;

    if (rewards) {
      Object.values(rewards).forEach((reward) => {
        if (reward.poolId === poolID) {
          result = reward[type] !== 0 ? reward[type].toFixed(4) : 0;
        }
      });
    }

    // Safety measure
    else {
      result = 0;
    }

    return result === undefined ? 0 : result;
  };

  /**
   * Create table data from context: PoolsContext
   * @returns JSX.ELement
   */
  const poolRow = () => {
    setTimeout(() => {
      setPending(false);
    }, 1000);

    return (
      <>
        {tableData ? (
          pending ? (
            <tr>
              <td colSpan="11" className="font-size-18 font-pnl text-center">
                Loading &nbsp;&nbsp;
                <img src={loader} alt="" />
              </td>
            </tr>
          ) : (
            tableData
              .filter((pool) => {
                return pool.poolName.toLowerCase().includes(search);
              })
              .map((pool, i) => {
                return (
                  <tr
                    valign="middle"
                    onClick={() => navigate(pool.poolId)}
                    key={`pooltable-${i}`}
                  >
                    <td className="font-size-18 font-pnl">{pool.poolName}</td>
                    <td className="font-size-18 font-pnl">
                      <div className={`${pool.poolType}-status`}>
                        {pool.poolType}
                      </div>
                    </td>
                    <td
                      className={`font-size-18 font-pnl ${
                        pool.poolStatus === "Offline"
                          ? "red-color"
                          : "green-color"
                      }`}
                    >
                      {pool.poolStatus}
                    </td>
                    <td className="font-size-18 font-pnl">
                      {pool.poolPower || pool.poolPower > 0 ? (
                        <>
                          <MiningPower />
                          {pool.poolPower}
                        </>
                      ) : null}
                    </td>
                    <td className="font-size-18 font-pnl">
                      {pool.activeMiners || pool.activeMiners > 0 ? (
                        <>
                          <Miners />
                          {pool.activeMiners}
                        </>
                      ) : null}
                    </td>
                    <td className="font-size-18 font-pnl">
                      {pool.deviceConnected || pool.deviceConnected > 0
                        ? pool.deviceConnected
                        : null}
                    </td>

                    <td className="font-size-14 font-pnl">
                      {rewardTableData(pool.poolId, "totalPrivateReward") !==
                      0 ? (
                        <>
                          <span className="w-25 me-3 d-inline-block">
                            {rewardTableData(pool.poolId, "totalPrivateReward")}
                          </span>
                          <XdenIcon />
                        </>
                      ) : (
                        rewardTableData(pool.poolId, "totalPrivateReward")
                      )}
                    </td>

                    <td className="font-size-14 font-pnl">
                      {rewardTableData(pool.poolId, "totalPublicreward") !==
                      0 ? (
                        <>
                          <span className="w-25 me-3 d-inline-block">
                            {rewardTableData(pool.poolId, "totalPublicreward")}
                          </span>
                          <XdenIcon />
                        </>
                      ) : (
                        rewardTableData(pool.poolId, "totalPublicreward")
                      )}
                    </td>

                    <td className="font-size-14 font-pnl">
                      {rewardTableData(pool.poolId, "totalreward") !== 0 ? (
                        <>
                          <span className="w-25 me-3 d-inline-block">
                            {rewardTableData(pool.poolId, "totalreward")}
                          </span>
                          <XdenIcon />
                        </>
                      ) : (
                        rewardTableData(pool.poolId, "totalreward")
                      )}
                    </td>

                    <td className="font-size-18 font-pnl">
                      <Moment format="DD MMM YYYY">{pool.createdAt}</Moment>
                    </td>
                  </tr>
                );
              })
          )
        ) : (
          <tr>
            <td colSpan="11" className="font-size-18 font-pnl text-center">
              No pools found
            </td>
          </tr>
        )}
      </>
    );
  };

  useEffect(() => {
    getRewardsForPools();
    eventBus.on("poolTableSearch", (data) => {
      setSearch(data.search.trim());
    });
  }, []);

  return (
    <Table className="my-pools-table" responsive hover>
      <thead>
        <tr>
          {/* <th className="font-size-18 font-pnsb">ID</th> */}
          <th className="font-size-18 font-pnsb">Name</th>
          <th className="font-size-18 font-pnsb">Type</th>
          <th className="font-size-18 font-pnsb">Status</th>
          <th className="font-size-18 font-pnsb">Mining Power</th>
          <th className="font-size-18 font-pnsb">Active Miners</th>
          <th className="font-size-18 font-pnsb">Connected devices</th>
          <th className="font-size-18 font-pnsb">Total Private Revenue</th>
          <th className="font-size-18 font-pnsb">Total Public Revenue</th>
          <th className="font-size-18 font-pnsb">Total Revenue</th>
          {/* <th className="font-size-18 font-pnsb">Minter ID</th> */}
          <th className="font-size-18 font-pnsb">Date created</th>
        </tr>
      </thead>
      <tbody>
        {poolRow()}
        {/* {toRender} */}
      </tbody>
    </Table>
  );
};

export default MyPoolsTable;
