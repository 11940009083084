import React, { useState, useEffect } from "react";
import "./MyPoolsHeader.scss";
import { Row, Col } from "react-bootstrap";
import eventBus from "../../../../../Utils/eventBus";

const MyPoolsHeader = () => {

   const handleSearch = (event) => {
      eventBus.dispatch("poolTableSearch", {
         search: event.target.value
      })
   }

   return (
      <Row xs={1} sm={1} md={1} lg={2} className="mb-4 filter-container">
         <Col lg={4}>
            <p className="font-pnb font-size-32 white-color mb-sm-3 mb-md-0">My Pools</p>
         </Col>

         <Col lg={8}>
            <Row xs={1} sm={1} md={2}>
               <Col className="text-lg-end" lg={12}>
                  <input onChange={handleSearch} className="search-input font-pnr my-pools-search" placeholder="Search pools..." />
               </Col>
            </Row>
         </Col>
      </Row>
   );
};

export default MyPoolsHeader;
