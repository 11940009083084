import React, { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import { ethers } from "ethers";
import { stakeAbi, sxidAbi } from "../Utils/abi";
import axios from "axios";

const MetamaskContext = React.createContext();

export const useMetamaskContext = () => useContext(MetamaskContext);

const MetamaskProvider = ({ children }) => {
  const [wallet, setWallet] = useState(null);
  const [balance, setBalance] = useState(null);
  const [loadingStake, setLoadingStake] = useState(true);

  const hasStake = async (
    poolAddress,
    poolID,
    alreadyHasStake,
    reFetchPool
  ) => {
    // check if already has stake
    if (alreadyHasStake) {
      setLoadingStake(false);
      return;
    }

    // if not, check if user has stake in the pool
    try {
      const RPC = process.env.REACT_APP_RPC;
      const provider = new ethers.providers.JsonRpcProvider(RPC);

      // define the contract
      const StakeContract = new ethers.Contract(
        process.env.REACT_APP_STAKE_CONTRACT_ADDRESS,
        stakeAbi,
        provider
      );

      // verify if has stake by calling the stake contract method hasStake with the address saved in pool (which holds the redeemed sxid)
      const hasStake = await StakeContract.hasStake(poolAddress);

      if (hasStake?.stakes?.length > 0) {
        if (
          hasStake?.stakes[0][0]?.toLowerCase() === poolAddress?.toLowerCase()
        ) {
          const toSend = {
            address: poolAddress,
            poolID: poolID,
          };

          // when the user has stake, we send the address and poolID to the backend to save it in the database (so we can check if the user has already staked)
          try {
            const checkReq = await axios.post(
              process.env.REACT_APP_BACKEND_URL + `/staking/confirmStake`,
              toSend,
              {
                headers: {
                  Authorization: localStorage.getItem("xiden-pool-session"),
                },
              }
            );
            if (checkReq.status === 200) {
              await new Promise((resolve) => setTimeout(() => resolve(), 3000));
              await reFetchPool();
            }
          } catch (err) {
            if (err.response) {
              switch (err.response.status) {
                default:
                  toast.error("Staking failed", { toastId: "stakingError" });
                  return;
              }
            }
          }
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingStake(false);
    }
  };

  const accountChange = async (newAccount) => {
    // check if the user has changed his account
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const network = await provider.getNetwork();

    // if the user has changed his account, we update the wallet and balance
    if (
      network.chainId !== Number(process.env.REACT_APP_XDEN_NETWORK_CHAINID) ||
      network.name !== process.env.REACT_APP_XDEN_NETWORK_NAME
    ) {
      return toast.warning(
        `Please connect to the Xiden ${
          process.env.NODE_ENV === "development" ? "testnet" : "mainnet"
        }`,
        { toastId: "sxid" }
      );
    }

    console.log(provider);

    setWallet(newAccount[0]);
    try {
      const signer = provider.getSigner();
      const contract = new ethers.Contract(
        process.env.REACT_APP_SXID_CONTRACT_ADDRESS,
        sxidAbi,
        signer
      );

      // get contract balance
      const contractBalance = await contract.balanceOf(newAccount.toString());
      setBalance(ethers.utils.formatEther(contractBalance));

      console.log(signer);
    } catch (err) {
      console.error(err);
      // toast.error("There was a problem connecting to MetaMask");
    }
  };

  const chainChanged = () => {
    // check if the user has changed his chain
    window.location.reload();
  };

  useEffect(() => {
    // check if the user has metamask and connect to it
    const connectHandler = async () => {
      if (window.ethereum) {
        try {
          const res = await window.ethereum.request({
            method: "eth_requestAccounts",
          });

          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const network = await provider.getNetwork();

          if (
            network.chainId !==
              Number(process.env.REACT_APP_XDEN_NETWORK_CHAINID) ||
            network.name !== process.env.REACT_APP_XDEN_NETWORK_NAME
          ) {
            return toast.warning(
              `Please connect to the Xiden ${
                process.env.NODE_ENV === "development" ? "testnet" : "mainnet"
              }`,
              { toastId: "sxid" }
            );
          }

          await accountChange(res);
        } catch (err) {
          console.error(err);
          // toast.error("There was a problem connecting to MetaMask");
        }
      }
    };

    connectHandler();

    // check if the user has changed his account or chain and update the wallet and balance if needed
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", accountChange);
      window.ethereum.on("chainChanged", chainChanged);
    }
  }, []);

  const stateObject = {
    wallet,
    balance,
    hasStake,
    loadingStake,
    setLoadingStake,
  };

  return (
    <MetamaskContext.Provider value={stateObject}>
      {children}
    </MetamaskContext.Provider>
  );
};

export default MetamaskProvider;
