import React from "react";
import "./RowsStyling/Rows.scss";
import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ReactComponent as Info } from "../../../../../Assets/SVGs/info.svg";
import { ReactComponent as MinersIcon } from "../../../../../Assets/SVGs/helmet.svg";
import { ReactComponent as PoolsIcon } from "../../../../../Assets/SVGs/planet.svg";
import { ReactComponent as ActivePoolsIcon } from "../../../../../Assets/SVGs/pink-pools.svg";
// import { ReactComponent as DeviceIcon } from "../../../../../Assets/SVGs/device.svg";
import { ReactComponent as HotspotIcon } from "../../../../../Assets/SVGs/hotspot.svg";
import ThemeLoader from "../../../../../Components/Loader/Loader";

const FirstRow = ({ details }) => {
   const { minersNumber, mintersNumber, poolsNumber, activePoolsNumber, loadingMinersNumber, loadingMintersNumber, loadingPoolsNumber, loadingActivePoolsNumber } = details;

   return (
      <Row lg={4} xs={2}>
         <Col className="mb-4">
            <div className="main-box height-252 d-flex justify-content-between flex-column">
               <div className="d-flex justify-content-between mb-3 w-100">
                  <p className="font-pnr white-color font-size-20">Total miners</p>
                  {/* info icon */}
                  <OverlayTrigger placement="top" overlay={<Tooltip>Total number of mining devices across all KrateR Pools</Tooltip>}>
                     <Info className="cursor-pointer" />
                  </OverlayTrigger>
               </div>
               <div className="d-flex flex-column justify-content-center align-items-center">
                  {/* main icon */}
                  <MinersIcon className="card-icons" />
                  {loadingMinersNumber ? (
                     <ThemeLoader />
                  ) : (
                     <p className="font-pnsb mb-0 font-size-40 white-color">{minersNumber}</p>
                  )}
               </div>
            </div>
         </Col>

         <Col className="mb-4">
            <div className="main-box height-252 d-flex justify-content-between flex-column">
               <div className="d-flex justify-content-between mb-3 w-100">
                  <p className="font-pnr white-color font-size-20">Total pools</p>
                  {/* info icon */}
                  <OverlayTrigger placement="top" overlay={<Tooltip>Total number of KrateR Pools created</Tooltip>}>
                     <Info className="cursor-pointer" />
                  </OverlayTrigger>
               </div>
               <div className="d-flex flex-column justify-content-center align-items-center">
                  {/* main icon */}
                  <PoolsIcon className="card-icons" />
                  {loadingPoolsNumber ? (
                     <ThemeLoader />
                  ) : (
                     <p className="font-pnsb mb-0 font-size-40 white-color">{poolsNumber}</p>
                  )}
               </div>
            </div>
         </Col>

         <Col className="mb-4">
            <div className="main-box height-252 d-flex justify-content-between flex-column">
               <div className="d-flex justify-content-between mb-3 w-100">
                  <p className="font-pnr white-color font-size-20">Active pools</p>
                  {/* info icon */}
                  <OverlayTrigger placement="top" overlay={<Tooltip>Number of active KrateR Pools</Tooltip>}>
                     <Info className="cursor-pointer" />
                  </OverlayTrigger>
               </div>
               <div className="d-flex flex-column justify-content-center align-items-center">
                  {/* main icon */}
                  <ActivePoolsIcon className="card-icons" />
                  {loadingActivePoolsNumber ? (
                     <ThemeLoader />
                  ) : (
                     <p className="font-pnsb mb-0 font-size-40 white-color">{activePoolsNumber}</p>
                  )}
               </div>
            </div>
         </Col>

         <Col className="mb-4">
            <div className="main-box height-252 d-flex justify-content-between flex-column">
               <div className="d-flex justify-content-between mb-3 w-100">
                  <p className="font-pnr white-color font-size-20">Total minters</p>
                  {/* info icon */}
                  <OverlayTrigger placement="top" overlay={<Tooltip>Total number of active MinteR devices</Tooltip>}>
                     <Info className="cursor-pointer" />
                  </OverlayTrigger>
               </div>
               <div className="d-flex flex-column justify-content-center align-items-center">
                  {/* main icon */}
                  <HotspotIcon className="card-icons" />
                  {loadingMintersNumber ? (
                     <ThemeLoader />
                  ) : (
                     <p className="font-pnsb mb-0 font-size-40 white-color">{poolsNumber}</p>
                  )}
               </div>
            </div>
         </Col>
      </Row>
   );
};

export default FirstRow;
