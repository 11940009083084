import React, { useEffect, useState } from "react";
import "./Security.scss";
// import ProfileCard from "../../Components/ProfileCard/ProfileCard";
import EmailCard from "./SecurityComponents/EmailCard";
import PasswordCard from "./SecurityComponents/PasswordCard";
import TwoFACard from "./SecurityComponents/TwoFACard";
import { Helmet } from "react-helmet";
import axios from "axios";
import KYC from "./SecurityComponents/KYC";
import { useLocation } from "react-router-dom";

const Security = () => {

   const [personalEmail, setPersonalEmail] = useState([]);

   let { state } = useLocation();

   useEffect(() => {

      // *********
      // TODO: Get user email from context
      // *********
      const fetchPersonalInfo = async () => {
          try {
              const response = await axios.get(
                  process.env.REACT_APP_BACKEND_URL + "/me",
                  {
                      headers: {
                          Authorization: localStorage.getItem("xiden-pool-session")
                      }
                  }
              );

              if (response.status === 200) {
                 setPersonalEmail(response.data.email)
                 console.log(response.data.email)
                  // setDevices(response.data.length > 0 ? response.data.reverse() : []);
              }
          } catch (err) {
              console.log(err);
          }
      };

      fetchPersonalInfo();
  }, []);


   return (
      <>
         <Helmet>
            <title>Xiden Pools | Security Profile</title>
         </Helmet>

         <div className="security-page min-page-height text-white pb-5">
            <div className="container-main">
               <div className="security-container">
                  {/* <ProfileCard /> */}
                  <EmailCard email = {{personalEmail}}/>
                  <PasswordCard email = {{personalEmail}}/>
                  <div state={"sal"} id="sal" className="row w-100 m-auto">
                  <TwoFACard />
                  <KYC/>
                
                  </div>
               </div>
            </div>
         </div>
      </>
   );
};

export default Security;
