import React, { useState, useEffect } from 'react';
import './Enable2FA.scss';
// import Active2FA from "./Active2FA/Active2FA";
// import TwoFASetup from "./TwoFASetup/TwoFASetup";
// import axios from "axios";
import MainSetup from './TwoFASetup/MainSetup';
import AppSetup from './TwoFASetup/AppSetup';
import SmsSetup from './TwoFASetup/SmsSetup';
import Confirmation2FA from './TwoFASetup/Confirmation2FA';
import Activated2FACard from './Activated2FACard/Activated2FACard';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { routes } from '../../Utils/routes';


const Enable2FA = () => {
  // state
  const [activeStep, setActiveStep] = useState(() => 'step1');
  const [steps, setSteps] = useState(() => {
    return { step_1: true, step_2: false, step_3: false };
  });
  const [activeCard, setActiveCard] = useState(() => {
    return { smsCard: false, appCard: true };
  });
  const [smsConfirmed, setSmsConfirmed] = useState(() => false);
  const [encodedRequest, setEncodedRequest] = useState(() => '');

  // hook
  const navigate = useNavigate();

  // functions
  const handleViewChange = (step) => {
    switch (step) {
      case 'step1':
        setActiveStep(step);
        setSteps({ step_1: true, step_2: false, step_3: false });
        break;
      case 'step2':
        setActiveStep(step);
        setSteps({ step_1: true, step_2: true, step_3: false });
        break;
      case 'step3':
        setActiveStep(step);
        setSteps({ step_1: true, step_2: true, step_3: true });
        break;
      default:
        setActiveStep(step);
        setSteps({ step_1: true, step_2: false, step_3: false });
    }
  };

  const handleActiveCard = (cardID) => {
    switch (cardID) {
      case 'sms':
        setActiveCard({ smsCard: true, appCard: false });
        break;
      case 'app':
        setActiveCard({ smsCard: false, appCard: true });
        break;
      default:
        setActiveCard({ smsCard: false, appCard: false });
    }
  };

  const handleSmsConfirmed = () => setSmsConfirmed(true);
  const handleEncodedRequest = (encodedRequest) => setEncodedRequest(encodedRequest);

  // useEffect(() => {
  //   try {
  //     const fetchData = async () => {
  //       let has2FA = await axios.get(process.env.REACT_APP_EDAIN_URL + '/api/auth/twofactor', {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem('jwt')}`
  //         }
  //       });

  //       if (has2FA.status === 200 && has2FA.data.setting !== 'NONE') {
  //         navigate(routes.overview);
  //         toast.error('You already have 2FA setup', { toastId: '2FA_already_setup' });
  //       }
  //     };

  //     fetchData();
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }, [navigate]);

  let renderer;
  switch (activeStep) {
    case 'step1':
      renderer = (
        <MainSetup
          handleViewChange={handleViewChange}
          activeCard={activeCard}
          handleActiveCard={handleActiveCard}
        />
      );
      break;

    case 'step2':
      if (activeCard.appCard) {
        renderer = <AppSetup handleViewChange={handleViewChange} />;
      }
      if (activeCard.smsCard) {
        renderer = (
          <SmsSetup
            handleViewChange={handleViewChange}
            handleEncodedRequest={handleEncodedRequest}
          />
        );
      }
      break;

    case 'step3':
      if (activeCard.smsCard) {
        if (smsConfirmed) {
          renderer = <Activated2FACard />;
        } else {
          renderer = (
            <Confirmation2FA
              encodedRequest={encodedRequest}
              handleViewChange={handleViewChange}
              handleSmsConfirmed={handleSmsConfirmed}
            />
          );
        }
      }
      if (activeCard.appCard) {
        renderer = <Activated2FACard />;
      }
      break;

    default:
      renderer = (
        <MainSetup
          handleViewChange={handleViewChange}
          activeCard={activeCard}
          handleActiveCard={handleActiveCard}
        />
      );
  }

  return (
    <section id='two-fa-section'>
      <h2 className="font-size-32 font-pnb text-center mb-5">{`Setup Two Factor Authentication (2FA)`}</h2>

      <div className="steps-container mb-5">
        <div className="steps-axis"></div>

        <div className={`step step-1 ${steps.step_1 && 'step-active'}`}></div>
        <div className={`step step-2 ${steps.step_2 && 'step-active'}`}></div>
        <div className={`step step-3 ${steps.step_3 && 'step-active'}`}></div>
      </div>

      {renderer}
    </section>
  );
};

export default Enable2FA;
