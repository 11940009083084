import React from 'react';
import './Activated2FACard.scss';
import { ReactComponent as SuccessIcon } from '../../../Assets/Icons/success.svg';
import { ReactComponent as ArrowLeft } from '../../../Assets/Icons/arrow-left.svg';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../Utils/routes';

const Activated2FACard= ()=> {
  // hooks
  const navigate = useNavigate();

  return (
    <div className="activated-card">
      <h3 className="font-size-32 font-pnb text-center title">Thank you!</h3>

      <SuccessIcon className="mb-4 success-icon" />

      <h3 className="fotn-size-32 font-pnb text-center white-color">
        Your account <br />
        has been secured <br />
        with 2FA
      </h3>

      <button
        className="zixxar-blue-button btn-height-56 font-size-20 font-pnm radius-12 control-2fa-btn m-auto mt-5 w-100"
        onClick={() => navigate(routes.overview)}
      >
        <ArrowLeft className="me-2" />
        Return to dashboard
      </button>
    </div>
  );
};

export default Activated2FACard;
