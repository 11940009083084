import axios from "axios";
import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Moment from "react-moment";

// Assets
import { ReactComponent as Pools } from "../../../../Assets/SVGs/changelog_pools.svg";
import { ReactComponent as Basm } from "../../../../Assets/SVGs/changelog_basm.svg";
import { ReactComponent as Minter } from "../../../../Assets/SVGs/changelog_minter.svg";
import { ReactComponent as Hidr } from "../../../../Assets/SVGs/changelog_hidr.svg";
import { ReactComponent as Impulse } from "../../../../Assets/SVGs/changelog_impulse.svg";

// Styles
import "./Changelog.scss";

const Changelog = () => {
  const [changelog, setChangelog] = useState([]);
  const endpoints = ["pools", "impulse", "minter", "hidr", "basm"];

  /**
   * Get all data from endpoints and save it to state
   */
  const changelogData = () => {
    endpoints.forEach(async (endpoint) => {
      try {
        await axios
          .get(
            `https://visibility.cryptodata.com/updates/custom/xiden/${endpoint}`
          )
          .then((response) => {
            response.data.forEach((data) => {
              setChangelog((prevState) => [...prevState, data]);
            });
          });
      } catch (error) {
        if (error) {
          // Clear state
          setChangelog([]);
        }
      }
    });
  };

  const changelogDevices = (device) => {
    switch (device) {
      case "minter":
        return (
          <div className="font-size-20 font-pnsb device-container-change-log mb-1">
            <Minter className="me-3" />
            Minter
          </div>
        );
      case "impulse":
        return (
          <div className="font-size-20 font-pnsb device-container-change-log mb-1">
            <Impulse className="me-3" />
            IMPulse
          </div>
        );
      case "basm":
        return (
          <div className="font-size-20 font-pnsb device-container-change-log mb-1">
            <Basm className="me-3" />
            BASM
          </div>
        );
      case "pools":
        return (
          <div className="font-size-20 font-pnsb device-container-change-log mb-1">
            <Pools className="me-3" />
            Pools
          </div>
        );
      case "hidr":
        return (
          <div className="font-size-20 font-pnsb device-container-change-log mb-1">
            <Hidr className="me-3" />
            Hidr
          </div>
        );
      default:
        return "";
    }
  };

  useEffect(() => {
    changelogData();
  }, []);

  return (
    <div className="changelog">
      <Container fluid>
        <p className="font-pnb font-size-32 white-color mb-3 mt-5">Changelog</p>

        <div className="main-box p-sm-5 p-3 text-light">
          {changelog.length ? (
            changelog
              // Sort array by date
              .sort((a, b) => {
                return new Date(b.date) - new Date(a.date);
              })
              // Remove all items from array except the first one
              .slice(0, 1)
              .map((log, key) => {
                return (
                  <div key={key} className="log">
                    <div className="d-sm-flex mb-4 align-items-center justify-content-between">
                      <div className="info-log d-sm-flex align-items-center justify-content-between">
                        <p className="white-color mb-sm-0 mb-3 font-size-32 font-pnb version-p me-sm-5 me-0 text-sm-start text-center">
                          Version: {log.version}
                        </p>

                        {changelogDevices(log.device)}
                      </div>
                      <div className="date-container-change-log font-size-20 font-pnsb">
                        <Moment format="MMMM DD, YYYY">{log.date}</Moment>
                      </div>
                    </div>

                    <div className="d-flex align-items-center justify-content-sm-start justify-content-center">
                      {log.type === "automatic" ? (
                        <span class="change-log-updatetype text-center font-size-20 font-pnsb text-capitalize">
                          Automatic Update
                        </span>
                      ) : log.type === "manual" ? (
                        <span class="change-log-updatetype text-center font-size-20 font-pnsb text-capitalize">
                          Manual Update
                        </span>
                      ) : null}
                    </div>

                    <ul className="mt-3 ps-3">
                      {log.updates.map((update, i) => {
                        return (
                          <li className="font-size-18" key={i}>
                            {update.description}
                          </li>
                        );
                      })}
                    </ul>

                    <a
                      className="changelog-loadmore mt-3"
                      target="_blank"
                      rel="noreferrer"
                      href="https://xiden.com/changelog"
                    >
                      Load more
                    </a>
                  </div>
                );
              })
          ) : (
            <p className="font-pnr white-color font-size-20 mb-0">
              No changelogs available
            </p>
          )}
        </div>
      </Container>
    </div>
  );
};

export default Changelog;
