import React, { useState } from "react";
import { Table, Row, Col } from "react-bootstrap";
import Fade from "react-reveal/Fade";
import Moment from "react-moment";
import DeleteMinerModal from "../../../../../Components/DeleteMinerModal/DeleteMinerModal";
import { ReactComponent as BasmIcon } from "../../../../../Assets/SVGs/basm-icon.svg";
import { ReactComponent as HidrIcon } from "../../../../../Assets/SVGs/hidr-icon.svg";
import { ReactComponent as UnknownDevice } from "../../../../../Assets/SVGs/unknown-device.svg";
import { ReactComponent as ImpulseIcon } from "../../../../../Assets/SVGs/impulse-icon.svg";
import { ReactComponent as NftIcon } from "../../../../../Assets/SVGs/nft-mining-icon.svg";
import MinterGuardianIcon from "../../../../../Assets/PNGs/minter-guardian-icon.png";
import { AiOutlineArrowUp } from "react-icons/ai";
import { AiOutlineArrowDown } from "react-icons/ai";
import { useEffect } from "react";
import { routes } from "../../../../../Utils/routes";
import { ReactComponent as Plus } from "../../../../../Assets/SVGs/yellow-plus.svg";
import { useNavigate } from "react-router-dom";
import { ReactComponent as MiningPower } from "../../../../../Assets/SVGs/mining_power.svg";

const MiningDevicesTable = ({ devices, fetchMiningDevices, params }) => {
    const [show, setShow] = useState(false);
    const [activeId, setActiveId] = useState(null);
    const [devicesList, setDevicesList] = useState([]);
    const [activeSortType, setActiveSortType] = useState("");

    const navigate = useNavigate();

    const onShow = (id) => {
        setShow(true);
        setActiveId(id);
    };

    const onHide = () => setShow(false);

    const handleAddMiner = () => {
        navigate(routes.addMiner + `?poolId=${params?.id}`);
    };

    const renderType = (type) => {
        if (type) {
            switch (type) {

                case "phone":
                    return <HidrIcon width="25px" />;

                case "impulse":
                    return <ImpulseIcon width="25px" />;

                case "router":
                    return <img src={MinterGuardianIcon} alt="minter" width="25px" />;

                case "laptop":
                    return <BasmIcon width="25px" />;

                case "nftlaptop":
                    return <><BasmIcon width="25px" /> &nbsp; + &nbsp; <NftIcon /></>;

                case "nftphone":
                    return <><HidrIcon width="25px" /> &nbsp; + &nbsp; <NftIcon /></>;

                case "nftimpulse":
                    return <><ImpulseIcon width="25px" /> &nbsp; + &nbsp; <NftIcon /></>;

                case String(type.match(/(nft\d\d)/g)):
                    return <NftIcon />;

                default:
                    return <UnknownDevice width="25px" />;

            }
        } else {
            return <UnknownDevice width="25px" />;
        }
    }

    const handleSort = (type) => {
        let sortedDevices = [...devices];

        if (type === "power_asc") {
            sortedDevices = devicesList.sort((a, b) => a?.power > b?.power ? -1 : 1)
        }

        if (type === "power_desc") {
            sortedDevices = devicesList.sort((a, b) => a?.power > b?.power ? 1 : -1)
        }

        setActiveSortType(type);
        setDevicesList([...sortedDevices]);
    }

    const handleStatus = (event) => {
        let filteredDevices = [...devices];

        if (event.target.value === "online") {
            filteredDevices = filteredDevices.filter(device => device?.status === "Online");
        }

        if (event.target.value === "offline") {
            filteredDevices = filteredDevices.filter(device => device?.status === "Offline");
        }

        setActiveSortType("");
        setDevicesList([...filteredDevices]);
    }

    useEffect(() => {
        setDevicesList(devices);
    }, [devices]);

    return (
        <>
            <Row className="my-3">
                <Col lg={4}>
                    <div className="d-flex align-items-center flex-wrap gap-3">
                        <p className="font-pnb font-size-32 white-color mb-0">Mining devices</p>
                        <div onClick={handleAddMiner} className="add-miner d-flex align-items-center">
                            <Plus className="me-2" />
                            <p className="font-pnl font-size-18 mb-0">Add Miner</p>
                        </div>
                    </div>
                </Col>
                <Col lg={8} className="d-flex align-items-center justify-content-end mt-3 mt-md-0">
                    <select onChange={handleStatus} type="text" className="pool-input withdraw-input" placeholder="Type here..." style={{ width: "200px" }}>
                        <option value="all">All</option>
                        <option value="online">Status Online</option>
                        <option value="offline">Status Offline</option>
                    </select>
                </Col>
            </Row>
            <Fade>
                <Table responsive hover>
                    <thead>
                        <tr>
                            <th className="font-size-18 font-pnsb">Type</th>
                            <th className="font-size-18 font-pnsb">Name</th>
                            <th className="font-size-18 font-pnsb">Status</th>
                            <th className="font-size-18 font-pnsb">Device</th>
                            <th className="font-size-18 font-pnsb">Assigned to pool</th>
                            <th className="font-size-18 font-pnsb">
                                {activeSortType === "power_asc" ? (
                                    <div onClick={() => handleSort("power_desc")} className="d-flex align-items-center gap-1 cursor-pointer">
                                        <p className="mb-0">Mining power</p>
                                        <AiOutlineArrowDown />
                                    </div>

                                ) : (
                                    <div onClick={() => handleSort("power_asc")} className="d-flex align-items-center gap-1 cursor-pointer">
                                        <p className="mb-0">Mining power</p>
                                        <AiOutlineArrowUp />
                                    </div>
                                )}
                            </th>
                            <th className="font-size-18 font-pnsb">Last hour activity</th>
                            <th className="font-size-18 font-pnsb">Last updated</th>
                            <th className="font-size-18 font-pnbsb">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {devicesList.length > 0 ? (
                            devicesList.map(device => (
                                <tr key={device.device}>
                                    <td className="font-size-18 font-pnl">{renderType(device?.deviceType)}</td>
                                    <td className="font-size-18 font-pnl">{device?.name || "N/A"}</td>
                                    <td className={`font-size-18 font-pnl ${device.status === "Online" ? "green-color" : "red-color"}`}>{device.status}</td>
                                    <td className="font-size-18 font-pnl">{device?.device}</td>
                                    <td className="font-size-18 font-pnl">{params?.id}</td>
                                    <td className="font-size-18 font-pnl">
                                        {
                                            device?.power || device?.power > 0 ?
                                                <>
                                                    <MiningPower />
                                                    {device?.power}
                                                </>
                                                : null
                                        }
                                    </td>
                                    <td className="font-size-18 font-pnl">{device?.counter > 59 ? 60 : device?.counter}/60 checks</td>
                                    <td className="font-size-18 font-pnl">
                                        {device?.lastSeen === 0 ? "N/A" : <Moment format="DD MMM YYYY HH:mm a">{device?.lastSeen}</Moment>}
                                    </td>
                                    <td className="font-size-18 font-pnl" onClick={() => onShow(device?.device)}>
                                        <button disabled={device?.deviceType === "router"} className="buttonRed font-size-18" style={{ height: "30px", maxWidth: "80px" }}>Delete</button>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="8" className="font-size-18 font-pnl">
                                    No devices found
                                </td>
                            </tr>
                        )}
                    </tbody>
                    <DeleteMinerModal
                        show={show}
                        onHide={onHide}
                        id={activeId}
                        params={params}
                        refetch={fetchMiningDevices}
                    />
                </Table>
            </Fade>
        </>
    );
};

export default MiningDevicesTable;
