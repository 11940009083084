import React, { useState, useEffect } from 'react';
import { ReactComponent as ChevronDown } from '../../../../Assets/SVGs/chevron-down.svg';
import Fade from 'react-reveal/Fade';
import { Container } from 'react-bootstrap';
import axios from "axios";

import FirstRow from './MiningGrid/FirstRow';
import SecondRow from './MiningGrid/SecondRow';

const Mining = ({ data }) => {

  const { miningPower } = data;

  const [showContent, setShowContent] = useState(true);

  // stats
  const [aggregatedPools, setAggregatedPools] = useState([]);

  // stats loaders
  const [loadingAggregatedPools, setLoadingAggregatedPools] = useState(true);

  const toggleContent = () => {
    setShowContent(prevContent => !prevContent);
  }

  const fetchPools = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL + "/pools/account",
        {
          headers: {
            Authorization: localStorage.getItem("xiden-pool-session")
          }
        }
      );

      if (response.status === 200) {
        return response.data.length > 0 ? response.data : [];
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const pools = await fetchPools();

      if (pools.length > 0) {
        const poolsData = [];

        for (const pool of pools) {
          try {
            const connectedResponse = await axios.get(
              process.env.REACT_APP_BACKEND_URL + `/pool/connectedDevices/${pool.id}`,
              {
                headers: {
                  Authorization: localStorage.getItem("xiden-pool-session")
                }
              }
            );

            const minerResponse = await axios.get(
              process.env.REACT_APP_BACKEND_URL + `/miners/getMinerStatus/${pool.id}`,
              {
                headers: {
                  Authorization: localStorage.getItem("xiden-pool-session")
                }
              }
            );

            if (connectedResponse.status === 200 && minerResponse.status === 200) {
              const poolData = {
                minersOffline: 0,
                minersOnline: 0,
                connectedDevices: 0,
                power: 0
              };

              if (minerResponse.data) {
                const findMinter = minerResponse.data.minersOnline.find((el) => el.device === pool.minterID);
                if (findMinter) {
                   poolData.isMinterActive = true;
                   poolData.hasStake = pool.hasStake;
                }

                poolData.minersOffline = minerResponse.data.numberOffline || 0;
                poolData.minersOnline = minerResponse.data.numberOnline || 0;

                if (poolData.isMinterActive) {
                  if (minerResponse.data.minersOnline?.length > 0) {
                    poolData.power = minerResponse.data.minersOnline.reduce(
                      (acc, cur) => acc + cur.power,
                      0
                    );
                  }
                } else {
                  poolData.power = 0;
                }
              }

              if (connectedResponse.data && connectedResponse.data.connectedDevices) {
                if (poolData.isMinterActive) {
                  poolData.connectedDevices = (connectedResponse.data.connectedDevices != null)? connectedResponse.data.connectedDevices: 0;
                } else {
                  poolData.connectedDevices = 0;
                }
              }

              poolsData.push(poolData);
            }
          } catch (err) {
            console.log(err);
          }
        }

        if (poolsData.length > 0) {
          const minersOffline = poolsData.reduce((acc, curr) => acc + curr.minersOffline, 0);
          const minersOnline = poolsData.reduce((acc, curr) => acc + curr.minersOnline, 0);
          const connectedDevices = poolsData.reduce((acc, curr) => acc + curr.connectedDevices, 0);
          const power = poolsData.reduce((acc, curr) => acc + curr.power, 0);

          const aggregate = {
            minersOffline,
            minersOnline,
            connectedDevices,
            power,
            myPoolsNumber: pools.length,
            myActivePoolsNumber: poolsData.filter(pool => pool.hasStake && pool.isMinterActive).length
          }

          setLoadingAggregatedPools(false);
          setAggregatedPools(aggregate);
        }
      } else {
        setAggregatedPools({
          minersOffline: 0,
          minersOnline: 0,
          connectedDevices: 0,
          power: 0,
          myPoolsNumber: pools.length,
          myActivePoolsNumber: 0,
        });
        setLoadingAggregatedPools(false);
      }
    }

    fetchData();
  }, []);

  const firstRow = {
    aggregatedPools: aggregatedPools,
    loadingAggregatedPools: loadingAggregatedPools,
  };

  const secondRow = {
    miningPower
  };

  return (
    <div className="mining">
      <Container fluid>
        <div onClick={toggleContent} className="d-flex gap-2 align-items-center mb-3 cursor-pointer">
          <p className="font-pnb font-size-32 white-color mb-0">Mining</p>
          <ChevronDown className={`chevron-icon ${showContent ? 'show' : 'hide'} `} />
        </div>
        {showContent && (
          <Fade>
            <FirstRow details={firstRow} />
            <SecondRow details={secondRow} />
          </Fade>
        )}
      </Container>
    </div>
  );
};

export default Mining;