import React, { useState, useEffect } from "react";
import "./ForgotPassword.scss";
import { AiOutlineEye as EyeOpenIcon, AiOutlineEyeInvisible as EyeCloseIcon } from "react-icons/ai";
import { Helmet } from "react-helmet";
import { pswPattern } from "../../../Utils/regexPatterns";
import { toast } from "react-toastify";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ReactComponent as Info } from "../../../Assets/SVGs/info.svg";
import { useNavigate, useParams } from "react-router-dom";
import { routes } from "../../../Utils/routes";
import axios from "axios";

// state values
const stateValues = {
   password: "",
   repeatPassword: "",
};

const ChangePassword = () => {
   // state
   const [values, setValues] = useState(() => stateValues);
   const [hidePassword, setHidePassword] = useState(() => true);
   const [hideRepeatPassword, setHideRepeatPassword] = useState(() => true);

   const navigate = useNavigate();
   const params = useParams();

   const pswTooltip = (
      <Tooltip>
         <p className="mb-0">Password should contain:</p>
         <ul className="text-start psw-list">
            <li>Minimum 8 characters</li>
            <li>At least one number</li>
            <li>One lowercase letter</li>
            <li>One special character</li>
            <li>One uppercase letter</li>
         </ul>
      </Tooltip>
   );

   // functions
   const togglePasswordView = () => setHidePassword(!hidePassword);
   const toggleRepeatPasswordView = () => setHideRepeatPassword(!hideRepeatPassword);

   const handleValues = event => {
      const target = event.target;
      const { name, value } = target;
      setValues({ ...values, [name]: value });
   };

   const handleChangePassword = async (event) => {
      event.preventDefault();

      // validation
      if (!pswPattern.test(values.password)) {
         toast.error("Please enter a valid password!");
         return;
      }

      if (values.password !== values.repeatPassword) {
         toast.error("Passwords do not match", { toastId: "password-error" });
         return;
      }

      // send request
      try {
         const response = await axios.post(process.env.REACT_APP_BACKEND_URL + `/reset-password`, { token: params.token, password: values.password },
            {
               headers: {
                  Authorization: localStorage.getItem("xiden-pool-session")
               }
            }
         );
         if (response.status === 200) {
            toast.success("Password changed successfully", { toastId: "password-changed" });
            // navigate to login page after 1.5 seconds
            setTimeout(() => navigate(routes.signIn), 1500);
         }
      } catch (err) {
         if (err.response) {
            switch (err.response.status) {
               case 400:
                  toast.error("Invalid token", { toastId: "forgot-error-400" });
                  return;
               default:
                  toast.error("Error changing password", { toastId: "forgot-error-default" });
                  return;
            }
         }
      }
   };

   useEffect(() => {
      const { token } = params;

      if (!token) {
         toast.error("Invalid token");
         navigate(routes.login);
         return;
      }

   }, [params]);

   return (
      <>
         <Helmet>
            <title>Xiden Pools | Forgot password</title>
         </Helmet>

         <div className="container-main text-white">
            <div className="d-flex align-items-center justify-content-center change-password ">
               <form className="main-box d-flex flex-column py-5" onSubmit={handleChangePassword}>
                  <h3 className="text-center font-pnb white-color font-size-24 mb-2">Change password</h3>

                  <div className="d-flex gap-2 align-items-center mb-1">
                     <label htmlFor="password" className="font-pnr font-size-18 white-color">
                        New Password
                     </label>
                     <OverlayTrigger placement="top" overlay={pswTooltip}>
                        <Info className="cursor-pointer psw-icon" />
                     </OverlayTrigger>
                  </div>
                  <div className="w-100 position-relative mb-2">
                     <input
                        value={values.password}
                        autoComplete="off"
                        name="password"
                        className="pool-input w-100"
                        type={hidePassword ? "password" : "text"}
                        placeholder="Enter password"
                        onChange={handleValues}
                     />
                     {hidePassword ? (
                        <EyeOpenIcon className="hide-pwd-icon" onClick={() => togglePasswordView()} />
                     ) : (
                        <EyeCloseIcon className="hide-pwd-icon" onClick={() => togglePasswordView()} />
                     )}
                  </div>

                  <label htmlFor="repeatPassword" className="font-pnr font-size-18 white-color mb-1">
                     Repeat New Password
                  </label>
                  <div className="w-100 position-relative mb-4">
                     <input
                        value={values.repeatPassword}
                        autoComplete="off"
                        name="repeatPassword"
                        className="pool-input w-100"
                        type={hideRepeatPassword ? "password" : "text"}
                        placeholder="Repeat password"
                        onChange={handleValues}
                     />
                     {hideRepeatPassword ? (
                        <EyeOpenIcon className="hide-pwd-icon" onClick={() => toggleRepeatPasswordView()} />
                     ) : (
                        <EyeCloseIcon className="hide-pwd-icon" onClick={() => toggleRepeatPasswordView()} />
                     )}
                  </div>

                  <button className="buttonOutlineBlue font-size-18 m-0 m-auto mt-4" onClick={handleChangePassword}>
                     Change password
                  </button>
               </form>
            </div>
         </div>
      </>
   );
};

export default ChangePassword;
