import React from "react";
import Fade from "react-reveal";
import SetupStepCard from "../../../Components/SetupStepCard/SetupStepCard";
import { steps } from "../../../Utils/setupSteps";
import { routes } from "../../../Utils/routes";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const AddMinerStep1 = ({ handleActiveStep, poolId, minerType, setMinerType, minterId, setMinterId, minerName, setMinerName }) => {
   // hooks
   const navigate = useNavigate();

   // data
   const stepCardData = {
      step: 1,
      title: "Add miner",
      info: "Wait until hashrate is displayed. Workers will be automatically added within a few seconds on the Workers page in your account.",
      checkboxes: true,
      minerType: minerType,
      setMinerType: setMinerType
   };

   // functions
   const handleMinterId = (event) => {
      setMinterId(event.target.value);
   }

   const handleMinerName = (event) => {
      setMinerName(event.target.value);
   }

   const handleNextStep = () => {
      if (!poolId) {
         return toast.error("No pool id", { toastId: "add-miner-pool-id" });
      }

      if (!minterId && minerType === "minter") {
         return toast.error("You didn't type the minter id", { toastId: "add-minter-id" });
      }

      if (!minerName && minerType === "miner") {
         return toast.error("You didn't type the miner name", { toastId: "add-miner-name" });
      }

      if ((minerName.length < 3 || minerName.length > 20) && minerType === "miner") {
         return toast.error("Miner name must be between 3 and 20 characters", { toastId: "add-miner-name-length" });
      }

      handleActiveStep(steps.step_2);
   };

   return (
      <Fade>
         <div className="w-100 d-flex flex-column">
            <SetupStepCard data={stepCardData} />

            <div className="setup-card m-auto d-flex flex-column">
               <h6 className="font-pnb font-size-20 mb-4">Miner configuration</h6>
               <label className="font-size-18 font-pnb mb-2">Pool Id: {poolId}</label>

               {minerType === "minter" ? (
                  <>
                     <label className="font-size-18 font-pnb mb-2 mt-3">Minter Id:</label>
                     <input
                        value={minterId}
                        type="text"
                        className="pool-input"
                        placeholder="Type here..."
                        onChange={handleMinterId}
                     />
                  </>
               ) : (
                  <>
                     <label className="font-size-18 font-pnb mb-2 mt-3">Miner name:</label>
                     <input
                        value={minerName}
                        type="text"
                        className="pool-input"
                        placeholder="Type here..."
                        onChange={handleMinerName}
                     />
                  </>
               )}

               <div className="setup-buttons-container">
                  <button className="setup-outline-btn font-size-20 font-pnm" onClick={() => navigate(routes.pool)}>
                     Go back
                  </button>

                  <button className="setup-fill-btn font-size-20 font-pnm" onClick={() => handleNextStep()}>
                     Next
                  </button>
               </div>
            </div>
         </div>
      </Fade>
   );
};

export default AddMinerStep1;
