import React from "react";
import "./OnboardingNavigation.scss";
import { Navbar, Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { ReactComponent as Logo } from "../../Assets/SVGs/xiden-logo.svg";
import { ReactComponent as WorkersIcon } from "../../Assets/SVGs/workers-icon.svg";
import { ReactComponent as MenuIcon } from "../../Assets/SVGs/dots.svg";
import { routes } from "../../Utils/routes";

const OnboardingNavigation = () => {
  return (
    <div className="p-4">
      <Navbar className="navigation-onboarding" expand="lg">
        <div className="container">
          <Navbar.Brand>
            <Logo className="logo" />
          </Navbar.Brand>
          <Navbar.Toggle className="toggle" aria-controls="basic-navbar-nav">
            <MenuIcon color="#22aaff" size={50} height="40px" />
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <NavLink className="route-link" to={routes.signIn}>
                <div className="d-flex align-items-center">
                  <WorkersIcon className="me-2" />
                  <p className="mb-0 font-size-20 font-pnr white-color">Sign In</p>
                </div>
                <div className="highlight-underline"></div>
              </NavLink>
              <NavLink className="route-link" to={routes.signUp}>
                <div className="d-flex align-items-center">
                  <WorkersIcon className="me-2" />
                  <p className="mb-0 font-size-20 font-pnr white-color">Sign Up</p>
                </div>
                <div className="highlight-underline"></div>
              </NavLink>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </div>
  );
};

export default OnboardingNavigation;
