import React, { useState, useEffect } from "react";
import "../AddMiner.scss";
import Fade from "react-reveal";
import SetupStepCard from "../../../Components/SetupStepCard/SetupStepCard";
import { steps } from "../../../Utils/setupSteps";
import axios from "axios";
import { routes } from "../../../Utils/routes";
import qs from "qs";
import { toast } from "react-toastify";
import Countdown from "react-countdown";
import { useNavigate } from "react-router-dom";

const AddMinerStep2 = ({ handleActiveStep, poolId, minerType, minterId, minerName }) => {
  const [mnemonic, setMnemonic] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const navigate = useNavigate();

  const handleAddMinter = async (es) => {
    const toSend = {
      poolID: poolId,
      minterID: minterId,
    };

    try {
      const checkReq = await axios.post(
        process.env.REACT_APP_BACKEND_URL + `/minter/add-to-pool`,
        qs.stringify(toSend),
        {
          headers: {
            Authorization: localStorage.getItem("xiden-pool-session"),
          },
        }
      );

      if (checkReq.status === 200) {
        es.close();
        toast.success("Minter added successfully to pool!");
        // in 3 seconds redirect to routes.overview
        setTimeout(() => {
          navigate(routes.pool + "/" + poolId);
        }, 1500);
      }
    } catch (err) {
      console.log(err);
      if (err.response) {
        switch (err.response.status) {
          case 400:
            toast.error("Minter already has a pool.", { toastId: "pool-already-exists" });
            return false;
          default:
            toast.error("Something is temporarily wrong.", { toastId: "pool-creation-error" });
            return false;
        }
      }
    }
  };

  // data
  const stepCardData = {
    step: 2,
    title: "Pair Miner",
    info: "Wait until hashrate is displayed. Workers will be automatically added within a few seconds on the Workers page in your account.",
  };

  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return (
        <div className="setup-buttons-container font-size-54 font-pnb d-flex flex-column align-items-center">
          <span className="font-size-24 font-pnb mb-5">Time expired</span>
        </div>
      );
    } else {
      // Render a countdown
      return (
        <>
          <div className="setup-buttons-container d-flex flex-column align-items-center">
            <span className="mb-5 font-size-54 font-pnb">
              {minutes.toString().padStart(2, "0")}:{seconds.toString().padStart(2, "0")}
            </span>
          </div>
          <div className="w-100 d-flex flex-wrap">
            {mnemonic.map((word, index) => (
              <div key={index} className="word-tag font-size-14 font-pnr">
                {index + 1}. {word}
              </div>
            ))}
          </div>
        </>
      );
    }
  };

  useEffect(() => {
    const startStream = async () => {
      let eventURL;

      if (minerType === "minter") {
        eventURL = process.env.REACT_APP_BACKEND_URL + `/streamMinterAsMiner/${minterId}`;
      } else {
        eventURL = process.env.REACT_APP_BACKEND_URL + `/streamMiner/${poolId}`;
      }

      let es = new EventSource(eventURL);

      es.onerror = (e) => {
        console.log("err", e);
      };

      es.onmessage = async (event) => {
        const data = JSON.parse(event.data);

        if (data.confirmed === "yes") {
          if (minerType === "minter") {
            handleAddMinter(es);
          } else {
            es.close();
            toast.success("Miner added successfully.");
            setTimeout(() => {
              navigate(routes.pool + "/" + poolId);
            }, 1500);
          }
        }
      };
    };

    const getMinerMnemonic = async () => {
      const toSend = {
        poolID: poolId,
        minerName: minerName,
      };

      try {
        const checkReq = await axios.put(
          process.env.REACT_APP_BACKEND_URL + `/miner`,
          qs.stringify(toSend),
          {
            headers: {
              Authorization: localStorage.getItem("xiden-pool-session"),
            },
          }
        );

        if (checkReq.status === 200) {
          setMnemonic(checkReq.data.split(" "));
          await startStream();
        }
      } catch (err) {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              setError("Pool id is not provided.");
              return;
            default:
              setError("Something is temporarily wrong. Please try again later.");
              return;
          }
        }
      } finally {
        setLoading(false);
      }
    };

    const getMinterMnemonic = async () => {
      try {
        const checkReq = await axios.get(
          process.env.REACT_APP_BACKEND_URL + `/minter/pair/${minterId}`,
          {
            headers: {
              Authorization: localStorage.getItem("xiden-pool-session"),
            },
          }
        );

        if (checkReq.status === 200) {
          setMnemonic(checkReq.data.split(" "));
          await startStream();
        }
      } catch (err) {
        if (err.response) {
          switch (err.response.status) {
            case 400:
              setError("Minter not initialized.");
              return;
            case 404:
              setError("Minter not found. Please check the Minter Id.");
              return;
            default:
              setError("Something is temporarily wrong. Please try again later.");
              return;
          }
        }
      } finally {
        setLoading(false);
      }
    };

    const fetchData = async () => {
      if (minerType === "minter") {
        await getMinterMnemonic();
      } else {
        await getMinerMnemonic();
      }
    };

    fetchData();
  }, [poolId]);

  let toRender;

  if (loading) {
    toRender = (
      <div className="d-flex justify-content-center align-items-center">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only"></span>
        </div>
      </div>
    );
  } else {
    if (mnemonic.length > 0) {
      toRender = <Countdown date={Date.now() + 600000} renderer={renderer} />;
    } else {
      toRender = <p className="font-pnr pt-4">{error}</p>;
    }
  }

  return (
    <Fade>
      <div className="w-100 d-flex flex-column">
        <SetupStepCard data={stepCardData} />

        <div className="setup-card m-auto d-flex flex-column">
          <h6 className="font-pnb font-size-20 mb-0">Pool configuration</h6>

          {toRender}

          <div className="setup-buttons-container">
            <button
              className="setup-outline-btn font-size-20 font-pnm me-0"
              onClick={() => handleActiveStep(steps.step_1)}
            >
              Go back
            </button>

            {/* <button
                     className="setup-fill-btn font-size-20 font-pnm"
                     onClick={() => handleActiveStep(steps.step_3)}
                     disabled={error}
                  >
                     Next
                  </button> */}
          </div>
        </div>
      </div>
    </Fade>
  );
};

export default AddMinerStep2;
