import React from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { PoolsContext } from "../../../../../Contexts/PoolsContext";
import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ReactComponent as Info } from "../../../../../Assets/SVGs/info.svg";
import { ReactComponent as ActiveInfo } from "../../../../../Assets/SVGs/active-info.svg";
import { ReactComponent as StakingSupply } from "../../../../../Assets/SVGs/metro-stack.svg";
import { ReactComponent as AddPool } from "../../../../../Assets/SVGs/add-pool.svg";
import { routes } from "../../../../../Utils/routes";
import { ReactComponent as XdenIcon } from "../../../../../Assets/SVGs/xden_icon.svg";

import "../../../Pool.scss";
import ThemeLoader from "../../../../../Components/Loader/Loader";

const FirstRow = ({ details }) => {
   const { xdenStake, loadingXdenStake, latestRewards } = details;

   const navigate = useNavigate();

   const poolsData = useContext(PoolsContext)

   let miningPower = 0, boosterPower = 0, activeMiners = 0, inactiveMiners = 0, devicesConected = 0;
   poolsData.forEach((data) => {
      miningPower += data.poolPower
      boosterPower += data.poolBonus
      activeMiners += data.activeMiners
      inactiveMiners += data.inactiveMiners
      devicesConected += data.deviceConnected
   })

   const handleCreatePool = () => {
      navigate(routes.createPool);
   }

   return (
      <Row xs={2} md={2} lg={3} xl={6} className="pools-overview-first-row">
         <Col className="mb-4">
            <div className="main-box height-252">
               <div onClick={handleCreatePool} className="d-flex h-100 flex-column justify-content-center align-items-center cursor-pointer">
                  <AddPool className="card-icons" />
                  <p className="font-pnb mb-0 font-size-28 white-color text-center">Create new Pool</p>
               </div>
            </div>
         </Col>

         <Col className="mb-4">
            <div className="main-active-box height-252 d-flex flex-column">
               <div className="d-flex justify-content-between mb-3">
                  <p className="font-pnr white-color font-size-20">Pools</p>
                  {/* info icon */}
                  <OverlayTrigger placement="top" overlay={<Tooltip>Total number of pools created</Tooltip>}>
                     <ActiveInfo className="cursor-pointer info-icon" />
                  </OverlayTrigger>
               </div>
               <div className="d-flex inherit flex-column justify-content-center align-items-center">
                  <p className="font-pnsb mb-0 font-size-40 white-color text-center">{poolsData.length}</p>
                  <p className="font-pnl mb-0 font-size-36 grey-text text-center">pools</p>
               </div>
            </div>
         </Col>

         <Col className="mb-4 d-flex flex-column justify-content-between gap-4">
            <div className="main-box height-114 d-flex flex-column justify-content-between align-items-start">
               <div className="d-flex w-100 justify-content-between align-items-center">
                  <p className="font-pnr white-color font-size-20 mb-0 card-title">Mining power</p>
                  {/* info icon */}
                  <OverlayTrigger placement="top" overlay={<Tooltip>Total of mining cycles from active miner devices</Tooltip>}>
                     <Info className="cursor-pointer info-icon" />
                  </OverlayTrigger>
               </div>
               <div className="d-flex flex-column justify-content-center align-items-center">
                  <p className="font-pnsb mb-0 font-size-32 white-color text-center card-label">{miningPower.toFixed(2)} cycles</p>
               </div>
            </div>
            <div className="main-box height-114 d-flex flex-column justify-content-between align-items-start">
               <div className="d-flex w-100 justify-content-between align-items-center">
                  <p className="font-pnr white-color font-size-20 mb-0 card-title">Booster power</p>
                  {/* info icon */}
                  <OverlayTrigger placement="top" overlay={<Tooltip>Mining cycles granted by booster devices</Tooltip>}>
                     <Info className="cursor-pointer info-icon" />
                  </OverlayTrigger>
               </div>
               <div className="d-flex flex-column justify-content-center align-items-center">
                  <p className="font-pnsb mb-0 font-size-32 white-color text-center card-label">{boosterPower.toFixed(2)} cycles</p>
               </div>
            </div>
         </Col>

         <Col className="mb-4">
            <div className="main-box height-252 d-flex justify-content-between flex-column">
               <div className="d-flex justify-content-between mb-3">
                  <p className="mb-0 font-pnr white-color font-size-20 card-title">Staking supply</p>
                  {/* info icon */}
                  <OverlayTrigger placement="top" overlay={<Tooltip>Amount of XDEN currently in stake</Tooltip>}>
                     <Info className="cursor-pointer info-icon" />
                  </OverlayTrigger>
               </div>
               <div className="d-flex flex-column justify-content-center align-items-center">
                  {/* main icon */}
                  <StakingSupply className="card-icons supply-icon" />
                  {loadingXdenStake ? (
                     <ThemeLoader />
                  ) : (
                     <p className="font-pnsb d-flex align-items-center mb-0 font-size-32 white-color text-center card-label">{xdenStake} <XdenIcon /></p>
                  )}
               </div>
            </div>
         </Col>

         <Col className="mb-4 d-flex flex-column justify-content-between gap-4">
            <div className="main-box height-114 d-flex flex-column justify-content-between align-items-start">
               <div className="d-flex w-100 justify-content-between align-items-center">
                  <p className="font-pnr white-color font-size-20 mb-0 card-title">Active miners</p>
                  {/* info icon */}
                  <OverlayTrigger placement="top" overlay={<Tooltip>Number of active miners</Tooltip>}>
                     <Info className="cursor-pointer info-icon" />
                  </OverlayTrigger>
               </div>
               <div className="d-flex flex-column justify-content-center align-items-center">
                  <p className="font-pnsb mb-0 font-size-32 white-color text-center card-label">{activeMiners} miners</p>
               </div>
            </div>
            <div className="main-box height-114 d-flex flex-column justify-content-between align-items-start">
               <div className="d-flex w-100 align-items-center justify-content-between">
                  <p className="font-pnr white-color font-size-20 mb-0 card-title">Inactive miners</p>
                  {/* info icon */}
                  <OverlayTrigger placement="top" overlay={<Tooltip>Number of inactive miners</Tooltip>}>
                     <Info className="cursor-pointer info-icon" />
                  </OverlayTrigger>
               </div>
               <div className="d-flex flex-column justify-content-center align-items-center">
                  <p className="font-pnsb mb-0 font-size-32 white-color text-center card-label">{inactiveMiners} miners</p>
               </div>
            </div>
         </Col>

         <Col className="mb-4 d-flex flex-column justify-content-between gap-4">
            <div className="main-box height-114 d-flex flex-column justify-content-between align-items-start">
               <div className="d-flex w-100 justify-content-between align-items-center">
                  <p className="font-pnr white-color font-size-20 mb-0 card-title">Devices connected</p>
                  {/* info icon */}
                  <OverlayTrigger placement="top" overlay={<Tooltip>Number of devices connected as boosters</Tooltip>}>
                     <Info className="cursor-pointer info-icon" />
                  </OverlayTrigger>
               </div>
               <div className="d-flex flex-column justify-content-center align-items-center">
                  <p className="font-pnsb mb-0 font-size-32 white-color text-center card-label">{devicesConected} devices</p>
               </div>
            </div>
            <div className="main-box height-114 d-flex flex-column justify-content-between align-items-start">
               <div className="d-flex w-100 justify-content-between align-items-center">
                  <p className="font-pnr white-color font-size-20 mb-0 card-title">24H revenue</p>
                  {/* info icon */}
                  <OverlayTrigger placement="top" overlay={<Tooltip>Daily revenue based on the number of active miners and boosters</Tooltip>}>
                     <Info className="cursor-pointer info-icon" />
                  </OverlayTrigger>
               </div>
               <div className="d-flex flex-column justify-content-center align-items-center">
                  <p className="font-pnsb d-flex align-items-center mb-0 font-size-32 white-color text-center card-label">{latestRewards?.toFixed(4)} <XdenIcon /></p>
               </div>
            </div>
         </Col>
      </Row>
   );
};

export default FirstRow;
