import axios from "axios";
import React from "react";
import { Container } from "react-bootstrap";

import MyWithdrawalsHeader from "./MyWithdrawalsHeader/MyWithdrawalsHeader";
import MyWithdrawalsTable from "./MyWithdrawalsTable/MyWithdrawalstTable";

class MyWithdrawals extends React.Component{

   state = {
      withdrawalsList : []
   }

   componentDidMount = async () => {

      let withdrawalRespose;
      try {
         withdrawalRespose = await axios.get(
            process.env.REACT_APP_BACKEND_URL + `/withdrawal/list`,
            {
               headers: {
                  Authorization: localStorage.getItem("xiden-pool-session")
               }
            }
         );
         // console.log(withdrawalRespose.data)

         this.setState({
            withdrawalsList : withdrawalRespose.data
         })
      }
      catch(e){

         console.log(e);
      }
      
   }

   render(){
         return (
            <Container fluid>
               <MyWithdrawalsHeader />
               <MyWithdrawalsTable withdrawalsList = {this.state.withdrawalsList} />
            </Container>
         );
      }
}
   // state
   // const [activeFilter, setActiveFilter] = useState(() => {
   //    return { all: true, public: false, private: false };
   // });

   

export default MyWithdrawals;
