import React from "react";
import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import Chart from "react-apexcharts";
import { ReactComponent as Info } from "../../../../../Assets/SVGs/info.svg";
import ThemeChart from "../../../../../Components/Chart/Chart";
import { ReactComponent as XdenIcon } from "../../../../../Assets/SVGs/xden_icon.svg";
import ThemeLoader from "../../../../../Components/Loader/Loader";

const SecondRow = ({ details }) => {
  const {
    onlineMiners,
    offlineMiners,
    connectedDevices,
    privateRewardYesterday,
    publicRewardYesterday,
    loadingRewards,
    loadingMiners,
    loadingConnectedDevices,
  } = details;

  const poolIdFromWindow = window.location.pathname.replace("/pool/", "");
  const revenueTp = <Tooltip></Tooltip>;

  return (
    <Row>
      <Col lg={4} xs={6} className="mb-4">
        <div className="main-box height-252">
          <div className="d-flex justify-content-between">
            <p className="font-pnr white-color font-size-20">Active miners</p>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center">
            {loadingMiners ? (
              <ThemeLoader />
            ) : (
              <Chart
                type="radialBar"
                series={[(onlineMiners / (onlineMiners + offlineMiners)) * 100]}
                options={{
                  plotOptions: {
                    radialBar: {
                      hollow: {
                        size: "60%",
                      },
                      track: {
                        background: "#12401E",
                      },

                      dataLabels: {
                        showOn: "always",
                        name: {
                          offsetY: 10,
                          show: true,
                          color: "#FFFFFF",
                          fontSize: "30px",
                        },
                        value: {
                          color: "#111",
                          fontSize: "30px",
                          show: false,
                        },
                      },
                    },
                  },
                  fill: {
                    colors: "#58FF89",
                  },
                  stroke: {
                    lineCap: "round",
                  },
                  labels: [`${onlineMiners}/${onlineMiners + offlineMiners}`],
                }}
                height="200"
              />
            )}
          </div>
        </div>
      </Col>

      <Col lg={4} xs={6} className="mb-4">
        <div className="main-box height-252">
          <div className="d-flex justify-content-between h-25">
            <p className="font-pnr white-color font-size-20">
              Devices connected
            </p>
            {/* info icon */}
            <OverlayTrigger placement="top" overlay={<Tooltip>Number of devices connected as boosters</Tooltip>}>
              <Info className="cursor-pointer" />
            </OverlayTrigger>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center h-75">
            {loadingConnectedDevices ? (
              <ThemeLoader />
            ) : (
              <p className="font-pnsb mb-3 font-size-32 white-color text-center card-label">
                {connectedDevices} devices
              </p>
            )}
          </div>
        </div>
      </Col>

      <Col
        lg={4}
        className="mb-4 d-flex flex-column justify-content-between gap-4"
      >
        <div className="main-box height-114 d-flex justify-content-between flex-column">
          <div className="d-flex justify-content-between">
            <p className="font-pnr white-color font-size-20 mb-0 card-title">
              24h public reward
            </p>
            {/* info icon */}
            <OverlayTrigger placement="top" overlay={<Tooltip>Daily reward in the Public Difficulty</Tooltip>}>
              <Info className="cursor-pointer info-icon" />
            </OverlayTrigger>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-start">
            {loadingRewards ? (
              <ThemeLoader />
            ) : (
              <p className="font-pnsb d-flex align-items-center mb-0 font-size-32 white-color text-center card-label">
                {Number(publicRewardYesterday)?.toFixed(2)} <XdenIcon />
              </p>
            )}
          </div>
        </div>
        <div className="main-box height-114 d-flex justify-content-between flex-column">
          <div className="d-flex justify-content-between">
            <p className="font-pnr white-color font-size-20 mb-0 card-title">
              24h private reward
            </p>
            {/* info icon */}
            <OverlayTrigger placement="top" overlay={<Tooltip>Daily reward in the Private Difficulty</Tooltip>}>
              <Info className="cursor-pointer info-icon" />
            </OverlayTrigger>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-start">
            {loadingRewards ? (
              <ThemeLoader />
            ) : (
              <p className="font-pnsb d-flex align-items-center mb-0 font-size-32 white-color text-center card-label">
                {Number(privateRewardYesterday)?.toFixed(2)} <XdenIcon />
              </p>
            )}
          </div>
        </div>
      </Col>

      <Col lg={12} className="mb-4">
        <div className="main-box height-252 position-relative d-flex align-items-end">

          <ThemeChart
            uri={`${process.env.REACT_APP_BACKEND_URL}/mining-stats-new/${poolIdFromWindow}/`}
            measurement="power"
            singleData={false}
            excludeDates={[]}
            defaultDate="24hours"
            single={true}
            chartTitle="Mining Power" />

        </div>
      </Col>
    </Row>
  );
};

export default SecondRow;
