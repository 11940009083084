import React, { useState } from "react";
import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ReactComponent as Info } from "../../../../../Assets/SVGs/info.svg";
import ThemeChart from "../../../../../Components/Chart/Chart";
import ChartOverlay from "../../../../../Components/ChartOverlay/ChartOverlay";
import ThemeLoader from "../../../../../Components/Loader/Loader";

const SecondRow = ({ details }) => {

   // props
   const { hashPower, loadingHashPower } = details;

   return (
      <Row lg={2} md={1} sm={1} xs={1}>
         <Col lg={3} className="mb-4">
            <div className="main-box height-252 d-flex w-100 flex-column">
               <div className="d-flex justify-content-between mb-3">
                  <p className="font-pnr white-color font-size-20">Total mining power</p>
                  {/* info icon */}
                  <OverlayTrigger placement="top" overlay={<Tooltip>Total number of validating power generated in the whole network</Tooltip>}>
                     <Info className="cursor-pointer" />
                  </OverlayTrigger>
               </div>
               <div
               className="d-flex inherit flex-column justify-content-center align-items-center">
                  {loadingHashPower ? (
                     <ThemeLoader />
                  ) : (
                     <>
                        <p className="font-pnsb mb-0 font-size-40 white-color">{hashPower.toFixed(2)} cycles</p>
                        <p className="pnr font-size-12 grey-text mb-0 text-center">
                           Last updated: {new Date(Date.now()).toLocaleDateString() + " / " + new Date(Date.now()).toLocaleTimeString()}
                        </p>
                     </>
                  )}
               </div>
            </div>
         </Col>
         <Col lg={9} className="mb-4">
            <div className="main-box height-252 position-relative d-flex align-items-end">

               <ThemeChart
               uri={`${process.env.REACT_APP_BACKEND_URL}/mining-stats/allpower/`}
               measurement={["power", "bonus"]}
               singleData={true}
               excludeDates={["24hours"]}
               defaultDate="7days"
               single={true}
               label="Power"
               chartTitle="Total mining power" />

            </div>
         </Col>
      </Row>
   );
};

export default SecondRow;
