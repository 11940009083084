import React, { useState, useEffect } from "react";
import "./ForgotPassword.scss";
import { BsArrowLeftShort as ArrowLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../Utils/routes";
import { Helmet } from "react-helmet";
import axios from "axios";
import { toast } from "react-toastify";

const SendEmail = () => {
   // state
   const [emailValue, setEmailValue] = useState("");
   const [lock, setLock] = useState(false);

   // hooks
   const navigate = useNavigate();

   // functions
   const handleEmailValue = event => setEmailValue(event.target.value);

   const handleSend = async (event) => {
      event.preventDefault();

      // send request
      try {
         const response = await axios.post(process.env.REACT_APP_BACKEND_URL + `/request-reset-password`, { email: emailValue },
            {
               headers: {
                  Authorization: localStorage.getItem("xiden-pool-session")
               }
            }
         );
         if (response.status === 200) {
            toast.success("Email sent successfully", { toastId: "emailSent" });
            setLock(true);
         }
      } catch (err) {
         if (err.response) {
            switch (err.response.status) {
               case 400:
                  toast.error("Invalid email", { toastId: "forgot-error-400" });
                  return;
               default:
                  toast.error("Error sending email", { toastId: "forgot-error-default" });
                  return;
            }
         }
      }
   };

   useEffect(() => {
      if (lock) setTimeout(() => setLock(false), 30000);
   }, [lock]);

   return (
      <>
         <Helmet>
            <title>Xiden Pools | Forgot password</title>
         </Helmet>

         <div className="container-main text-white">
            <div className="d-flex align-items-center justify-content-center send-email">
               <form className="main-box d-flex flex-column py-5" onSubmit={handleSend}>
                  <div
                     className="d-flex align-items-center cursor-pointer mb-5"
                     onClick={() => navigate(routes.signIn)}
                  >
                     <ArrowLeft className="me-1" color="#ffffff" size={20} />
                     <span className="font-size-15 font-pnr">Go back</span>
                  </div>

                  <h3 className="text-center font-pnb white-color font-size-24 mb-2">Change password</h3>

                  <label htmlFor="email" className="font-pnr font-size-18 white-color mb-1">
                     E-mail
                  </label>
                  <input
                     value={emailValue}
                     autoComplete="off"
                     name="email"
                     className="pool-input mb-2"
                     type="email"
                     placeholder="Enter e-mail"
                     onChange={handleEmailValue}
                  />

                  <button disabled={lock} className="buttonOutlineBlue font-size-18 m-0 m-auto mt-4" onClick={handleSend}>
                     {"Send e-mail"}
                  </button>
                  {lock && (
                     <p className="font-pnr text-center mt-3">Resend email available in 30s</p>
                  )}
               </form>
            </div>
         </div>
      </>
   );
};

export default SendEmail;
