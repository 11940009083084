import React from "react";
import { Button, Modal } from "react-bootstrap";

export default function UnstakeModal({
  show,
  handleClose,
  unstake,
  privateRewards,
}) {
  return (
    <Modal className="white-color" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Unstake</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        When you click the 'Unstake' button, all rewards from a private
        withdrawal will be sent to your connected MetaMask wallet. <br></br>{" "}
        <br></br> Any unmined stake Xiden will also be transferred to your
        wallet. Please note that this pool will become inactive for mining.
        <br></br> <br></br>{" "}
        <span
          style={{
            color: "red",
          }}
        >
          🚨 The next unstake can be done after 6 months of staking.
        </span>
        <br></br> <br></br> Are you sure you want to unstake? <br></br> This
        action cannot be undone.
        <br></br> <br></br>
        Amount to unstake: <strong>{privateRewards.toFixed(2)} XDEN</strong>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            handleClose();
            unstake();
          }}
        >
          Unstake
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
