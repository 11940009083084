import React from "react";
import "./Homepage.scss";
import MiningBanner from "./Components/MiningBanner/MiningBanner";
import StatisticsGrid from "./Components/StatisticsGrid/StatisticsGrid";
import { Helmet } from "react-helmet";
import Changelog from "./Components/Changelog/Changelog";
import Announcement from "./Components/Announcement/Announcement";

const Homepage = () => {
  return (
    <>
      <Helmet>
        <title>Xiden Pools</title>
      </Helmet>
      <Announcement
        text={`Please be informed that we are currently working on updating the system and there may appear various bugs and functionality issues.`}
      />

      <div className="py-4 homepage">
        <div className="container-main">
          {/* <Announcement /> */}
          <MiningBanner />
          <Changelog />
          <StatisticsGrid />
        </div>
      </div>
    </>
  );
};

export default Homepage;
