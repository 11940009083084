import React from "react";
import { Helmet } from "react-helmet";

import MiningDevicesTable from "./Components/MiningDevicesTable/MiningDevicesTable";

const Workers = () => {
   return (
      <>
         <Helmet>
            <title>Xiden Pools | Workers</title>
         </Helmet>

         <div className="py-4 overview">
            <div className="container-main">
               <MiningDevicesTable />
            </div>
         </div>
      </>
   );
};

export default Workers;
