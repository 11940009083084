import React from "react";
import "./MiningBanner.scss";
import { Row, Col, Container } from "react-bootstrap";
import BannerImg from "../../../../Assets/PNGs/mining-banner.png";
// import MiningIcon from "../../../../Assets/PNGs/mining-icon.png";
import { ReactComponent as Logo } from "../../../../Assets/SVGs/xiden-logo.svg";
import Fade from "react-reveal";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../../Utils/routes";

const MiningBanner = () => {
   const navigate = useNavigate();

   return (
      <Container fluid>
         <Fade>
            <div className="mining-banner mb-3">
               <div className="main-box p-5">
                  <Row>
                     <Col md={6} sm={12} className="d-flex flex-column justify-content-center pool-col">
                        <Logo className="mb-1 mining-icon" />
                        {/* <img src={MiningIcon} className="mb-1 mining-icon" alt="mining-icon" /> */}
                        {/* <p className="font-size-64 white-color mb-1 font-pnr">CDPool</p> */}
                        <p className="font-size-24 white-color mb-4">Enjoy the new mining experience</p>
                        <button onClick={() => navigate(routes.overview)} className="buttonBlue me-0">Start now</button>
                     </Col>
                     <Col
                        md={6}
                        sm={12}
                        className="mt-5 mt-md-0 banner-col d-flex align-items-center justify-content-end"
                     >
                        <img src={BannerImg} className="mining-banner-img mx-auto mx-md-0" alt="mining-banner" />
                     </Col>
                  </Row>
               </div>
            </div>
         </Fade>
      </Container>
   );
};

export default MiningBanner;
