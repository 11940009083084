import React, { useState } from "react";
import "./AddRouter.scss";
import { Helmet } from "react-helmet";

const AddRouter = () => {
   // state
   const [routerValue, setRouterValue] = useState(() => "");

   // functions
   const handleRouterValue = event => setRouterValue(event.target.value);

   const handleAddRouter = event => {
      event.preventDefault();
      setRouterValue("");
   };

   return (
      <>
         <Helmet>
            <title>Xiden Pools | Add Router</title>
         </Helmet>

         <div className="position-relative py-4 layout add-router-page">
            <div className="container-main">
               <form className="text-white form-card">
                  <label className="font-size-20 font-pnr mb-2">Add a router</label>

                  <input
                     value={routerValue}
                     type="text"
                     className="pool-input"
                     placeholder="Type here..."
                     onChange={event => handleRouterValue(event)}
                  />

                  <button className="buttonBlue mt-4 m-auto" onClick={event => handleAddRouter(event)}>
                     Add router
                  </button>
               </form>
            </div>
         </div>
      </>
   );
};

export default AddRouter;
