import React from 'react';
import "../../IndividualPool.scss";
// import { ReactComponent as Check } from "../../../../Assets/SVGs/check-circle.svg";
import { ReactComponent as Clock } from "../../../../Assets/SVGs/clock.svg";
// import { ReactComponent as Info } from "../../../../Assets/SVGs/yellow-info.svg";
import { Container } from 'react-bootstrap';
import Fade from "react-reveal/Fade";
import Moment from "react-moment";
import { useMetamaskContext } from '../../../../Contexts/MetamaskProvider';

const Header = ({ poolData }) => {
    const { wallet } = useMetamaskContext();

    const formatDateFromUnix = (UNIX_timestamp) => {
        var a = new Date(UNIX_timestamp * 1000);
        var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var date = a.getDate();
        var hour = a.getHours();
        var min = a.getMinutes();
        var sec = a.getSeconds();
        var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
        return time;
    }

    return (
        <Container fluid className="mb-4">
            <Fade>
                <div className="mb-4 d-flex align-items-center flex-wrap gap-3">
                    <p className="font-pnb font-size-32 white-color mb-0">{poolData?.name}</p>
                    {poolData?.hasStake ? (
                        <>
                            {/* <p className="font-pnr font-size-18 white-color mb-0 main-box w-auto py-2 px-4">Staking started from: {formatDateFromUnix(stakes[0].timestamp._hex)}</p> */}
                            <p className="font-pnr font-size-18 white-color mb-0 main-box w-auto py-2 px-4" style={{ overflowX: 'auto' }}>Staking wallet: {poolData?.address}</p>
                        </>
                    ) : (
                        <p className="font-pnr font-size-18 white-color mb-0 main-box w-auto py-2 px-4">No Staking Wallet Associated</p>
                    )}
                    <p className="font-pnr font-size-18 white-color mb-0 main-box py-2 px-4" style={{ overflowX: 'auto' }}>{wallet ? `Metamask connected: ${wallet}` : "Metamask not connected"}</p>
                </div>
                <div className="d-flex flex-wrap gap-3 mb-4 font-size-18 white-color main-box w-auto py-2 px-4">
                    <div className="d-flex align-items-center me-5">
                        {/* <U className="me-2" /> */}
                        {poolData?.hasStake ? (
                            <p className="mb-0 font-pnr font-size-18 green-color">Status: Active</p>
                        ) : (
                            <p className="mb-0 font-pnr font-size-18 red-color">Status: Inactive</p>
                        )}
                    </div>
                    <div className="d-flex align-items-center me-5">
                        <Clock className="me-2" />
                        <p className="mb-0 font-pnr font-size-18 blue-color">Pool created on: <Moment format="DD/MM/YYYY">{poolData?.createdAt}</Moment></p>
                    </div>
                    {/* <div className="d-flex align-items-center">
                        <Info className="me-2" />
                        <p className="mb-0 font-pnr font-size-18 yellow-color">My first pool is active</p>
                    </div> */}
                </div>
            </Fade>
        </Container >
    );
};

export default Header;