import React, { useState, useEffect } from "react";
import "../../../IndividualPool.scss";
import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ReactComponent as Info } from "../../../../../Assets/SVGs/info.svg";
// import { ReactComponent as ActiveInfo } from "../../../../../Assets/SVGs/active-info.svg";
// import { ReactComponent as StakingSupply } from "../../../../../Assets/SVGs/metro-stack.svg";
import { ReactComponent as SpeedIcon } from "../../../../../Assets/SVGs/speedometer.svg";
import { ReactComponent as XdenIcon } from "../../../../../Assets/SVGs/xden_icon.svg";
import { toast } from "react-toastify";
import axios from "axios";
import { Modal } from "react-bootstrap";
import ThemeLoader from "../../../../../Components/Loader/Loader";

const FirstRow = ({
  details,
  forcerRender,
  setForcerRender,
  stake,
  handleShow,
  poolDetails,
}) => {
  const [countdown, setCountdown] = useState(0);
  const [show, setShow] = useState(false);
  const [pendingChangeStatus, setPendingChangeStatus] = useState(false);

  const {
    // onlineMiners,
    // offlineMiners,
    connectedDevices,
    miningPower,
    // loadingMiners,
    loadingConnectedDevices,
    poolData,
    publicDifficulty,
    privateDifficulty,
    loadingPublicPrivateDifficulty,
    privateRewardsTotal,
    allRewards,
    latestRewards,
    loadingRewards,
    // xdenStake,
    loadingXdenStake,
  } = details;

  const changePoolType = async (type) => {
    if (!poolData.hasStake) {
      toast.error("You must stake to change mining type", {
        toastId: "changePoolType-error",
      });
      return;
    }

    try {
      await axios
        .post(
          process.env.REACT_APP_BACKEND_URL + `/pool/change-type`,
          {
            poolID: poolData.id,
          },
          {
            headers: {
              Authorization: localStorage.getItem("xiden-pool-session"),
            },
          }
        )
        .then((response) => {
          if (response.status === 202) {
            setPendingChangeStatus(false);
            setShow(false);
            setForcerRender(!forcerRender);
            toast.success("Undo successful");
          }

          if (response.status === 200) {
            setPendingChangeStatus(true);
            setForcerRender(!forcerRender);
            toast.success(
              "Pool changed. The change will be available at 00:00 UTC.",

              { toastId: "change-pool-type" }
            );
          }
        });
    } catch (err) {
      if (err.response) {
        switch (err.response.status) {
          case 403:
            toast.warn(
              "Pool already changed. The change will be available at 00:00 UTC.",
              { toastId: "change-pool-type-403" }
            );
            return;
          case 404:
            toast.warn("Something went wrong.", {
              toastId: "change-pool-type-404",
            });
            return;
          default:
            console.log("Something is temporarily wrong.", {
              toastId: "change-pool-type-default",
            });
            return;
        }
      }
    }
  };
  useEffect(() => {
    var d = new Date();
    d.setUTCHours(0, 0, 0, 0);
    let now = Date.now();
    let tomorrow =
      Math.floor(now / (24 * 3600 * 1000)) * 24 * 3600 * 1000 +
      24 * 3600 * 1000;
    let hours = Math.floor((tomorrow - now) / (1000 * 60 * 60));
    let minutes = Math.floor((tomorrow - now) / (1000 * 60)) % 60;
    let seconds = Math.floor((tomorrow - now) / 1000) % 60;

    //format to have a leading zero
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    setCountdown(`${hours}:${minutes}:${seconds}`);

    // update setCountdown every 1 second
    const interval = setInterval(() => {
      var d = new Date();
      d.setUTCHours(0, 0, 0, 0);
      let now = Date.now();
      let tomorrow =
        Math.floor(now / (24 * 3600 * 1000)) * 24 * 3600 * 1000 +
        24 * 3600 * 1000;
      let hours = Math.floor((tomorrow - now) / (1000 * 60 * 60));
      let minutes = Math.floor((tomorrow - now) / (1000 * 60)) % 60;
      let seconds = Math.floor((tomorrow - now) / 1000) % 60;

      //format to have a leading zero
      hours = hours < 10 ? "0" + hours : hours;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      setCountdown(`${hours}:${minutes}:${seconds}`);
    }, 1000);

    if (poolData.changeType === d.getTime()) {
      setPendingChangeStatus(true);
    } else {
      setPendingChangeStatus(false);
    }
    setForcerRender(!forcerRender);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const cancel = (item) => {
    setShow(true);
  };

  // console.log(poolDetails.unstaked_date);

  function formatYearMonthAndDay(dateString) {
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    const month = (date.getUTCMonth() + 1).toString().padStart(2, "0"); // Months are zero-based, so we add 1.
    const day = date.getUTCDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  return (
    <>
      <Modal
        show={show}
        // onHide={toggle}
        centered
        size="lg"
        className="text-white"
      >
        <Modal.Body>
          <h2 className="font-pnsb font-size-32 mb-5 text-center">
            Are you sure you want to undo your changes ?
          </h2>

          <div className="w-100 d-flex justify-content-center">
            <button
              className="font-size-24 font-pnm modal-button-outline me-4"
              onClick={() => setShow(false)}
            >
              No
            </button>

            <button
              className="font-size-24 font-pnm modal-button-blue"
              onClick={changePoolType}
            >
              Yes
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Row xs={2} lg={3} xl={6} className="pools-overview-first-row">
        <Col className="mb-4">
          <div className="main-box height-252 d-flex justify-content-between flex-column">
            <div className="d-flex justify-content-between mb-3">
              <p className="font-pnr white-color font-size-20">Total power</p>
              {/* info icon */}
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip>
                    Combined mining power of the active miners and boosters
                  </Tooltip>
                }
              >
                <Info className="cursor-pointer info-icons" />
              </OverlayTrigger>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <SpeedIcon className="card-icons" />
              {loadingConnectedDevices ? (
                <ThemeLoader />
              ) : (
                <p className="font-pnsb mb-0 font-size-32 text-white text-center">
                  {(
                    Number(miningPower) +
                    0.01 * Number(connectedDevices) * miningPower
                  ).toFixed(2)}{" "}
                  cycles
                </p>
              )}
            </div>
          </div>
        </Col>

        <Col className="mb-4">
          <div className="main-box d-flex flex-column justify-content-between height-252">
            <div className="d-flex justify-content-between mb-3">
              <p className="mb-0 font-pnr white-color font-size-20 card-title">
                Staking supply<br></br>
                <span className="yellow-color font-size-16">
                  {" "}
                  {poolDetails.unstaked_date
                    ? `Unstaked at ${formatYearMonthAndDay(
                        poolDetails.unstaked_date
                      )}`
                    : null}
                </span>
              </p>

              {/* info icon */}
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Amount of XDEN currently in stake</Tooltip>}
              >
                <Info className="cursor-pointer info-icon" />
              </OverlayTrigger>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center">
              {poolData?.hasStake ? (
                <p className="font-pnsb d-flex align-items-center mb-0 font-size-32 white-color text-center card-label">
                  1000 <XdenIcon />
                </p>
              ) : (
                <p className="font-pnsb mb-0 font-size-32 white-color text-center card-label">
                  0 <XdenIcon />
                </p>
              )}
            </div>
            {!poolData.hasStake ? (
              <button onClick={() => stake()} className="buttonYellow mx-auto">
                Stake
              </button>
            ) : (
              <button
                onClick={() => handleShow()}
                className="buttonYellow mx-auto"
              >
                Unstake
              </button>
            )}
          </div>
        </Col>

        <Col className="mb-4 d-flex flex-column justify-content-between gap-4">
          <div className="main-box height-114 d-flex justify-content-between flex-column">
            <div className="d-flex justify-content-between">
              <p className="font-pnr white-color font-size-20 mb-0 card-title">
                Type
              </p>
            </div>
            <div className="toggle-switch-container mt-2 d-flex justify-content-between align-items-center">
              <p
                className={`mb-0 font-pnsb font-size-16 ${
                  poolData?.type === "public" ? "green-color" : "white-color"
                }`}
              >
                Public
              </p>
              {pendingChangeStatus ? (
                <>
                  {" "}
                  <input
                    onChange={(item) => {
                      cancel(item);
                    }}
                    type="checkbox"
                    id="switch"
                    readOnly
                    checked={poolData?.type === "public"}
                  />
                  <label htmlFor="switch" className="yellow-switch"></label>
                </>
              ) : (
                <>
                  {" "}
                  <input
                    onChange={(item) => {
                      changePoolType(item);
                    }}
                    type="checkbox"
                    id="switch"
                    readOnly
                    checked={poolData?.type === "private"}
                  />
                  <label htmlFor="switch"></label>
                </>
              )}

              <p
                className={`mb-0 font-pnsb font-size-16 ${
                  poolData?.type === "private" ? "green-color" : "white-color"
                }`}
              >
                Private
              </p>
            </div>
            {/* Countdown change from Public to Private */}
            {pendingChangeStatus ? (
              <div className=" m-auto text-center d-flex flex-column justify-content-between mt-1 font-size-12">
                <div className="yellow-color">Change in: </div>
                <div className="yellow-color">{countdown}h</div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="main-box height-114 d-flex flex-column justify-content-between align-items-start">
            <div className="d-flex w-100 justify-content-between">
              <p className="font-pnr white-color font-size-20 mb-0 card-title">
                Difficulty
              </p>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center">
              {loadingPublicPrivateDifficulty ? (
                <ThemeLoader />
              ) : (
                <p className="font-pnsb mb-0 font-size-32 white-color text-center card-label">
                  {poolData?.type === "private"
                    ? privateDifficulty
                    : publicDifficulty}{" "}
                </p>
              )}
            </div>
          </div>
        </Col>

        <Col className="mb-4 d-flex flex-column justify-content-between gap-4">
          <div className="main-box height-114 d-flex flex-column justify-content-between align-items-start">
            <div className="d-flex w-100 justify-content-between">
              <p className="font-pnr white-color font-size-20 mb-0 card-title">
                Mining power
              </p>
              {/* info icon */}
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip>
                    Total of mining cycles from active miner devices
                  </Tooltip>
                }
              >
                <Info className="cursor-pointer info-icon" />
              </OverlayTrigger>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center">
              {loadingConnectedDevices ? (
                <ThemeLoader />
              ) : (
                <p className="font-pnsb mb-0 font-size-32 white-color text-center card-label">
                  {miningPower.toFixed(0)} cycles
                </p>
              )}
            </div>
          </div>
          <div className="main-box height-114 d-flex flex-column justify-content-between align-items-start">
            <div className="d-flex w-100 justify-content-between">
              <p className="font-pnr white-color font-size-20 mb-0 card-title">
                Booster power
              </p>
              {/* info icon */}
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip>Mining cycles granted by booster devices</Tooltip>
                }
              >
                <Info className="cursor-pointer info-icon" />
              </OverlayTrigger>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center">
              {loadingConnectedDevices ? (
                <ThemeLoader />
              ) : (
                <p className="font-pnsb mb-0 font-size-32 white-color text-center card-label">
                  {(0.01 * Number(connectedDevices) * miningPower).toFixed(2)}{" "}
                  cycles
                </p>
              )}
            </div>
          </div>
        </Col>

        <Col className="mb-4 d-flex flex-column justify-content-between gap-4">
          <div className="main-box height-114 d-flex flex-column justify-content-between align-items-start">
            <div className="d-flex w-100 justify-content-between">
              <p className="font-pnr white-color font-size-20 mb-0 card-title">
                Locked amount
              </p>
              {/* info icon */}
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Amount of XDEN locked in staking</Tooltip>}
              >
                <Info className="cursor-pointer info-icon" />
              </OverlayTrigger>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center">
              {loadingXdenStake || loadingRewards ? (
                <ThemeLoader />
              ) : (
                <p className="font-pnsb mb-0 d-flex align-items-center font-size-32 white-color text-center card-label">
                  {poolData.hasStake
                    ? `${(1000 - Number(privateRewardsTotal)).toFixed(2)}`
                    : `0.00`}{" "}
                  <XdenIcon />
                </p>
              )}
            </div>
          </div>
          <div className="main-box height-114 d-flex flex-column justify-content-between align-items-start p-3">
            <div className="d-flex w-100 justify-content-between">
              <p className="font-pnr white-color font-size-20 mb-0 card-title">
                Unlocked amount
              </p>
              {/* info icon */}
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip>Amount of XDEN unlocked and kept in staking</Tooltip>
                }
              >
                <Info className="cursor-pointer info-icon" />
              </OverlayTrigger>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center">
              {loadingRewards ? (
                <ThemeLoader />
              ) : (
                <p className="font-pnsb d-flex align-items-center mb-0 font-size-32 white-color text-center card-label">
                  {Number(privateRewardsTotal).toFixed(2)} <XdenIcon />
                </p>
              )}
            </div>
          </div>
        </Col>

        <Col className="mb-4 d-flex flex-column justify-content-between gap-4">
          <div className="main-box height-114 d-flex flex-column justify-content-between align-items-start p-3">
            <div className="d-flex w-100 justify-content-between">
              <p className="font-pnr white-color font-size-20 mb-0 card-title">
                All time revenue
              </p>
              {/* info icon */}
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip>
                    Combined revenue from both the private and public
                    difficulties
                  </Tooltip>
                }
              >
                <Info className="cursor-pointer info-icon" />
              </OverlayTrigger>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center">
              {loadingRewards ? (
                <ThemeLoader />
              ) : (
                <p className="font-pnsb d-flex align-items-center mb-0 font-size-32 white-color text-center card-label">
                  {allRewards} <XdenIcon />
                </p>
              )}
            </div>
          </div>

          <div className="main-box height-114 d-flex flex-column justify-content-between align-items-start p-3">
            <div className="d-flex w-100 justify-content-between">
              <p className="font-pnr white-color font-size-20 mb-0 card-title">
                24H revenue
              </p>
              {/* info icon */}
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip>
                    Daily revenue based on the number of active miners and
                    boosters
                  </Tooltip>
                }
              >
                <Info className="cursor-pointer info-icon" />
              </OverlayTrigger>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center">
              {loadingRewards ? (
                <ThemeLoader />
              ) : (
                <p className="font-pnsb d-flex align-items-center mb-0 font-size-32 white-color text-center card-label">
                  {latestRewards} <XdenIcon />
                </p>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default FirstRow;
