import React, { useState, useEffect } from "react";
import "./MyWithdrawalsHeader.scss";
import { Row, Col } from "react-bootstrap";

const MyWithdrawalsTableHeader = ({ searchPoolsByName }) => {
   const [searchValue, setSearchValue] = useState(null);

   const handleSearch = (event) => {
      setSearchValue(event.target.value);
   }

   // // useEffect(() => {
   // //    const delayDebounceFn = setTimeout(() => {
   // //       // Send Axios request when users stops typing for 1.5s
   // //       // searchPoolsByName(searchValue);
   // //    }, 1500)

   //    return () => clearTimeout(delayDebounceFn);
   // }, [searchValue]);

   return (
      <Row xs={1} sm={1} md={1} lg={2} className="mb-4 filter-container">
         <Col lg={4}>
            {/* <div className="my-pools-title"> */}
            <p className="font-pnb font-size-32 white-color mb-sm-3 mb-md-0">My Withdrawals</p>
            {/* </div> */}
         </Col>

         <Col lg={8}>
            {/* <div className="my-poolsfilter-wrapper"> */}
            <Row xs={1} sm={1} md={2}>
               {/* <Col className="text-lg-end" lg={12}>
                  <input onChange={handleSearch} className="search-input font-pnr my-pools-search" placeholder="Search pools..." />
               </Col> */}

            </Row>
            {/* </div> */}
         </Col>
      </Row>
   );
};

export default MyWithdrawalsTableHeader;
