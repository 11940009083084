import React, { useState, useEffect } from "react";
import "./CreatePool.scss";
import { steps } from "../../Utils/setupSteps";
import CreatePoolStep1 from "./Steps/CreatePoolStep1";
import CreatePoolStep2 from "./Steps/CreatePoolStep2";
// import CreatePoolStep3 from "./Steps/CreatePoolStep3";

const CreatePool = () => {
   // state
   const [activeStep, setActiveStep] = useState(steps.step_1);
   const [poolId, setPoolId] = useState("");
   const [poolName, setPoolName] = useState("");

   // utils
   let stepToRender;

   // functions
   const handleActiveStep = step => setActiveStep(step);

   switch (activeStep) {
      case steps.step_1:
         stepToRender = <CreatePoolStep1 poolName={poolName} setPoolName={setPoolName} poolId={poolId} setPoolId={setPoolId} handleActiveStep={handleActiveStep} />;
         break;

      case steps.step_2:
         stepToRender = <CreatePoolStep2 poolName={poolName} poolId={poolId} handleActiveStep={handleActiveStep} />;
         break;

      // case steps.step_3:
      //    stepToRender = <CreatePoolStep3 poolName={poolName}  poolId={poolId} handleActiveStep={handleActiveStep} />;
      //    break;

      default:
         stepToRender = <CreatePoolStep1 handleActiveStep={handleActiveStep} />;
   }

   useEffect(() => {
      window.scrollTo(0, 0)
   }, []);

   return (
      <div className="create-pool py-3 text-white">
         <div className="container-main d-flex justify-content-center">{stepToRender}</div>
      </div>
   );
};

export default CreatePool;
