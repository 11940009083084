import React, { useState, useEffect, ReactText } from "react";
import "../Enable2FA.scss";
import AppIcon from "../Assets/app.png";
import Fade from "react-reveal/Fade";
import { ReactComponent as ArrowLeft } from "../../../Assets/Icons/arrow-left.svg";
import QRCode from "react-qr-code";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useUserContext } from "../../../Contexts/UserProvider";

const AppSetup = ({ handleViewChange }) => {
  // state
  const [qrCode, setQrCode] = useState(() => "hello world");
  const [secureCode, setSecureCode] = useState(() => "");
  const [loading, setLoading] = useState(() => false);
  const [qrcodeUrl, setQrcodeUrl] = useState(() => "");

  // hooks
  const navigate = useNavigate();

  const { rerender, setRerender } = useUserContext();
  // functions
  const handleSecureCode = (event) => setSecureCode(event.target.value);

  const activate2FA = async () => {
    if (!secureCode) {
      toast.error("Secure code field is empty", {
        toastId: "secure-code-empty",
      });
      return;
    }

    try {
      setLoading(true);

      let verifyCode = await axios.post(
        "https://pools.xiden.com/api/confirmTwoFactor",
        {
          code: secureCode,
        },
        {
          headers: {
            Authorization: localStorage.getItem("xiden-pool-session"),
          },
        }
      );

      if (verifyCode.status === 200) {
        toast.success("Two-factor authentication has been enabled", {
          toastId: "2fa-enabled-success",
        });
        handleViewChange("step3");
        setRerender(!rerender);
      }
    } catch (err) {
      console.log(err);
      if (err.response) {
        switch (err.response.status) {
          case 403:
            toast.error("Invalid verification code provided", {
              toastId: "invalid-verification",
            });
            return;
            case 401:
              toast.error("Invalid verification code")
              return;
          case 422:
            toast.error("Code is not allowed to be empty", {
              toastId: "code-not-allowed-empty",
            });
            return;
          default:
            toast.error("Oops.. There was an error!", { toastId: "error" });
            return;
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCopyNotification = () => {
    if (!qrCode) {
      return toast.error("Recovery code field is empty", {
        toastId: "recovery-code-empty-copy",
      });
    }
    toast.success("Recovery code was copied successfuly", {
      toastId: "recovery-code-copy-success",
    });
  };

  // effects
  useEffect(() => {
    const fetchData = async () => {
      try {
        let response = await axios.post("https://pools.xiden.com/api/initTwoFactor", {}, {
          headers: {
            Authorization: localStorage.getItem("xiden-pool-session"),
            },
            });
        setQrCode(response.data);
        setQrcodeUrl(response.data.url);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  return (
    <Fade>
      <div className="main-card-2fa">
        <div className="d-flex flex-column">
          <h1 className="font-size-32 font-pnb text-center mb-5">
            Setup 2FA with mobile app
          </h1>

          <div className="set-container">
            <div className="p-2 d-flex flex-column align-items-center justify-content-center setup-card sms-card single-setup-card " >
              <img src={AppIcon} alt="sms" className="tfa-img" />
              <p className="mt-3 mb-2 font-size-32 font-pnb text-center card-heading">
                Use a mobile app
              </p>
              <p className="font-size-20 font-pnr text-center tfa-info">
                Security codes will be generated <br /> by an authenticator app.
              </p>
            </div>

            <div className="d-flex flex-column">
              <p className="mb-1 font-pnb font-size-32 centered-heading">
                Scan the QR code below
              </p>

              <div className="py-4 d-flex align-items-center qr-code-container">
                <div className="p-2 qr-wrapper">
                  <QRCode value={qrcodeUrl} />
                </div>
              </div>
            </div>
          </div>

          <div className="set-container mt-5 d-flex flex-column">
            <h4 className="font-size-24 font-pnb">
              Please save this link/code for recovery in case you lose your
              device.
            </h4>

            <input
              disabled
              value={qrCode?.recoveryToken || ""}
              type="text"
              className="primary-input"
              onChange={() => null}
            />

            <CopyToClipboard
              text={qrCode?.recoveryToken || ""}
              onCopy={handleCopyNotification}
            >
              <button className="btn-height-48 font-size-18 font-pnm radius-10 copy-btn">
                Copy
              </button>
            </CopyToClipboard>
          </div>

          <div className="set-container mt-5 d-flex flex-column">
            <h4 className="font-size-24 font-pnb">
              Enter the code generated by the authenticator app.
            </h4>

            <input
              value={secureCode}
              type="text"
              className="primary-input"
              placeholder="Type de code..."
              onChange={handleSecureCode}
            />

            <button
              className="zixxar-blue-button btn-height-48 font-size-18 font-pnm radius-10 process-btn"
              disabled={loading}
              onClick={activate2FA}
            >
              Activate
            </button>
          </div>

          {/* back button */}
          <button
            className="zixxar-blue-button btn-height-56 font-size-20 font-pnm radius-12 control-2fa-btn m-auto mt-5 mt-3"
            onClick={() => handleViewChange("step1")}
          >
            <ArrowLeft className="me-2" />
            Go back
          </button>
        </div>
      </div>
    </Fade>
  );
};

export default AppSetup;
