import React from "react";
import "./ToggleBar.scss";

const ToggleBar = ({ activeButton, setActiveButton, excludeDates }) => {

   let
   hours = "24hours",
   sevenDays = "7days",
   thirtyDays = "30days",
   twelveMonths = "12months";

   const handleToggleSelect = buttonId => {
      switch (buttonId) {
         case hours:
            setActiveButton(hours);
            break;
         case sevenDays:
            setActiveButton(sevenDays);
            break;
         case thirtyDays:
            setActiveButton(thirtyDays);
            break;
         case twelveMonths:
            setActiveButton(twelveMonths);
            break;
         default:
            setActiveButton(sevenDays);
      }
   };

   const handleActiveButtonUI = () => {
      switch (activeButton) {
         case hours:
            return "hourActive";
         case sevenDays:
            return "dayActive";
         case twelveMonths:
            return "monthActive";
         case thirtyDays:
            return "weekActive";
         default:
            return "hourActive";
      }
   };

   return (
      <div className={`toggle-container ${excludeDates !== undefined && excludeDates.includes("24hours") ? 'only-7days' : 'simple'}`}>
         <div className={`activeButton ${handleActiveButtonUI()}`}>
            {
               activeButton === hours ? 'Last 24 hours' :
               activeButton === sevenDays ? 'Last 7 days' :
               activeButton === thirtyDays ? 'Last 30 days' :
               activeButton === twelveMonths ? 'Year' : ""
            }
         </div>
         {
            excludeDates !== undefined && !excludeDates.includes("24hours") ?
            <div
            className="font-pnsm darkgrey-color cursor-pointer flex-fill d-flex align-items-center justify-content-center"
            onClick={() => handleToggleSelect(hours)}>
            Last 24 hours
         </div>
         : null
         }
         <div
            className="font-pnsm darkgrey-color cursor-pointer flex-fill d-flex align-items-center justify-content-center"
            onClick={() => handleToggleSelect(sevenDays)}
         >
            Last 7 days
         </div>
         <div
            className="font-pnsm darkgrey-color cursor-pointer flex-fill d-flex align-items-center justify-content-center"
            onClick={() => handleToggleSelect(thirtyDays)}
         >
            Last 30 days
         </div>
         <div
            className="font-pnsm darkgrey-color cursor-pointer flex-fill d-flex align-items-center justify-content-center"
            onClick={() => handleToggleSelect(twelveMonths)}
         >
            Year
         </div>
      </div>
   );
};

export default ToggleBar;
