import React from "react";
import { Outlet } from "react-router-dom";
import PrivateRoute from "../../Components/PrivateRoute/PrivateRoute";
import Navigation from "../../Components/Navigation/Navigation";
import UserProvider from "../../Contexts/UserProvider";

const AdminLayout = () => {
   console.log(
      "%c Xiden Pool ",
      "font-weight: bold; font-size: 50px;color: #6c17ff; text-shadow: 3px 3px 0 rgb(197, 182, 223) , 6px 6px 0 rgb(121, 86, 180) , 9px 9px 0 rgb(121, 86, 180) , 12px 12px 0 rgb(197, 182, 223) , 15px 15px 0 rgb(236, 231, 244) , 18px 18px 0 rgb(236, 231, 244) , 21px 21px 0 rgb(236, 231, 244); background: #1c1839",
   );

   return (
      // layout for logged in users
      <div className="position-relative layout">
         <UserProvider>
            <Navigation />

            {/* Contexts providers */}
            <div>
               {/* main content */}
               <PrivateRoute>
                  <Outlet />
               </PrivateRoute>
            </div>
         </UserProvider>
      </div>
   );
};

export default AdminLayout;
