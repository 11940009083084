import React, { useState, useEffect } from 'react';
import './Economics.scss';
import { ReactComponent as ChevronDown } from '../../../../Assets/SVGs/chevron-down.svg';
import Fade from 'react-reveal/Fade';
import { Container } from 'react-bootstrap';
import axios from "axios";

import FirstRow from './EconomicsGrid/FirstRow';
import SecondRow from './EconomicsGrid/SecondRow';
import { getDaysInAMonth } from '../../../../Utils/chartTimeStamps';

const Economics = ({ data }) => {

    const { economicsScanner, chartRewardOverview } = data

    const [showContent, setShowContent] = useState(true);

    // stats
    const [xdenPrice, setXdenPrice] = useState(0);
    const [xdenStake, setXdenStake] = useState(0);
    const [totalWithdrawals, setTotalWithdrawals] = useState(0);

    const [rewards, setRewards] = useState({
        allRewards: 0,
        latestRewards: 0,
        privateRewardTotal: 0,
        privateRewardYesterday: 0,
        publicRewardTotal: 0,
        publicRewardYesterday: 0,
    });

    // stats loaders
    const [loadingXdenPrice, setLoadingXdenPrice] = useState(true);
    const [loadingXdenStake, setLoadingXdenStake] = useState(true);
    const [loadingXdenRewards, setLoadingXdenRewards] = useState(true);
    const [loadingWithdrawals, setLoadingWithdrawals] = useState(true);

    const toggleContent = () => {
        setShowContent(prevContent => !prevContent);
    }

    const getXdenPrice = async () => {
        try {
            const checkReq = await axios.get("https://pools.xiden.com/api/price")
            if (checkReq.status === 200) {
                setXdenPrice(checkReq.data.price || 0);
            }
        } catch (err) {
            if (err.response) {
                switch (err.response.status) {
                    case 404:
                        console.log("404 Not found.");
                        return;
                    default:
                        console.log("Something is temporarily wrong.");
                        return;
                }
            }
        } finally {
            setLoadingXdenPrice(false);
        }
    }

    const getTotalWithdrawal = async () => {
        try {
            const checkReq = await axios.get("https://pools.xiden.com/api/withdrawal/getTotal", {
                headers: {
                    Authorization: localStorage.getItem("xiden-pool-session")
                }
            })         
            if (checkReq.status === 200) {
                console.log(checkReq.data)
                setTotalWithdrawals(checkReq.data.totalWithdrawalAmount.toFixed(5) || 0);
                console.log("Total withdrawals")

                console.log(checkReq.data.totalWithdrawalAmount)
            }
        } catch (err) {
            if (err.response) {
                switch (err.response.status) {
                    case 404:
                        console.log("404 Not found.");
                        return;
                    default:
                        console.log("Something is temporarily wrong.");
                        return;
                }
            }
        } finally {
            setLoadingWithdrawals(false);
        }
    }


    const getXdenStake = async () => {
        try {
            const checkReq = await axios.get("https://pools.xiden.com/api/rewards/getStakedAmound", {
                headers: {
                    Authorization: localStorage.getItem("xiden-pool-session")
                }
            })
            if (checkReq.status === 200) {
                setXdenStake(checkReq.data.staked ? Number(checkReq.data.staked) * 1000 : 0);
            }
        } catch (err) {
            if (err.response) {
                switch (err.response.status) {
                    case 404:
                        console.log("404 Not found.");
                        return;
                    default:
                        console.log("Something is temporarily wrong.");
                        return;
                }
            }
        } finally {
            setLoadingXdenStake(false);
        }
    }

    const getXdenReward = async () => {
        try {
            const checkReq = await axios.get("https://pools.xiden.com/api/rewards/getAllRewards", {
                headers: {
                    Authorization: localStorage.getItem("xiden-pool-session")
                }
            })
            if (checkReq.status === 200) {
                setRewards(checkReq.data);
            }
        } catch (err) {
            if (err.response) {
                switch (err.response.status) {
                    case 404:
                        console.log("404 Not found.");
                        return;
                    default:
                        console.log("Something is temporarily wrong.");
                        return;
                }
            }
        } finally {
            setLoadingXdenRewards(false);
        }
    }

    useEffect(() => {
        // Create an scoped async function in the hook
        const fetchData = async () => {
            await getXdenPrice();
            await getXdenReward();
            await getXdenStake();
            await getTotalWithdrawal();
        };

        fetchData();
    }, []);

    const firstRow = {
        scanner: economicsScanner,
        xdenPrice: xdenPrice,
        xdenStake: xdenStake,
        totalWithdrawals : totalWithdrawals,
        allRewards: rewards.allRewards || 0,
        loadingXdenPrice: loadingXdenPrice,
        loadingXdenStake: loadingXdenStake,
        loadingXdenRewards: loadingXdenRewards
    };

    const secondRow = {
        xdenPrice: xdenPrice,
        rewardsChart: chartRewardOverview,
        latestRewards: rewards.latestRewards || 0,
        privateRewardYesterday: rewards.privateRewardYesterday || 0,
        publicRewardYesterday: rewards.publicRewardYesterday || 0,
        loadingXdenPrice: loadingXdenPrice,
        loadingXdenRewards: loadingXdenRewards
    };

    return (
        <div className="economics">
            <Container fluid>
                <div onClick={toggleContent} className="d-flex gap-2 align-items-center mb-3 cursor-pointer">
                    <p className="font-pnb font-size-32 white-color mb-0">Economics</p>
                    <ChevronDown className={`chevron-icon ${showContent ? 'show' : 'hide'} `} />
                </div>
                {showContent && (
                    <Fade>
                        <FirstRow details={firstRow} />
                        <SecondRow details={secondRow} />
                    </Fade>
                )}
            </Container>
        </div>
    );
};

export default Economics;