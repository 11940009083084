import React from "react";
import "./ErrorPage.scss";
import { useNavigate } from "react-router-dom";
import { routes } from "../../Utils/routes";
import { ReactComponent as ErrorImage } from "../../Assets/SVGs/error.svg";

const ErrorPage = () => {
   // hooks
   const navigate = useNavigate();

   return (
      <div className="py-4 error-page">
         <div className="container-main text-white d-flex flex-column justify-content-center align-items-center">
            <div className="d-flex flex-column justify-content-center align-items-center">
               <ErrorImage className="mb-5 error-image" />

               <h1 className="font-pnb mb-5 text-center">Sorry, the page you were looking for was not found</h1>

               <button className="buttonBlue backButton" onClick={() => navigate(routes.homepage)}>
                  Back to main page
               </button>
            </div>
         </div>
      </div>
   );
};

export default ErrorPage;
