import React, { useState, useContext } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';


const kycContextDefaultValue = {
  kycData: {
    personalDetails: {
      fullName: '',
      residentialAddress: '',
      nationality: '',
      dateOfBirth: '',
      placeOfOrigin: '',
      gender: '',
      videoAppointment: '',
      sourceOfWealthExplanation: ''
    },
    files: []
  },

};

// contexts
const KycContext = React.createContext(kycContextDefaultValue);

// custom hooks
export const useKycContext = () => useContext(KycContext);

const KycProvider = ({ children }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [rejectReason, setRejectReason] = useState(() => "");


  const [kycData, setKycData] = useState({
    personalDetails: {
      fullName: '',
      residentialAddress: '',
      nationality: '',
      dateOfBirth: '',
      placeOfOrigin: '',
      gender: '',
      videoAppointment: '',
      sourceOfWealthExplanation: ''
    },
    files: []
  });

  const formatDocumentType = (mimeType) => {
    switch (mimeType) {
      case 'image/jpeg':
        return '.jpeg';

      case 'image/jpg':
        return '.jpg';

      case 'image/png':
        return '.png';

      case 'application/pdf':
        return '.pdf';

      default:
        return '';
    }
  };

  const updatePersonalDetails = (detailName, detailValue) => {
    setKycData({
      ...kycData,
      personalDetails: {
        ...kycData.personalDetails,
        [detailName]: detailValue
      }
    });
  };

  const updateFiles = (file, index, documentType) => {
    const cp = kycData.files.slice();

    // rename file with document type
    const extractedType = formatDocumentType(file[0].type);

    if (!extractedType) {
      return toast.error('Format not accepted', { toastId: 'format-not-accepted' });
    }

    const newFile = new File([file[0]], `${documentType}${extractedType}`, {
      type: file[0].type
    });
    newFile.realName = file[0].name;

    cp[index] = newFile;
    setKycData({
      ...kycData,
      files: cp
    });
  };

  const values = {
    kycData: kycData,
    updatePersonalDetails: updatePersonalDetails,
    updateFiles: updateFiles,
    currentStep:currentStep,
    setCurrentStep:setCurrentStep,
    setRejectReason:setRejectReason,
    rejectReason:rejectReason
  };

  return <KycContext.Provider value={values}>{children}</KycContext.Provider>;
};

export default KycProvider;
