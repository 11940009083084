export const routes = {
   signIn: "/",
   signUp: "/register",
   forgotPassword: "/forgot-password",
   homepage: "/dashboard",
   overview: "/overview",
   pending: "/pending",
   pool: "/pool",
   revenue: "/revenue",
   worker: "/worker",
   addRouter: "/add-router",
   error: "/error",
   sendemail: "/forgot-password/send-email",
   changePassword: "/forgot-password/:token",
   security: "/security",
   wallet: "/wallet",
   createPool: "/create-pool",
   activatePool: "/activate-pool",
   withdraw: "/withdraw",
   addMiner: "/add-miner",
   kycProcess: "/kyc",
   enable2FA: "/enable-2fa",
};
