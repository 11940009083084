import React from "react";
import "./Hero.scss";
import { Container, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap"
import { ReactComponent as AddPool } from "../../../../Assets/SVGs/add-pool.svg";
import { ReactComponent as Info } from "../../../../Assets/SVGs/info.svg";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../../Utils/routes";

const CreatePool = () => {
    const navigate = useNavigate();

    const handleCreatePool = () => {
        navigate(routes.createPool);
    }

    return (
        <Container fluid>
            <Row>
                <Col className="mb-4">
                    <div className="hero-box main-box height-252">
                        <div onClick={handleCreatePool} className="d-flex h-100 justify-content-center align-items-center gap-3 cursor-pointer">
                            <AddPool className="pool-icon" />
                            <p className="font-pnsb mb-0 font-size-48 white-color text-center">Create Pool</p>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default CreatePool;