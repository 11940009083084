import React, { useEffect, useState } from "react";
import "./KycProcess.scss";
import Fade from "react-reveal/Fade";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "axios";

// import PersonalDetails from './Steps/PersonalDetails';
// import SendData from './Steps/Documents';
// import { useKycContext } from '../../Contexts/KycProvider';
import { useNavigate, useParams } from "react-router-dom";
import PersonalDetails from "./PersonalDetails/PersonalDetails";
import SendData from "./SendData/SendData";
import { useKycContext } from "./KycContext/KycProvider";
import { routes } from "../../Utils/routes";
import { useUserContext } from "../../Contexts/UserProvider";
// import { useUserProfile } from '../../Contexts/UserProfileProvider';
// import LoadingModal from './LoadingModal/LoadingModal';
// import { appRoutes } from '../../Utils/appRoutes';

const KycProcess = () => {
  // const [currentStep, setCurrentStep] = useState(0);
  const [chosenLevel, setChosenLevel] = useState(1);
  const [loading, setLoading] = useState(false);

  const user = useUserContext();

  const navigate = useNavigate();
  const params = useParams();
  const { kycData,currentStep,rejectReason,setRejectReason,setCurrentStep } = useKycContext();
  // const { authProfile } = useUserProfile();

  const steps = [<PersonalDetails />, <SendData chosenLevel={chosenLevel} />];

  const onChange = (nextStep) => {
    setCurrentStep(nextStep > 1 ? 1 : nextStep);
  };

  const sendDocuments = async (obj) => {
    
    const json = JSON.stringify(obj);
    const data = new FormData();

    let names = [
      "identification_document_front",
      "identification_document_back",
      "proof_of_address",
    ];
    for(let i=0;i<names.length;i++){
    if(rejectReason==="personal_info"){
      data.append(names[i],null);

    }
    else{  
      data.append(names[i], kycData.files[i]);
    }}
  


    // kycData.files.map((file) => data.append('documents', file));
    data.append("json", json);

    try {
      setLoading(true);
      
      let result = await axios.post(
        `https://kyc.xiden.com/kyc/submitKYC`,
        data,
        {
          headers: { customauth: localStorage.getItem("xiden-pool-session") },
        }
      );
      if (result.status === 200) {
        toast.success("Personal data and documents saved successfully", {
          toastId: "kyc-success",
        });
        navigate("/overview");
      }
    } catch (err) {
      console.log(err);
      if (err.response) {
        switch (err.response.status) {
          case 401:
            toast.error("Please log in again", { toastId: "error-401" });
            localStorage.removeItem("jwt");
            navigate("/login");
            return;
          case 406:
            toast.error(err.response.data.message, {
              toastId: "error-kyc-406",
            });
            return;
          case 409:
            toast.error("All fields are required", {
              toastId: "error-kyc-409",
            });
            return;
          case 411:
            toast.error(err.response.data.message, {
              toastId: "error-kyc-411",
            });
            return;
          default:
            toast.error("Oops.. There was an error", {
              toastId: "error-kyc-default",
            });
            return;
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const onNext = async () => {
    const obj = {
      fullName: kycData.personalDetails.fullName,
      fullAddress: kycData.personalDetails.residentialAddress,
      nationality: kycData.personalDetails.nationality,
      dateOfBirth: kycData.personalDetails.dateOfBirth,
      placeOfBirth: kycData.personalDetails.placeOfOrigin,
      gender: kycData.personalDetails.gender,
      // videoId: kycData.personalDetails.videoAppointment,
      // sourceOfWealthExplanation: kycData.personalDetails.sourceOfWealthExplanation,
      // type: params?.level,
      // // email: authProfile?.email
      // email:  user.user.email
      //     };
    };
    // first step - validate personal details
    if (currentStep === 0) {
      if (
        !kycData.personalDetails.dateOfBirth ||
        !kycData.personalDetails.fullName ||
        !kycData.personalDetails.nationality ||
        !kycData.personalDetails.gender ||
        !kycData.personalDetails.placeOfOrigin ||
        !kycData.personalDetails.residentialAddress
      ) {
        console.log(kycData);
        toast.error("All fields are mandatory", {
          toastId: "mandatory-fields",
        });
        return;
      }

      if (kycData.personalDetails.fullName.length < 4) {
        toast.error("Full Name should be at least 4 characters", {
          toastId: "full-name-length",
        });
        return;
      }

      if (kycData.personalDetails.residentialAddress.length < 4) {
        toast.error("Residential Address should be at least 4 characters", {
          toastId: "residential-address-length",
        });
        return;
      }
      if(rejectReason==="personal_info"){
        console.log('intru',rejectReason)
        sendDocuments(obj);
        return;


      }
      onChange(currentStep + 1);
      toast.success("Personal details sent successfully", {
        toastId: "personal-details-success",
      });
    }

    const realLength = kycData.files.filter((n) => n);
    console.log(chosenLevel);

    // second step - validate documents and send data
    if (currentStep === 1) {
      if (chosenLevel === 1 ) {
        if (
          kycData.files.some((file) => file.name.includes("backsideIdCard"))
        ) {
          if (realLength.length !== 3) {
            toast.error("You have not completed all the mandatory fields", {
              toastId: "mandatory-fields",
            });
            return;
          }
        } else {
          if (realLength.length !== 3) {
            toast.error("You have not completed all the mandatory fields", {
              toastId: "mandatory-fields",
            });
            return;
          }
        }
      }

      if (chosenLevel === 2) {
        if (kycData.personalDetails?.sourceOfWealthExplanation?.length < 50) {
          toast.error("You have not completed all the mandatory fields", {
            toastId: "source-of-wealth-explanation-length",
          });
          return;
        }
        if (
          kycData.files.some((file) => file.name.includes("backsideIdCard"))
        ) {
          if (realLength.length !== 5) {
            toast.error("You have not completed all the mandatory fields", {
              toastId: "mandatory-fields",
            });
            return;
          }
        } else {
          if (realLength.length !== 4) {
            toast.error("You have not completed all the mandatory fields", {
              toastId: "mandatory-fields",
            });
            return;
          }
        }
      }

      if (chosenLevel === 3) {
        if (kycData.personalDetails?.sourceOfWealthExplanation?.length < 50) {
          toast.error("You have not completed all the mandatory fields", {
            toastId: "source-of-wealth-length",
          });
          return;
        }
        if (!kycData.personalDetails.videoAppointment) {
          toast.error("You have not completed all the mandatory fields", {
            toastId: "video-appointment-mandatory",
          });
          return;
        }
        if (
          kycData.files.some((file) => file.name.includes("backsideIdCard"))
        ) {
          if (realLength.length !== 5) {
            toast.error("You have not completed all the mandatory fields", {
              toastId: "mandatory-fields",
            });
            return;
          }
        } else {
          if (realLength.length !== 4) {
            toast.error("You have not completed all the mandatory fields", {
              toastId: "mandatory-fields",
            });
            return;
          }
        }
      }


      sendDocuments(obj);
    }
  };

  let toRender;
  let guideByLevel;

  if (chosenLevel === 1) {
    guideByLevel = (
      <div>
        <ul>
          <li className="font-pnr font-size-16 mb-2">
            {
              "If you are using an ID card, you are required to take photos of the front and back of your ID. Please make sure all the details are clearly visible."
            }
            <br />
          </li>
          {/* <li className="font-pnr font-size-16 mb-2">
            {
              "The selfie must be taken in a well-illuminated room and with a blank wall behind, with the document appearing clearly."
            }
          </li> */}
          <li className="font-pnr font-size-16 mb-2">
            {
              "Upload an utility bill as Proof of Address. Please make sure all the details are clearly visible."
            }
          </li>
          <li className="font-pnr font-size-16 mb-2">
            {
              "The maximum size for document uploads is 10 MB, and the accepted formats are PNG, JPG, and PDF.              "
            }
          </li>
        </ul>
      </div>
    );
  } else if (chosenLevel === 2) {
    guideByLevel = (
      <div>
        <ul>
          <li className="font-pnr font-size-16 mb-2">
            {
              "If you are using an ID card, you are required to take photos of the front and back of your ID. Please make sure all the details are clearly visible."
            }
            <br />*
            {"If you are using a passport, only the front side is required."}
          </li>
          <li className="font-pnr font-size-16 mb-2">
            {
              "The selfie must be taken in a well-illuminated room and with a blank wall behind, with the document appearing clearly."
            }
          </li>
          <li className="font-pnr font-size-16 mb-2">
            {
              "Upload an utility bill as Proof of Address. Please make sure all the details are clearly visible."
            }
          </li>
          <li className="font-pnr font-size-16 mb-2">
            {
              "Basic information, such as the bank name and address, bank statement, total balance amounts, a bank personnel's signature, is required on the proof of funds document."
            }
          </li>
          <li className="font-pnr font-size-16 mb-2">
            {
              "Source of wealth is also required, as it describes the economic, business and/or commercial activities that generated, or significantly contributed to, the the overall's net worth/entire body of wealth."
            }
          </li>
        </ul>
      </div>
    );
  } else if (chosenLevel === 3) {
    guideByLevel = (
      <div>
        <ul>
          <li className="font-pnr font-size-16 mb-2">
            {
              "If you are using an ID card, you are required to take photos of the front and back of your ID. Please make sure all the details are clearly visible."
            }
            <br />*
            {"If you are using a passport, only the front side is required."}
          </li>
          <li className="font-pnr font-size-16 mb-2">
            {
              "The selfie must be taken in a well-illuminated room and with a blank wall behind, with the document appearing clearly."
            }
          </li>
          <li className="font-pnr font-size-16 mb-2">
            {
              "Upload an utility bill as Proof of Address. Please make sure all the details are clearly visible."
            }
          </li>
          <li className="font-pnr font-size-16 mb-2">
            {
              "Basic information, such as the bank name and address, bank statement, total balance amounts, a bank personnel's signature, is required on the proof of funds document."
            }
          </li>
          <li className="font-pnr font-size-16 mb-2">
            {
              "Source of wealth is also required, as it describes the economic, business and/or commercial activities that generated, or significantly contributed to, the the overall's net worth/entire body of wealth."
            }
          </li>
          <li className="font-pnr font-size-16 mb-2">
            {
              "You will have to choose the date and time to schedule a video appointment with one of our agents."
            }
          </li>
        </ul>
      </div>
    );
  }

  if (chosenLevel) {
    toRender = (
      <Fade>
        <div className="d-flex kyc-page-container w-100 flex-wrap gap-5 justify-content-center container-main">
          <div className="p-4 kyc-container d-flex flex-column justify-content-between my-3">
            <div className="d-flex flex-column justify-content-between safari-center">
              <Fade spy={currentStep}>{steps[currentStep]}</Fade>
            </div>
            <div className="d-flex align-items-center justify-content-around">
              {loading ? <div className="d-flex justify-content-center align-items-center">
            <div className="spinner-border text-primary" role="status">
               <span className="sr-only"></span>
            </div>
         </div>: <Button
                variant="primary"
                onClick={onNext}
                className="primary-btn-kyc w-100 font-size-16 font-pnr d-flex justify-content-center align-items-center"
                disabled={loading}
              >
                {currentStep !== 1 ? "Next" : "Send"}
              </Button> }
             
            </div>
          </div>
          <div className="p-4 kyc-container guide-container d-flex flex-column justify-content-between my-3">
            {currentStep === 0 ? (
              <div className="identity-info-guide">
                <p className="font-size-20 text-center font-pnb mb-3">
                  {"Identity information Guide"}
                </p>
                <p className="font-pnr font-size-16 mb-3">
                  {
                    "KYC is designed to protect users' accounts against the misuse of financial accounts such as fraud, corruption, money laundering and terrorist financing."
                  }
                  <br />
                  {
                    "Please make sure that all the information entered is consistent with your ID documents. You won’t be able to change it once confirmed."
                  }
                </p>
                <p className="font-size-16 font-pnr">
                  {"Complete the following"}:
                </p>
                <ul>
                  <li className="font-pnr font-size-16 mb-2">
                    {"Full Name: Last Name and First Name"}
                  </li>
                  <li className="font-pnr font-size-16 mb-2">
                    {"Residential Address including Country"}
                  </li>
                  <li className="font-pnr font-size-16 mb-2">
                    {"Nationality"}
                  </li>
                  <li className="font-pnr font-size-16 mb-2">
                    {"Date of Birth"}
                  </li>
                  <li className="font-pnr font-size-16 mb-2">
                    {"Place of Birth"}
                  </li>
                  <li className="font-pnr font-size-16 mb-2">{"Gender"}</li>
                </ul>
              </div>
            ) : (
              <div className="personal-documents-guide">
                <p className="font-size-20 text-center font-pnr mb-3">
                  {"Personal Documents Guide"}
                </p>
                {guideByLevel}
              </div>
            )}
          </div>
        </div>
      </Fade>
    );
  } else {
    toRender = (
      <div className="p-4 kyc-container d-flex flex-column justify-content-between">
        <div className="d-flex align-items-center justify-content-around">
          <p>{"Incorrect Level"}.</p>
        </div>
        <div className="d-flex align-items-center justify-content-around">
          <Button
            variant="primary"
            className="w-100 d-block font-size-16 font-pnr"
            style={{ backgroundColor: "#6c17ff", borderColor: "#6c17ff" }}
            onClick={() => navigate(routes.login)}
          >
            Back to Homepage
          </Button>
        </div>
      </div>
    );
  }

  //   useEffect(() => {
  //     if (!localStorage.getItem('jwt')) {
  //       navigate(appRoutes.login);
  //     }

  //     const fetchData = async () => {
  //       try {
  //         let result = await axios.get(process.env.REACT_APP_STRAPI_URL + '/documents/private', {
  //           headers: { 'x-authentication': 'Bearer ' + localStorage.getItem('jwt') }
  //         });
  //         if (result.status === 200) {
  //           if (result.data) {
  //             if (result.data?.pending) {
  //               toast.info('The KYC process is pending. Please wait for confirmation.', {
  //                 toastId: 'kyc-pending'
  //               });
  //               navigate(appRoutes.overview);
  //             } else if (result.data?.confirmed) {
  //               toast.info('KYC process confirmed successfully.', { toastId: 'kyc-confirmed' });
  //               navigate(appRoutes.overview);
  //             }
  //           }
  //         }
  //       } catch (err) {
  //         console.log(err);
  //         if (err.response) {
  //           switch (err.response.status) {
  //             case 401:
  //               toast.error('Please log in again', { toastId: 'error-401' });
  //               localStorage.removeItem('jwt');
  //               navigate(appRoutes.login);
  //               return;
  //             default:
  //               toast.error('Oops.. There was an error', { toastId: 'error-default' });
  //               return;
  //           }
  //         }
  //       }
  //     };

  //     fetchData();

  //     if (params && params.level) {
  //       switch (params.level) {
  //         case 'light':
  //           setChosenLevel(1);
  //           break;
  //         case 'regular':
  //           setChosenLevel(2);
  //           break;
  //         case 'extended':
  //           setChosenLevel(3);
  //           break;
  //         default:
  //           setChosenLevel(1);
  //           break;
  //       }
  //     }
  //   }, [navigate, params]);

  return (
    <div className="kyc-page">
      <div className="content-container-col d-flex flex-column">{toRender}</div>
      {/* <LoadingModal show={loading} /> */}
    </div>
  );
};

export default KycProcess;
