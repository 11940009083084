import React, { useState, useEffect } from "react";
import "./AddMiner.scss";
import { steps } from "../../Utils/setupSteps";
import { useSearchParams } from "react-router-dom";

import AddMinerStep1 from "./Steps/AddMinerStep1";
import AddMinerStep2 from "./Steps/AddMinerStep2";

const AddMiner = () => {
   // state
   const [activeStep, setActiveStep] = useState(steps.step_1);
   const [poolId, setPoolId] = useState("");
   const [minterId, setMinterId] = useState("");
   const [minerName, setMinerName] = useState("");
   const [error, setError] = useState("");
   const [minerType, setMinerType] = useState("miner");

   const [searchParams] = useSearchParams();

   // utils
   let stepToRender;

   // functions
   const handleActiveStep = step => setActiveStep(step);

   switch (activeStep) {
      case steps.step_1:
         stepToRender = <AddMinerStep1 minterId={minterId} setMinterId={setMinterId} minerType={minerType} setMinerType={setMinerType} poolId={poolId} handleActiveStep={handleActiveStep} minerName={minerName} setMinerName={setMinerName} />;
         break;

      case steps.step_2:
         stepToRender = <AddMinerStep2 minterId={minterId} minerType={minerType} poolId={poolId} handleActiveStep={handleActiveStep} minerName={minerName} />;
         break;

      default:
         stepToRender = <AddMinerStep1 handleActiveStep={handleActiveStep} />;
   }

   useEffect(() => {
      window.scrollTo(0, 0)

      const poolId = searchParams.get("poolId");
      setPoolId(poolId);

      if (!poolId) {
         setError("Wrong Pool.");
      }
   }, [searchParams]);

   return (
      <div className="add-miner-page py-3 text-white">
         {!error ? (
            <div className="container-main d-flex justify-content-center">{stepToRender}</div>
         ) : (
            <div className="container-main d-flex justify-content-center">
               <div className="alert alert-danger" role="alert">
                  {error}. Please go back and try again.
               </div>
            </div>
         )}
      </div>
   );
};

export default AddMiner;
