import React, { useState } from "react";
import "../Security.scss";
import { AiOutlineEye as EyeOpenIcon, AiOutlineEyeInvisible as EyeCloseIcon } from "react-icons/ai";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import qs from 'qs';

// state values
const password = {
   old: "",
   new: "",
   confirm: "",
};

const PasswordCard = (props) => {

   let email = props.email.personalEmail;

   // state
   const navigate = useNavigate();
   const [oldPassword, setOldPassword] = useState(() => "");
   const [passwordValue, setPasswordValue] = useState(() => password);
   const [hideOldPassword, setHideOldPassword] = useState(() => true);
   const [hideNewPassword, setHideNewPassword] = useState(() => true);
   const [hideConfirmPassword, setHideConfirmPassword] = useState(() => true);

   // functions
   const handlePasswordChange = event => {
      const target = event.target;
      const { name, value } = target;
      setPasswordValue({ ...passwordValue, [name]: value });
   };

   

   const showOldPassword = () => setHideOldPassword(!hideOldPassword);
   const showNewPassword = () => setHideNewPassword(!hideNewPassword);
   const showConfirmPassword = () => setHideConfirmPassword(!hideConfirmPassword);

   const handleFormSUbmit = async (form) => {
      form.preventDefault()

      const oldPassword = form.target.elements.old.value.trim()
      const newPassword = form.target.elements.new.value.trim()
      const confirmPassword = form.target.elements.confirm.value.trim()

      let error = false;

      // Validation case
      if(newPassword !== confirmPassword) {
         error = true;
         toast.warn("Passwords are not matching.", {
            toastId: "erorr_notequal",
            hideProgressBar: true
         })
      }

      // Validation case
      if(newPassword === "") {
         error = true;
         toast.warn("Password is empty.", {
            toastId: "erorr_new_empty",
            hideProgressBar: true
         })
      }

      // Validation case
      if(newPassword !== "" && confirmPassword === "") {
         error = true;
         toast.warn("One of the fields are empty.", {
            toastId: "erorr_confirm_empty",
            hideProgressBar: true
         })
      }

      const toSend = {
         email: email,
         password: oldPassword
      };

      try{
         const responseLogin = await axios.post(
            process.env.REACT_APP_BACKEND_URL + "/login",
            qs.stringify(toSend),
         );
   
         if (responseLogin.status !== 200) {
            error = true;
            toast.warn("Invalid old password", {
               toastId: "erorr_new_empty",
               hideProgressBar: true
            })
         }
      }
      catch(e){
         error = true;
         toast.warn("Invalid old password", {
            toastId: "erorr_new_empty",
            hideProgressBar: true
         })
      }
      

      // Make request if there are no errors
      if(!error) {
         await axios.post(`${process.env.REACT_APP_BACKEND_URL}/reset-password-private`,
         {
            password: newPassword,
            password_confirmation: confirmPassword
         },
         {
            headers: {
               Authorization: localStorage.getItem("xiden-pool-session")
            }
         }).then((response) => {
            toast.success("Password changed successfully.", { toastId: "success_request" });
            localStorage.removeItem("xiden-pool-session");
            navigate("/");
         }).catch(err => console.log(err))
      }






   }

   return (
      <div className="d-flex flex-column personal-data-card">
         <h5 className="font-size-20 font-pnb mb-5">Security - Change password</h5>

        

         {/* new password */}
         <form onSubmit={handleFormSUbmit} autoComplete="off">

             {/* old password */}
            <div className="profile-input-container mb-3">
               <label className="font-size-18 font-pnr input-label">Old password:</label>
               <div className="password-input-container position-relative">
                  <input
                     value={passwordValue.old}
                     name="old"
                     type={hideOldPassword ? "password" : "text"}
                     className="pool-input profile-input"
                     placeholder="Type here..."
                     onChange={(event) => handlePasswordChange(event)}
                  />
                  {hideOldPassword ? (
                     <EyeOpenIcon className="hide-pwd-icon" onClick={() => showOldPassword()} />
                  ) : (
                     <EyeCloseIcon className="hide-pwd-icon" onClick={() => showOldPassword()} />
                  )}
               </div>
            </div>

            <div className="profile-input-container mb-3">
               <label className="font-size-18 font-pnr input-label">New password:</label>
               <div className="password-input-container position-relative">
                  <input
                     value={passwordValue.new}
                     name="new"
                     type={hideNewPassword ? "password" : "text"}
                     className="pool-input profile-input"
                     placeholder="Type here..."
                     onChange={event => handlePasswordChange(event)}
                  />
                  {hideNewPassword ? (
                     <EyeOpenIcon className="hide-pwd-icon" onClick={() => showNewPassword()} />
                  ) : (
                     <EyeCloseIcon className="hide-pwd-icon" onClick={() => showNewPassword()} />
                  )}
               </div>
            </div>

            {/* confirm password */}
            <div className="profile-input-container mb-3">
               <label className="font-size-18 font-pnr input-label">Confirm password:</label>
               <div className="password-input-container position-relative">
                  <input
                     value={passwordValue.confirm}
                     name="confirm"
                     type={hideConfirmPassword ? "password" : "text"}
                     className="pool-input profile-input"
                     placeholder="Type here..."
                     onChange={event => handlePasswordChange(event)}
                  />
                  {hideConfirmPassword ? (
                     <EyeOpenIcon className="hide-pwd-icon" onClick={() => showConfirmPassword()} />
                  ) : (
                     <EyeCloseIcon className="hide-pwd-icon" onClick={() => showConfirmPassword()} />
                  )}
               </div>
            </div>

            {/* control buttons */}
            <div className="setup-buttons-container d-block text-end">
               <button className="setup-fill-btn font-size-20 font-pnm">Save</button>
            </div>

         </form>
      </div>
   );
};

export default PasswordCard;
