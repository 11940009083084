import React from "react";
import Fade from "react-reveal";
import SetupStepCard from "../../../Components/SetupStepCard/SetupStepCard";
import { steps } from "../../../Utils/setupSteps";
import { routes } from "../../../Utils/routes";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const ActivatePoolStep1 = ({ handleActiveStep, setWallet }) => {
   // hooks
   const navigate = useNavigate();

   // data
   const stepCardData = {
      step: 1,
      title: "Activate Pool",
      info: "Wait until hashrate is displayed. Workers will be automatically added within a few seconds on the Workers page in your account.",
   };

   const connectMetamask = async () => {
      if (!window.ethereum) {
         toast.error("Please install MetaMask to continue.");
         return;
      }

      try {
         const wallet = await window.ethereum.request({
            method: "eth_requestAccounts",
         });

         console.log(wallet)
         
         if (wallet.length === 0) {
            toast.error("Please connect your wallet to continue.", { toastId: "connect-wallet" });
            return;
         }

         setWallet(wallet[0]);
         toast.success("Wallet connected successfully.", { toastId: "wallet-connected" });
         handleActiveStep(steps.step_2);

      } catch (err) {
         console.error(err);
         console.log("There was a problem connecting to MetaMask");
      }
   }

   return (
      <Fade>
         <div className="w-100 d-flex flex-column">
            <SetupStepCard data={stepCardData} />

            <div className="setup-card m-auto d-flex flex-column">
               <h6 className="font-pnb font-size-20 mb-4">Activate Pool Configuration</h6>

               <div className="inner-wrapper">
                  <p className="font-size-16 font-pnm text-center">{stepCardData.info}</p>
               </div>

               <div className="setup-buttons-container">
                  <button className="setup-outline-btn font-size-20 font-pnm" onClick={() => navigate(routes.homepage)}>
                     Go back
                  </button>

                  <button
                     className="setup-fill-btn font-size-20 font-pnm"
                     onClick={connectMetamask}
                  >
                     Connect MetaMask
                  </button>
               </div>
            </div>
         </div>
      </Fade>
   );
};

export default ActivatePoolStep1;
