import React from "react";
import { Form, FormControl } from "react-bootstrap";
import {  useKycContext } from "../KycContext/KycProvider";
// import './Styling/StepsStyling.scss';
import { nationalities } from "./Utils/nationalities";
// import { ReactComponent as InfoIcon } from '../../../Assets/Icons/info.svg';

const PersonalDetails = () => {
    const { updatePersonalDetails,rejectReason } = useKycContext();
  const disableFutureDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth()).padStart(2, "0");
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };


  return (
    <div className="steps-styling">
      {!rejectReason &&      <p className="font-size-24 font-pnsb">{"Step"} 1/2:</p>
}
      <Form.Label className="font-size-20 text-center font-pnr">
        {"Identity information"}:
      </Form.Label>
      <FormControl
        className="kyc-link-input mb-2 font-pnr primary-input primary-input"
        aria-label=""
        aria-describedby="basic-addon1"
        placeholder={"Full Name"}
       onChange={(event) =>
          updatePersonalDetails("fullName", event.target.value)
        }
        
      />
      <FormControl
        className="kyc-link-input mb-2 font-pnr primary-input"
        aria-label=""
        aria-describedby="basic-addon1"
        placeholder={"Residential Address"}
        onChange={(event) =>
          updatePersonalDetails("residentialAddress", event.target.value)
        }
      />
      <Form.Select
        className="kyc-link-input kyc-select mb-2 w-100 primary-input"
        onChange={(event) =>
          updatePersonalDetails("nationality", event.target.value)
        }
      >
        <option className="select-option" defaultValue="">
          {"Select nationality"}
        </option>
        {nationalities?.map((nationality, index) => (
          <option key={index} className={`select-option`}>
            {nationality.Nationality}
          </option>
        ))}
      </Form.Select>
      <FormControl
        type="date"
        className="kyc-link-input mb-2 font-pnr primary-input"
        aria-label=""
        aria-describedby="basic-addon1"
        placeholder="Date of Birth"
        onChange={(event) =>
          updatePersonalDetails("dateOfBirth", event.target.value)
        }
        max={disableFutureDate()}
      />
      <FormControl
        className="kyc-link-input mb-2 font-pnr primary-input"
        aria-label=""
        aria-describedby="basic-addon1"
        placeholder={"Place of birth"}
        onChange={(event) =>
          updatePersonalDetails("placeOfOrigin", event.target.value)
        }
      />
      <Form.Select
        className="kyc-link-input kyc-select primary-input mb-3 w-100 font-pnr"
        onChange={(event) =>
          updatePersonalDetails("gender", event.target.value)
        }
      >
        <option className="select-option" defaultValue="">
          {"Select gender"}
        </option>
        <option className="select-option" value="male">
          {"Male"}
        </option>
        <option className="select-option" value="female">
          {"Female"}
        </option>
        <option className="select-option" value="other">
          {"Other"}
        </option>
      </Form.Select>
      <p className="mb-3" id="info-paragraph">
        {/* <InfoIcon className="information-icon" /> */}
        <span className="ml-1 font-pnr font-size-14">
          {
            "Information required for personal verification. Please take note that your personal information is kept confidential and protected."
          }
        </span>
      </p>
    </div>
  );
};

export default PersonalDetails;
