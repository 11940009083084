import React, { useState, useEffect } from "react";
import "./IndividualPool.scss";
import { Helmet } from "react-helmet";
import axios from "axios";
import { useParams } from "react-router-dom";

import MetamaskProvider from "../../Contexts/MetamaskProvider";
import IndividualPoolContent from "./Components/IndividualPoolGrid/IndividualPoolContent";

const IndividualPool = () => {
  const [error, setError] = useState("");
  const [poolData, setPoolData] = useState(null);
  const [loadingPool, setLoadingPool] = useState(true);
  const [forcerRender, setForcerRender] = useState(false);

  const params = useParams();

  const fetchPool = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL + `/pool/id/${params.id}`,
        {
          headers: {
            Authorization: localStorage.getItem("xiden-pool-session"),
          },
        }
      );

      if (response.status === 200) {
        setPoolData(response.data);
      }
    } catch (err) {
      if (err.response) {
        switch (err.response.status) {
          case 404:
            setError("Wrong pool.");
            return;
          default:
            setError("Something is temporarily wrong.");
            return;
        }
      }
    } finally {
      setLoadingPool(false);
    }
  };

  useEffect(() => {
    fetchPool();
  }, [params.id]);

  return (
    <>
      <Helmet>
        <title>Xiden Pools | My Pool</title>
      </Helmet>

      <div className="py-4 individual-pool">
        <div className="container-main">
          {!error ? (
            // wrap individual pool content in metamask provider if there is no error
            <MetamaskProvider>
              <IndividualPoolContent
                setForcerRender={setForcerRender}
                forcerRender={forcerRender}
                loadingPool={loadingPool}
                params={params}
                poolData={poolData}
                fetchPool={fetchPool}
              />
            </MetamaskProvider>
          ) : (
            <div className="container-main d-flex justify-content-center">
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default IndividualPool;
